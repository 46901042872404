import React from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import LoadingComponent from 'components/LoadingComponent';
import ErrorComponent from 'components/ErrorComponent';

/** HOC for loading data from a rest call and providing it to the wrapped
component. Will handle basic errors and display a loading component

The wrapped component will receive a *data* prop with the data resulting of the
GET request.

Usage:
  export default withData(MyComponent, '/api/getData');
*/

export const withData = (WrappedComponent, urlOrFunction) => {
	const isFunction = typeof urlOrFunction === 'function';

	return class extends React.PureComponent {
		_intervalHandles = [];

		state = {
			loading: true,
			data: null,
			error: null
		};

		_processRequest = (url, method = 'get', options = {}, cb) => {
			axios({ method: method, url: url, ...options })
				.then((response) => {
					cb && cb();
					this.setState({ loading: false, data: response.data, error: null });
				})
				.catch((e) => {
					if (e.response && e.response.status === 403) {
						swal({
							title: 'Ups',
							text: e.response.data.message,
							icon: 'error',
							dangerMode: true
						}).then(() => {
							document.location = '/';
						});
						return;
					}
					if (e.response && e.response.status === 401) {
						swal({
							title: 'Session expired',
							text: e.response.data.message,
							icon: 'error',
							dangerMode: true
						}).then(() => {
							localStorage.clear();
							document.location = '/';
						});
						return;
					}
					cb && cb(e);
					this.setState({ loading: false, data: null, error: e });
				});
		};

		processRequest = (cb /*optional */) => {
			if (typeof urlOrFunction === 'string') {
				//Assume it is a URL
				this._processRequest(urlOrFunction);
			} else if (isFunction) {
				const result = urlOrFunction(this.props);

				this.resultResult = JSON.stringify(result);

				if (typeof result === 'string') {
					//We take this as a constructed URL
					this._processRequest(result, undefined, undefined, cb);
				} else if (typeof result === 'object') {
					//We take this as an options object
					this._processRequest(result.url, result.method, undefined, cb);
				} else {
					console.log(`Don't know how to handle: ${JSON.stringify(result)}`);
				}
			} else if (typeof urlOrFunction === 'object') {
				//options object
				this._processRequest(urlOrFunction.url, undefined, undefined, cb);
				if (urlOrFunction.reload) {
					this._intervalHandles.push(
						setInterval(() => {
							this._processRequest(urlOrFunction.url);
						}, urlOrFunction.reload * 1000)
					);
				}
			} else {
				console.log(`Don't know how to handle: ${JSON.stringify(urlOrFunction)}`);
			}
		};

		componentDidMount() {
			this.processRequest();
		}

		componentWillUnmount() {
			this._intervalHandles.forEach(clearInterval);
		}

		componentDidUpdate() {
			if (isFunction) {
				const result = JSON.stringify(urlOrFunction(this.props));
				if (this.lastResult !== undefined && this.lastResult !== result) {
					console.log(this.lastResult, '!=', result);
					this.processRequest();
				} else {
					console.log('Nothing else to change');
				}
			}
		}

		reloadData = (cb /*optional*/) => {
			this.processRequest(cb);
		};

		render() {
			const { loading, data, error } = this.state;

			if (error) {
				return <ErrorComponent context={JSON.stringify(urlOrFunction)} error={error} />;
			}
			if (loading) {
				return <LoadingComponent />;
			}

			return (
				<WrappedComponent data={data} {...this.props} reloadData={this.reloadData} />
			);
		}
	};
};
