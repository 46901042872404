import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { removeUnderscore } from 'utils';
import moment from 'moment';
import { readableFormatDate } from 'utils'; 
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
	root: {
		margin: 0
	},
	small: {
		width: theme.spacing(5),
		height: theme.spacing(5),
		'font-size': '1em',
		background: 'white',
		color: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main}`
	}
});

const EmployeeDays = (props) => {
	const { classes, dayOff } = props;
	const {
		type,
		amount,
		active,
		created_at,
		expires_at,
		description
	} = dayOff;

	let inactiveDescription = '';

	if (!active) {
		inactiveDescription = " (Your PM didn't approve it yet)";
	}

	let compensationDayExpirationDate = '';
	if (type === 'compensation' && active) {
		compensationDayExpirationDate = `${readableFormatDate(moment.utc(created_at).add(1, 'year'))}`;
	}

	return (
		<div className={classes.root}>
			<List className={classes.root}>
				<ListItem alignItems="flex-start" disabled={!active}>
					<ListItemAvatar>
						<Avatar className={classes.small}>{amount}</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={
							<strong>
								{removeUnderscore(type[0].toUpperCase() + type.slice(1)) + ' Day'}
								{inactiveDescription}
							</strong>
						}
						secondary={
							<React.Fragment>
								<Typography
									component="span"
									variant="body2"
									className={classes.inline}
									color="textPrimary"
								>
									{description} <br/>
									Created on: {readableFormatDate(moment.utc(created_at))} <br/>
									{expires_at && `Expires on: ${readableFormatDate(moment.utc(expires_at))}`}
								</Typography>
							</React.Fragment>
						}
					/>
				</ListItem>
			</List>
		</div>
	);
};

export default withStyles(styles)(EmployeeDays);
