import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { withData } from 'components/withData';

const styles = {
	widget: {
		width: '100%'
	}
};

/**
 * This widget displays sales related news and their details. It is used in the
 * home dashboard.
 */

class SalesWidget extends React.PureComponent {
	render() {
		const { classes, data } = this.props;

		return (
			<ul className={classes.widget}>
				{data.map((news, i) => (
					<li key={i}>{news.title}</li>
				))}
			</ul>
		);
	}
}

SalesWidget.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withData(withStyles(styles)(SalesWidget), '/api/news/sales');
