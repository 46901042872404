import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import WithPermission from 'components/WithPermission';
import DayOffForm from './DayOffForm';
import axios from 'axios';
import { successMsg, errorMsg } from 'utils/alerts';
import { BASE_URL_DAYS_OFF } from 'utils/base';

const styles = (theme) => ({
	root: {
		margin: 0,
		flexGrow: 1,
		width: '100%',
		padding: theme.spacing(3)
	}
});

const EditDayOff = (props) => {
	const { id } = props.match.params;

	return (
		<WithPermission has={'days_off_edit'}>
			<div className={props.classes.root}>
				<h2>Edit day off</h2>
				<DayOffForm
					dayOffId={id}
					closeForm={() => {
						props.history.push('/days_off');
					}}
					onSubmit={(dayOff) => {
						axios
							.put(BASE_URL_DAYS_OFF + id, dayOff)
							.then((response) => {
								successMsg(response.data.message);
								props.history.push('/days_off');
							})
							.catch((err) => {
								console.error(err.response);
								errorMsg(`Error saving: ${err.response.data.message}`);
							});
					}}
				/>
			</div>
		</WithPermission>
	);
};

export default withStyles(styles)(EditDayOff);
