/**
 * Displays badges for the whole company
 */
import React, { Component } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import * as moment from 'moment';
import QuarterBadges from './QuarterBadges';
import UserLevel from './UserLevel';
import {
	BASE_URL_USERS,
	BASE_URL_BADGE_ASSIGNMENTS,
	BASE_URL_LEVEL_ASSIGNMENTS,
	BASE_URL_QUARTERS
} from 'utils/base';

const styles = (theme) => {};

class BadgesHome extends Component {
	state = {
		users: [
			{
				id: 123,
				email: 'bananita@plus.com',
				name: 'banana',
				currently_active: true
			}
		],
		badges: [
			{
				// sample badge
				id: 123,
				name: 'String',
				description: 'desc',
				created_at: Date.now(),
				updated_at: Date.now(),
				image: 'image?',
				properties: 'properties',
				badge_type: 'elective'
			}
		],
		quarters: [
			{
				// sample quarter
				id: 1,
				start: Date.now(),
				end: Date.now()
			},
			{
				// sample quarter
				id: 1,
				start: Date.now(),
				end: Date.now()
			},
			{
				// sample quarter
				id: 1,
				start: Date.now(),
				end: Date.now()
			}
		]
	};

	componentDidMount() {
		// TODO(german-e-mas): User an interceptor to attach the JWT (if any)
		const options = {
			params: {
				quarters: 3
			}
		};

		const token = localStorage.getItem('token');

		if (token) {
			options.headers = {
				Authorization: `Bearer ${token}`
			};
		}

		// Get the quarters
		axios
			.get(BASE_URL_QUARTERS, options)
			.then((response) => {
				this.setState({ quarters: response.data });
			})
			.catch((error) => console.log(error));

		// Get the users
		axios
			.get(BASE_URL_USERS, options)
			.then((response) => {
				this.setState({ users: response.data });
			})
			.catch((error) => console.error(error));

		// Get the badge assignments
		axios
			.get(BASE_URL_BADGE_ASSIGNMENTS, options)
			.then((response) => {
				this.setState({ badges: response.data });
			})
			.catch((error) => console.error(error));

		// Get the level assignments
		axios
			.get(BASE_URL_LEVEL_ASSIGNMENTS, options)
			.then((response) => {
				this.setState({ levels: response.data });
			})
			.catch((error) => console.log(error));
	}

	render() {
		const { badges, quarters, users } = this.state;
		console.log(badges);
		return (
			<Grid container spacing={16} style={{ padding: 16 }}>
				<Grid item container spacing={16} xs={12}>
					<Grid item xs={3} />
					{/* TODO: Pre sort quarters so the newest is first
          or filter so only last 3 remain (using id?) */}
					<Grid item xs={3}>
						{moment(quarters[0].start.toString()).format('YYYY [Q]Q [(]MMM Do[)]') || ''}
					</Grid>
					<Grid item xs={3}>
						{moment(quarters[1].start.toString()).format('YYYY [Q]Q [(]MMM Do[)]') || ''}
					</Grid>
					<Grid item xs={3}>
						{moment(quarters[2].start.toString()).format('YYYY [Q]Q [(]MMM Do[)]') || ''}
					</Grid>
				</Grid>

				{users.map((user) => (
					<Grid key={user.id} item container spacing={16} xs={12}>
						<Grid item xs={2}>
							<UserLevel {...user} />
						</Grid>
						<Grid item xs={3}>
							<QuarterBadges badges={badges[user.id] ? badges[user.id][25] : []}>
								q1
							</QuarterBadges>
						</Grid>
						<Grid item xs={3}>
							<QuarterBadges badges={badges[user.id] ? badges[user.id][26] : []}>
								q2
							</QuarterBadges>
						</Grid>
						<Grid item xs={3}>
							<QuarterBadges>q3</QuarterBadges>
						</Grid>
					</Grid>
				))}
			</Grid>
		);
	}
}

export default withStyles(styles, { withTheme: true })(BadgesHome);
