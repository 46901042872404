import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const styles = {
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '20px'
	},
	text: {
		marginLeft: '20px'
	}
};

/**
 * Displays a nice 'loading' spinner
 */
class LoadingComponent extends React.PureComponent {
	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<CircularProgress />
				<Typography className={classes.text}>Loading</Typography>
			</div>
		);
	}
}

LoadingComponent.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoadingComponent);
