import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	smallAvatar: {
		margin: 0,
		border: `2px solid ${theme.palette.secondary.main}`,
		width: 100,
		height: 100
	},
	bigAvatar: {
		margin: 0,
		border: `2px solid ${theme.palette.primary.main}`,
		width: 260,
		height: 260
	}
});

/* Renders a specific employee */
class TeamMember extends React.PureComponent {
	handleClick = () => {
		this.props.onClick && this.props.onClick(this.props.person);
	};

	renderTitle = () => {
		if (!this.props.big) {
			return null;
		}
		return (
			<Typography variant="h4" color="textSecondary">
				{this.props.person.title}
			</Typography>
		);
	};

	render() {
		const { classes, person } = this.props;
		const big = this.props.big;
		const nameVariant = big ? 'h3' : 'h5';
		const nameColor = big ? 'primary' : 'textSecondary';
		const buttonColor = big ? 'primary' : 'secondary';

		return (
			<div className={classes.root}>
				<IconButton onClick={this.handleClick} color={buttonColor}>
					<Avatar
						className={big ? classes.bigAvatar : classes.smallAvatar}
						src={person.image_url}
					/>
				</IconButton>
				<Typography
					variant={nameVariant}
					color={nameColor}
					title={`${person.last_name}, ${person.first_name}`}
				>
					{person.alias}
				</Typography>
				{this.renderTitle()}
			</div>
		);
	}
}

export default withStyles(styles)(TeamMember);
