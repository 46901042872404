import React from 'react';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { errorMsg } from 'utils/alerts';
import Avatar from '@material-ui/core/Avatar';
import axios from 'axios';
import { BASE_URL_USERS, BASE_URL_ROLES } from 'utils/base';

const styles = (theme) => ({
	root: {
		margin: 0,
		width: '100%',
		padding: theme.spacing(3),
		display: 'grid'
	},
	input: {
		minWidth: '300px',
		width: '100%',
		margin: '10px 0 0 0'
	},
	avatar: {
		margin: '0 auto',
		width: theme.spacing(20),
		height: theme.spacing(20)
	},
	actions: {
		textAlign: 'right',
		margin: '20px 0 0 0'
	},
	column: {
		flexDirection: 'column',
		margin: '5px'
	},
	roles: {
		overflowY: 'overlay',
		height: '135px'
	}
});

class UserForm extends React.Component {
	state = {
		user_experimental_modules: '',
		email_actions: '',
		roles: [],
		first_name: '',
		last_name: '',
		email: '',
		picture: '',
		employee_id: '',
		staticRoles: []
	};

	componentDidMount() {
		// Get all roles
		axios
			.get(BASE_URL_ROLES)
			.then((response) => {
				this.setState({ staticRoles: response.data });
			})
			.catch();

		if (this.props.userId) {
			axios
				.get(BASE_URL_USERS + this.props.userId)
				.then((response) => {
					const user = response.data;
					this.setState({
						user_experimental_modules: user.user_experimental_modules,
						email_actions: user.email_actions,
						roles: user.roles,
						first_name: user.first_name,
						last_name: user.last_name,
						email: user.email,
						picture: user.picture,
						employee_id: user.employee_id
					});
				})
				.catch((err) => {
					console.error(err.response);
					errorMsg(`Error getting employee: ${err.response.data.message}`);
				});
		}
	}

	addRole = (id) => {
		this.setState((prevState) => {
			const user = prevState;
			if (!user.roles.includes(id)) {
				user.roles = user.roles.concat(id);
				return { user };
			}
		});
	};

	removeRole = (id) => {
		this.setState((prevState) => {
			const user = prevState;
			if (user.roles.includes(id)) {
				user.roles = user.roles.filter((role_id) => {
					return role_id !== id;
				});
				return { user };
			}
		});
	};

	onSubmit = (e) => {
		e.preventDefault();
		const user = this.state;
		delete user.staticRoles;
		this.props.onSubmit(user);
	};

	render() {
		const { classes, closeForm } = this.props;
		const { first_name, last_name, email, picture, roles, staticRoles } = this.state;

		const rolesList = staticRoles.map((element) => {
			element.status = roles.includes(element._id.toString());
			return element;
		});

		return (
			<Fade in={true}>
				<div>
					<form className={classes.root} onSubmit={this.onSubmit}>
						<div className={classes.column}>
							<Avatar alt={last_name} src={picture} className={classes.avatar} />
						</div>

						<FormGroup row className={classes.column}>
							<TextField
								className={classes.input}
								key="first_name"
								label="First name"
								name="first_name"
								value={first_name}
								disabled={true}
							/>
							<TextField
								className={classes.input}
								key="last_name"
								label="Last name"
								name="last_name"
								value={last_name}
								disabled={true}
							/>
							<TextField
								className={classes.input}
								key="email"
								label="Email"
								name="email"
								value={email}
								disabled={true}
							/>
						</FormGroup>

						<FormGroup row className={classes.column}>
							<h3>User Roles</h3>

							<div className={classes.roles}>
								{rolesList.map((role) => {
									const { _id, name, status } = role;

									return (
										<FormControlLabel
											key={_id}
											control={
												<Checkbox
													checked={status}
													onChange={() => {
														status
															? this.removeRole(_id.toString())
															: this.addRole(_id.toString());
													}}
													name={name}
													color="primary"
													value={_id}
												/>
											}
											label={name}
										/>
									);
								})}
							</div>
						</FormGroup>

						<div className={classes.actions}>
							<Button type="submit" color="primary">
								Save
							</Button>
							<Button onClick={closeForm} color="primary">
								Cancel
							</Button>
						</div>
					</form>
				</div>
			</Fade>
		);
	}
}

export default withStyles(styles)(UserForm);
