import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Selector from 'components/ui/Selector';
import { errorMsg } from 'utils/alerts';
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BASE_URL_DAYS_OFF, BASE_URL_EMPLOYEES } from 'utils/base';
import moment from 'moment';
import { readableFormatDate } from 'utils';

const styles = (theme) => ({
	root: {
		marginTop: '30px'
	},
	form: {
		width: '100%',
		margin: 0,
		display: 'flex',
		flexWrap: 'wrap',
	},
	inputs: {
		margin: '0 15px 25px 0',
		width: '20%'
	},
	inputs100w: {
		margin: '0 15px -1px 0',
		width: '100%'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	actions: {
		textAlign: 'right',
		margin: '20px 0 0 0'
	}
});

class DayOffForm extends React.Component {
	state = {
		employee: false,
		type: '',
		description: '',
		amount: '',
		createdAt: '',
		expiredAt: '',
		expiresOn: '',
		employees: []
	};

	componentDidMount() {
		if (this.props.dayOffId) {
			axios
				.get(BASE_URL_DAYS_OFF + this.props.dayOffId)
				.then((response) => {
					const dayOff = response.data;
					this.setState({
						employee: dayOff.employee,
						type: dayOff.type,
						description: dayOff.description || '',
						amount: dayOff.amount,
						createdAt: readableFormatDate(moment.utc(dayOff.created_at)),
						expiredAt: dayOff.expired_at ? readableFormatDate(moment.utc(dayOff.expired_at)) : null,
						expiresOn: readableFormatDate(moment.utc(dayOff.created_at).add(1, 'year')),
					});
				})
				.catch((err) => {
					console.error(err.response);
					errorMsg(`Error getting day off: ${err.response.data.message}`);
				});
		}

		axios
			.get(BASE_URL_EMPLOYEES)
			.then((response) => {
				const employees = response.data;
				this.setState({ employees });
			})
			.catch((err) => {
				console.error(err.response);
				errorMsg(`Error getting employees: ${err.response.data.message}`);
			});
	}

	onTextChange = (e) => {
		const field = e.target.name;
		const value = e.target.value;

		if (field === 'amount' && parseInt(value) < 0) return false;

		this.setState({ [field]: value });
	};

	onSwitchChange = (e) => {
		const field = e.target.name;
		this.setState((prevState) => {
			return {
				[field]: !prevState[field]
			};
		});
	};

	onChangeEmployee = (employee) => {
		this.setState({ employee });
	};

	onChangeType = (type) => {
		this.setState({ type });
	};

	onSubmit = (e) => {
		e.preventDefault();
		const dayOff = this.state;
		delete dayOff.employees;
		dayOff.employee = dayOff.employee;
		this.props.onSubmit(dayOff);
	};

	render() {
		const { employee, type, description, amount, employees, createdAt, expiresOn, expiredAt } = this.state;
		const { classes, closeForm } = this.props;

		return (
			<div className={classes.root}>
				<form className={classes.form} onSubmit={this.onSubmit}>
					<div className={classes.inputs}>
						<Autocomplete
							id="employee"
							value={employee}
							options={employees}
							getOptionSelected={(option, value) => option.id === value.id}
							getOptionLabel={(option) => {
								if (option) {
									return option.last_name + ' ' + option.first_name;
								} else {
									return '';
								}
							}}
							onChange={(e, newValue) => {
								this.onChangeEmployee(newValue);
							}}
							style={{ width: 300 }}
							renderInput={(params) => (
								<TextField {...params} label="Employee" variant="outlined" />
							)}
						/>
					</div>

					<div className={classes.inputs}>
						<Selector
							key="type_selector"
							name="type"
							value={type}
							onChange={this.onChangeType}
							options={{
								vacation_postponed_days: 'vacation postponed days',
								vacation_current_days: 'vacation current days',
								vacation_inadvance_days: 'vacation in-advance days',
								compensation: 'days off compensation',
								childbirth: 'childbirth',
								marriage: 'marriage',
								moving: 'moving',
								exam: 'exam',
								sibling_death: 'sibling death',
								parent_death: 'parent death'
							}}
						/>
					</div>

					<TextField
						className={classes.inputs}
						label="Amount"
						type="number"
						name="amount"
						value={amount}
						onChange={this.onTextChange}
					/>

					{(type === 'compensation') &&
					<>
						<TextField
							className={classes.inputs}
							label="Created at"
							name="created_at"
							disabled
							readonly
							value={createdAt}
						/>
						<TextField
							className={classes.inputs}
							label="Expires on"
							name="expires_on"
							disabled
							readonly
							value={expiresOn}
						/>

						{expiredAt &&
							<TextField
								className={classes.inputs}
								label="Expired at"
								name="expired_at"
								disabled
								readonly
								value={expiredAt}
							/>
						}
					 </>
					}

					<TextField
						className={classes.inputs100w}
						name="description"
						key="description"
						value={description}
						inputProps={{ autoComplete: 'off' }}
						label="Description"
						multiline
						rows="6"
						fullWidth
						placeholder="Please input here day off origin description"
						onChange={this.onTextChange}
					/>

					<div className={classes.actions}>
						<Button type="submit" color="primary">
							Save
						</Button>
						<Button onClick={closeForm} color="secondary">
							Cancel
						</Button>
					</div>
				</form>
			</div>
		);
	}
}

export default withStyles(styles)(DayOffForm);
