import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import StyledTableCell from 'components/ui/table/StyledTableCell';
import StyledTableRow from 'components/ui/table/StyledTableRow';
import Fade from '@material-ui/core/Fade';
import WithPermission from 'components/WithPermission';

export const Role = (props) => {
	const { role, onDelete, onModify, disabledActions } = props;
	const { _id, name, description } = role;

	return (
		<Fade in={true}>
			<StyledTableRow key={_id}>
				<StyledTableCell component="th" scope="row">
					{name}
				</StyledTableCell>
				<StyledTableCell>{description}</StyledTableCell>
				<StyledTableCell>
					<WithPermission has={'roles_edit'}>
						<IconButton
							title="Edit Role"
							onClick={() => {
								onModify(role);
							}}
							disabled={disabledActions}
						>
							<CreateOutlinedIcon />
						</IconButton>
					</WithPermission>
					<WithPermission has={'roles_delete'}>
						<IconButton
							title="Remove Role"
							onClick={() => {
								onDelete(role);
							}}
							disabled={disabledActions}
						>
							<DeleteIcon />
						</IconButton>
					</WithPermission>
				</StyledTableCell>
			</StyledTableRow>
		</Fade>
	);
};
