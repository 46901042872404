/**
 * Displays user name and current level,
 * allows navigating to user full badge history
 */
import React from 'react';
//import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import LevelBadge from './LevelBadge';

function UserLevel(props) {
	const { name, id } = props;
	return (
		<div>
			<Link to={'/badges/' + id}>{name}</Link>
			<LevelBadge level={9000} award_date={Date.now()} />
		</div>
	);
}

// TODO uncomment once props are clearer
// UserLevel.PropTypes = {
//  userId: Number,
// }

export default UserLevel;
