const READABLE_FORMAT = 'MMM Do, YYYY';

//Builds an absolute url to this site
export const absoluteUrl = function (url) {
	if (url.charAt(0) !== '/') {
		url = '/' + url;
	}
	return `${window.location.protocol}//${window.location.host}${url}`;
};

export const capitalize = function (str) {
	if (str.length === 0) {
		return str;
	}
	return str.charAt(0).toUpperCase() + str.substring(1);
};

export const isMobileDevice = () => {
	return (
		typeof window.orientation !== 'undefined' ||
		navigator.userAgent.indexOf('IEMobile') !== -1
	);
};

/**
 * Receives a moment date object and formats it
 * humanly readable
 * @param {moment} date
 */
export const readableFormatDate = (date) => {
	return date.format(READABLE_FORMAT);
};

/**
 * Hack for deleting an unused feature component of date picker that breaks the UI
 */
export const removeStaticsPicker = () => {
	const static_dates = document.querySelector('.rdrDefinedRangesWrapper');
	static_dates.parentNode.removeChild(static_dates);
};

export const removeUnderscore = (text) => {
	if (typeof text === 'string') {
		return text.replace(/_/g, ' ');
	}
	return text;
};

/**
 * Hacky way to solve the timezone issue (#155) for PY users
 * @param {Date} date
 */
export const getUTCDate = (date) => {
	const dateOptions = {
		timeZone: 'UTC',
		month: 'long',
		day: 'numeric',
		year: 'numeric'
	};
	const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
	const utcDate = dateFormatter.format(new Date(date));
	return new Date(utcDate);
};

export const copyToClipboard = async (content) => {
	try {
		await navigator.clipboard.writeText(content);
		alert('Copied!');
	} catch (err) {
		alert('Failed to copy!');
	}
};

/**
 * There's no way to customize unique fields error messages, since they are caused by mongo and not mongoose.
 * @param  {String} error_message error message obtained when trying to update a mongo entry
 * @returns {String} human readable string indicating the duplicated field, or the error message unchanged, if it's not a unique error
 */
export function parseMongoErrorMessage(error_message) {
	const expression = /{ (\w*): "(\w*)" }/;
	const match = error_message.match(expression);
	if (!match) {
		return error_message;
	} else {
		return `The field ${match[1]} needs to be unique, and ${match[2]} is already taken`;
	}
}
