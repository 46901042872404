import React from 'react';
import PropTypes from 'prop-types';
import Permissions from 'permissions';
import { UserContext } from 'AppContexts';

/* Helper component used to conditionally render its children depending on the
 user having the requested permissions
 */

export default class WithPermission extends React.PureComponent {
	static contextType = UserContext;

	renderChildren() {
		return this.props.children;
	}

	render() {
		const { has, any, all } = this.props;

		if (has !== undefined && Permissions.has(this.context, has)) {
			return this.renderChildren();
		}
		if (any !== undefined && Permissions.any(this.context, any)) {
			return this.renderChildren();
		}
		if (all !== undefined && Permissions.all(this.context, all)) {
			return this.renderChildren();
		}

		return null;
	}
}

WithPermission.propTypes = {
	has: PropTypes.string,
	any: PropTypes.arrayOf(PropTypes.string),
	all: PropTypes.arrayOf(PropTypes.string)
};
