export const validateData = (objType, data) => {
	const invalids = [];

	switch (objType) {
		case 'days_claim':
			if (!data.type) invalids.push('Type is required');
			if (!data.state) invalids.push('State is required');
			if (!data.employee) invalids.push('Employee is required');
			if (!data.start_date || !data.end_date) invalids.push('Date range is required');
			break
		case 'days_off':
			if (!data.start_date || !data.end_date) invalids.push('Date range is required');
			if (!data.description) invalids.push('Description is required');
			break
		default:
			break
	}

	if (invalids.length === 0) {
		return {
			status: true
		};
	} else {
		const errors = document.createElement('div');
		errors.innerHTML = invalids.join('<br>');

		return {
			status: false,
			errors
		};
	}
};
