import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        margin: 0,
        width: '100%',
        padding: theme.spacing(3)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    deleteEmployee: {
        color: theme.palette.grayScale.lighter,
        margin: theme.spacing(1, 0, 0, 0),
        padding: theme.spacing(1),
        textAlign: 'left',
        fontWeight: 'bold'
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DeleteEmployee = (props) => {
    const {
        classes,
        open,
        onClose,
        onDeleteEmployee,
    } = props;

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="dialog-title"
            open={open}
            maxWidth="sm"
            fullWidth={true}
            className={classes.root}
        >
            <DialogTitle id="dialog-title" onClose={onClose}>
                Employee deletion process
            </DialogTitle>

            <DialogContent dividers>
                <div className={classes.deleteEmployee}>
                Are you trying to delete this employee because is leaving Ekumen? Please, before doing it, read the corresponding instructions in the{'\xa0'}
                    <a
                        rel="noopener noreferrer"
                        href="https://docs.google.com/document/d/1MYk2weZsiVQBheuJ1twsQUq-bdhb8yGX5IkD-oX90Us/edit#bookmark=id.b5b4o4i33igd"
                        target="_blank"
                    >
                        SOO document
                    </a>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDeleteEmployee} variant="contained">
                    Confirm Deletion
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(DeleteEmployee);
