import React from 'react';
import { Route } from 'react-router-dom';
import UsersHome from './UsersHome';
import EditUser from './EditUser';

export default ({ match }) => {
	return (
		<>
			<Route path={match.path} exact component={UsersHome} />
			<Route path={`${match.path}/edit/:id`} component={EditUser} />
		</>
	);
};
