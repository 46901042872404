import swal from 'sweetalert';

/**
 * Modal to delete an object
 * @param {Function} deleteFunction
 */
export const deleteModal = (deleteFunction) => {
	swal({
		title: 'Are you sure?',
		text: 'Once deleted, you will not be able to recover this',
		icon: 'warning',
		buttons: true,
		dangerMode: true
	}).then((willDelete) => {
		if (willDelete) {
			!!deleteFunction && deleteFunction();
			swal('Poof! The data has been deleted!', {
				icon: 'success'
			});
		} else {
			swal('Your data is safe!', {
				icon: 'warning'
			});
		}
	});
};

/**
 * Modal to warn a user about something.
 * @param {String} title
 * @param {String} text
 * @param {Function} trueFunction
 * @param {String} trueMsg
 * @param {String} icon
 * @param {Boolean} dangerMode
 * @param {Function} falseFunction
 * @param {String} FalseMsg
 */
export const booleanModal = (
	title,
	text,
	trueFunction,
	trueMsg = 'Success',
	icon = 'warning',
	dangerMode = true,
	falseFunction = null,
	falseMsg = 'Cancel'
) => {
	swal({
		title: title,
		text: text,
		icon: icon,
		buttons: true,
		dangerMode: dangerMode
	}).then((affirmative) => {
		if (affirmative) {
			!!trueFunction && trueFunction();
			swal(trueMsg, {
				icon: 'success'
			});
		} else {
			!!falseFunction && falseFunction();
			swal(falseMsg, {
				icon: 'warning'
			});
		}
	});
};
