import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import PadawanJourneys from './PadawanJourneys';

const styles = {
	root: {
		flex: 1
	}
};

class PadawanHome extends React.PureComponent {
	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<PadawanJourneys />
			</div>
		);
	}
}

export default withStyles(styles)(PadawanHome);
