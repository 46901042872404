import React from 'react';
import { Route } from 'react-router-dom';

import SettingsHome from './SettingsHome';

export default ({ match }) => {
	return (
		<React.Fragment>
			<Route path={match.path} exact component={SettingsHome} />
		</React.Fragment>
	);
};
