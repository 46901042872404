/**
 * Represents one single badge
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = (theme) => {};

function Badge(props) {
	const { theme, name, description, properties, badge_type = 0 } = props;
	// const propertiesString = Object.keys(properties).map(key => key + ':' + properties.key).join(', ');
	return (
		<Paper
			style={{
				maxWidth: 'fit-content',
				padding: 5,
				backgroundColor: theme.palette.badges[badge_type].background,
				borderColor: theme.palette.badges[badge_type].border,
				whiteSpace: 'nowrap'
			}}
			title={description}
		>
			<Typography
				style={{
					fontWeight: theme.fontWeight.bold,
					color: theme.palette.badges[badge_type].text,
					fontSize: '14px'
				}}
			>
				{name}
			</Typography>
			<Typography
				style={{
					fontWeight: theme.fontWeight.medium,
					color: theme.palette.badges[badge_type].text,
					fontSize: '14px'
				}}
			>
				{properties}
			</Typography>
		</Paper>
	);
}

Badge.propTypes = {
	theme: PropTypes.object,
	//   id: PropTypes.number,
	//   owner_id: PropTypes.number,
	//   updated_at: PropTypes.date,
	//   quarter_id: PropTypes.number,
	//   created_at: PropTypes.date,
	properties: PropTypes.string,
	//   notes: PropTypes.string,
	//   badge_id: PropTypes.number,
	// id: Number,
	name: PropTypes.string,
	description: PropTypes.string,
	// created_at: Date,
	// updated_at: Date,
	// image: string,
	badge_type: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(Badge);
