import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MaterialTable from '@material-table/core';
import permissions from 'permissions';
import moment from 'moment';
import { readableFormatDate } from 'utils';

const Employees = (props) => {
	const { employees, handleEdit, handleCreate, handleDelete, user } = props;
	const allowEdit = !permissions.has(user, 'employees_edit');
	const allowCreate = !permissions.has(user, 'employees_create');
	const allowDelete = !permissions.has(user, 'employees_delete');

	const superUser = !(allowEdit || allowCreate || allowDelete);

	return (
		<MaterialTable
			title="Employee list"
			data={employees}
			options={{
				pageSize: 20,
				actionsColumnIndex: -1,
				paging: true
			}}
			actions={[
				{
					icon: 'edit',
					tooltip: 'Edit',
					disabled: allowEdit,
					onClick: (event, rowData) => handleEdit(rowData._id)
				},
				{
					icon: 'add',
					tooltip: 'Create',
					disabled: allowCreate,
					isFreeAction: true,
					onClick: () => handleCreate()
				},
				{
					icon: 'delete',
					tooltip: 'Delete',
					disabled: allowDelete,
					onClick: (event, rowData) => handleDelete(rowData._id)
				}
			]}
			columns={[
				{
					title: 'Avatar',
					field: 'image_url',
					render: (rowData) => <Avatar alt={rowData.last_name} src={rowData.image_url} />
				},
				{ title: 'Pronoun', field: 'pronoun' },
				{ title: 'Last name', field: 'last_name' },
				{ title: 'First name', field: 'first_name' },
				{ title: 'Email', field: 'email' },
				{ title: 'Location', field: 'location_id' },
				{
					title: 'Is manager',
					field: 'is_manager',
					render: (rowData) => {
						if (rowData.is_manager) {
							return <CheckCircleIcon />;
						}
					}
				},
				{ title: 'Managed by', field: 'manager' },
				{ title: 'Level', field: 'level', type: 'numeric' },
				{
					title: 'Onboard date',
					field: 'onboard_date',
					render: (rowData) => readableFormatDate(moment.utc(rowData.onboard_date)),
					type: 'date'
				},
				superUser
					? {
							title: 'Offboard date',
							field: 'offboard_date',
							render: (rowData) =>
								rowData.offboard_date
									? readableFormatDate(moment.utc(rowData.offboard_date))
									: null,
							type: 'date'
					  }
					: {}
			]}
		/>
	);
};

export default Employees;
