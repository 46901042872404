import React from 'react';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import ClearIcon from '@material-ui/icons/Clear';
import Selector from 'components/ui/Selector';
import DatePickerModal from 'components/ui/DatePickerModal';
import { errorMsg } from 'utils/alerts';
import axios from 'axios';
import { readableFormatDate } from 'utils';
import { BASE_URL_EMPLOYEES, MANAGER_EMPLOYEES } from 'utils/base';

const styles = (theme) => ({
	root: {
		marginTop: '30px'
	},
	form: {
		width: '100%',
		margin: 0,
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	},
	inputs: {
		margin: '0 15px 25px 0',
		width: '20%'
	},
	inputs100w: {
		margin: '0 15px -1px 0',
		width: '100%'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	actions: {
		textAlign: 'right',
		margin: '20px 0 0 0'
	}
});

const HIGHER_LEVEL = 10;
const LOWER_LEVEL = 0;

class EmployeeForm extends React.Component {
	state = {
		first_name: '',
		last_name: '',
		title: '',
		alias: '',
		slug: '',
		email: '',
		image_url: '',
		manager_id: '',
		is_manager: false,
		level: '',
		location_id: '',
		onboard_date: '',
		offboard_date: '',
		gender: '',
		pronoun: '',
		out_of_office: false,
		tax_id: '',
		description: '',
		openCalendar: false,
		managers: [],
		dateField: ''
	};

	componentDidMount() {
		if (this.props.employeeId) {
			axios
				.get(BASE_URL_EMPLOYEES + this.props.employeeId)
				.then((response) => {
					const employee = response.data;
					this.setState({
						first_name: employee.first_name,
						last_name: employee.last_name,
						title: employee.title,
						alias: employee.alias,
						slug: employee.slug,
						email: employee.email,
						image_url: employee.image_url,
						manager_id: employee.manager_id,
						is_manager: employee.is_manager,
						level: employee.level,
						location_id: employee.location_id,
						onboard_date: employee.onboard_date ? new Date(employee.onboard_date) : '',
						offboard_date: employee.offboard_date ? new Date(employee.offboard_date) : '',
						gender: employee.gender,
						pronoun: employee.pronoun,
						out_of_office: employee.out_of_office,
						tax_id: employee.tax_id,
						description: employee.description
					});
				})
				.catch((err) => {
					console.error(err.response);
					errorMsg(`Error getting employee: ${err.response.data.message}`);
				});
		}

		axios
			.get(MANAGER_EMPLOYEES)
			.then((response) => {
				const managers = {};
				response.data.forEach((manager) => {
					managers[manager._id] = `${manager.last_name} ${manager.first_name}`;
				});
				this.setState({ managers });
			})
			.catch((err) => {
				console.error(err.response);
				errorMsg(`Error getting managers: ${err.response.data.message}`);
			});
	}

	onTextChange = (e) => {
		const field = e.target.name;
		const value = e.target.value;

		if (
			(field === 'level' && parseInt(value) > HIGHER_LEVEL) ||
			parseInt(value) < LOWER_LEVEL
		)
			return false;

		this.setState({ [field]: value });
	};

	onSwitchChange = (e) => {
		const field = e.target.name;
		this.setState((prevState) => {
			return {
				[field]: !prevState[field]
			};
		});
	};

	onChangeManager = (manager_id) => {
		this.setState({ manager_id });
	};

	onSelectDate = (date) => {
		const { dateField } = this.state;
		this.setState({ [dateField]: new Date(date) }, () => {
			this.onCloseCalendar();
		});
	};

	onChangeGender = (gender) => {
		this.setState({ gender });
	};

	onChangePronoun = (pronoun) => {
		this.setState({ pronoun });
	};

	openOnboardDate = () => {
		this.setState({ dateField: 'onboard_date' }, () => {
			this.setState({ openCalendar: true });
		});
	};

	openOffboardDate = () => {
		this.setState({ dateField: 'offboard_date' }, () => {
			this.setState({ openCalendar: true });
		});
	};

	onCloseCalendar = () => {
		this.setState({ openCalendar: false, dateField: '' });
	};

	onSubmit = (e) => {
		e.preventDefault();

		const employee = this.state;

		delete employee.managers;
		delete employee.openCalendar;
		delete employee.dateField;

		this.props.onSubmit(employee);
	};

	render() {
		const {
			description,
			first_name,
			last_name,
			title,
			alias,
			slug,
			email,
			level,
			is_manager,
			out_of_office,
			image_url,
			manager_id,
			location_id,
			tax_id,
			gender,
			pronoun,
			onboard_date,
			offboard_date,
			managers,
			openCalendar,
			dateField
		} = this.state;
		const { classes, closeForm } = this.props;

		return (
			<div className={classes.root}>
				<DatePickerModal
					open={openCalendar}
					onSelectDate={this.onSelectDate}
					onCloseCalendar={this.onCloseCalendar}
					date={this.state[dateField]}
					title="Choose a date"
				/>

				<form className={classes.form} onSubmit={this.onSubmit}>
					<TextField
						className={classes.inputs}
						label="Last name"
						key="last_name"
						name="last_name"
						value={last_name}
						onChange={this.onTextChange}
					/>

					<TextField
						className={classes.inputs}
						label="First name"
						key="first_name"
						name="first_name"
						value={first_name}
						onChange={this.onTextChange}
					/>

					<TextField
						className={classes.inputs}
						label="Email"
						placeholder="example@creativa77.com.ar"
						key="email"
						name="email"
						value={email}
						onChange={this.onTextChange}
					/>

					<TextField
						className={classes.inputs}
						label="Location"
						placeholder="HQ/3 or remote/state"
						key="location_id"
						name="location_id"
						value={location_id}
						onChange={this.onTextChange}
					/>

					<TextField
						className={classes.inputs}
						label="Tax ID"
						placeholder="CUIL"
						key="tax_id"
						name="tax_id"
						value={tax_id}
						onChange={this.onTextChange}
					/>

					<div className={classes.inputs}>
						<Selector
							className={classes.inputs}
							key="manager_id_selector"
							name="manager"
							value={manager_id}
							onChange={this.onChangeManager}
							options={managers}
						/>
					</div>

					<div className={classes.inputs}>
						<Selector
							key="gender_selector"
							name="gender"
							value={gender}
							onChange={this.onChangeGender}
							options={{
								male: 'Male',
								female: 'Female'
							}}
						/>
					</div>
					<div className={classes.inputs}>
						<Selector
							key="pronoun_selector"
							name="pronoun"
							value={pronoun}
							onChange={this.onChangePronoun}
							options={{
								'They/Them': 'They/Them',
								'She/Her': 'She/Her',
								'He/Him': 'He/Him',
								'Name as Pronoun': 'Name as Pronoun'
							}}
						/>
					</div>

					<TextField
						className={classes.inputs}
						label="Level"
						type="number"
						name="level"
						value={level}
						max={10}
						min={1}
						onChange={this.onTextChange}
					/>

					<TextField
						className={classes.inputs}
						label="Title"
						key="title"
						name="title"
						value={title}
						onChange={this.onTextChange}
					/>

					<TextField
						className={classes.inputs}
						label="Alias"
						key="alias"
						name="alias"
						value={alias}
						onChange={this.onTextChange}
					/>

					<TextField
						className={classes.inputs}
						label="Slug"
						key="slug"
						name="slug"
						value={slug}
						onChange={this.onTextChange}
					/>

					<TextField
						className={classes.inputs}
						label="Corporative Image"
						placeholder="Paste the image url"
						key="image_url"
						name="image_url"
						value={image_url}
						onChange={this.onTextChange}
					/>

					<TextField
						className={classes.inputs}
						label="Onboard date"
						key="onboard_date"
						name="onboard_date"
						value={onboard_date ? readableFormatDate(moment(onboard_date)) : ''}
						onClick={this.openOnboardDate}
					/>

					<TextField
						className={classes.inputs}
						label="Offboard date"
						key="offboard_date"
						name="offboard_date"
						value={offboard_date ? readableFormatDate(moment(offboard_date)) : ''}
						onClick={this.openOffboardDate}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										size="small"
										aria-label="clean offboard date"
										onClick={(e) => {
											e.stopPropagation();
											this.setState({ offboard_date: '' });
										}}
										edge="end"
									>
										{offboard_date && <ClearIcon />}
									</IconButton>
								</InputAdornment>
							)
						}}
					/>

					<FormControlLabel
						className={classes.inputs}
						control={
							<Switch
								checked={is_manager}
								onChange={this.onSwitchChange}
								name="is_manager"
								key="is_manager"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
							/>
						}
						label="Is manager"
					/>

					<FormControlLabel
						className={classes.inputs}
						control={
							<Switch
								checked={out_of_office}
								onChange={this.onSwitchChange}
								name="out_of_office"
								key="out_of_office"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
							/>
						}
						label="Out of office"
					/>

					<TextField
						className={classes.inputs100w}
						name="description"
						key="description"
						value={description}
						inputProps={{ autoComplete: 'off' }}
						label="Description"
						multiline
						rows="6"
						fullWidth
						placeholder="Please input here a nice resume of employee profile, hobbies are welcomed!."
						onChange={this.onTextChange}
					/>

					<div className={classes.actions}>
						<Button type="submit" color="primary">
							Save
						</Button>
						<Button onClick={closeForm} color="secondary">
							Cancel
						</Button>
					</div>
				</form>
			</div>
		);
	}
}

export default withStyles(styles)(EmployeeForm);
