import axios from 'axios';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import RoleForm from './RoleForm';
import RolesPermissions from './RolesPermissions';
import Roles from './Roles';
import { successMsg, errorMsg } from 'utils/alerts';
import { deleteModal, booleanModal } from 'utils/modals';
import { remove } from 'lodash';
import Grow from '@material-ui/core/Grow';
import WithPermission from 'components/WithPermission';
import { BASE_URL_ROLES, BASE_URL_PERMISSIONS } from 'utils/base';

const styles = (theme) => ({
	root: {
		flexGrow: 1
	},
	roles: {
		display: 'inline-flex',
		width: '40%',
		flexDirection: 'column'
	},
	permissions: {
		display: 'inline-flex',
		width: '59%',
		margin: '0px 0px 0px 14px',
		overflowY: 'scroll',
		height: '87vh'
	},
	roleForm: {
		width: '100%'
	},
	rolesTable: {
		width: '100%'
	},
	reminder: {
		textAlign: 'center'
	}
});

const DEFAULT_STATE = {
	selectedRole: {
		_id: '',
		name: '',
		description: '',
		permissions: []
	},
	roles: [],
	staticPermissions: []
};

class RolesHome extends React.PureComponent {
	state = {
		...DEFAULT_STATE
	};

	componentDidMount() {
		// Get Roles
		axios
			.get(BASE_URL_ROLES)
			.then((response) => {
				const roles = response.data;
				this.setState({ roles });
			})
			.catch((err) => {
				console.error(err);
			});

		// Get static permissions list
		axios
			.get(BASE_URL_PERMISSIONS)
			.then((response) => {
				const staticPermissions = response.data;
				this.setState({ staticPermissions });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	/**
	 * *************** Form Manager *********************************
	 */

	/**
	 * Handle the change of the elements of the form updating the state.
	 * @param {String} field
	 * @param {String} data
	 */
	handleFormChange = (field, data) => {
		this.setState((prevState) => {
			const selectedRole = { ...prevState.selectedRole };
			selectedRole[field] = data;
			return { selectedRole };
		});
	};

	saveFormClickHandler = () => {
		// If there is an id in the state modifies the role.
		if (this.state.selectedRole._id) {
			this.modifyRole();
		} else {
			this.createRole();
		}
	};

	cancelRoleEditionMode = () => {
		// Deselect the the permission table, clean up the form.
		// Throw a modal in case the user regrets and then reset values.
		booleanModal(
			'Are you sure?',
			'You will lose all your changes',
			() => {
				// Clear the form
				this.setState({ selectedRole: DEFAULT_STATE.selectedRole });
			},
			'Edition cancelled'
		);
	};

	/**
	 * *************** Permissions Manager *********************************
	 */

	/**
	 * Remove a specific permissions from the permissions list.
	 * @param {Number} permissionId
	 */
	removePermissionFromRole = (permissionId) => {
		console.debug(`Remove existing permission ${permissionId}`);
		const targetPermissionId = permissionId.toString();
		this.setState((prevState) => {
			// We should remove the permission only if exists in the list
			const selectedRole = { ...prevState.selectedRole };

			if (
				!!targetPermissionId &&
				selectedRole.permissions.includes(targetPermissionId)
			) {
				selectedRole.permissions = remove(
					selectedRole.permissions,
					(element) => element !== targetPermissionId
				);
				return { selectedRole };
			}
		});
	};

	/**
	 * Add a specific permissions to the permissions list.
	 * @param {Number} permissionId
	 */
	addPermissionToRole = (permissionId) => {
		const targetPermissionId = permissionId.toString();

		this.setState((prevState) => {
			// We should add the permission only if not exists in the list
			if (!prevState.selectedRole.permissions.includes(targetPermissionId)) {
				const selectedRole = { ...prevState.selectedRole };
				selectedRole.permissions = prevState.selectedRole.permissions.concat(
					targetPermissionId
				);
				return { selectedRole };
			}
		});
	};

	/**
	 * *************** Roles Manager *********************************
	 */
	createRole = () => {
		const { name, description, permissions } = this.state.selectedRole;

		return axios
			.post(BASE_URL_ROLES, {
				name,
				description,
				permissions
			})
			.then((response) => {
				this.addToList({ ...response.data.data });
				successMsg(response.data.message);
			})
			.catch((error) => {
				console.error(error);
				errorMsg(error.response.data.error);
			});
	};

	modifyRole = async () => {
		const { name, description, permissions, _id } = this.state.selectedRole;

		return axios
			.put(BASE_URL_ROLES + _id, {
				name,
				description,
				permissions
			})
			.then((response) => {
				this.addToList(this.state.selectedRole);
				successMsg(response.data && response.data.message);
			})
			.catch((error) => {
				console.error(error);
				errorMsg(error.response && `Error saving role ${error.response.data.error}`);
			});
	};

	addToList = (data) => {
		this.setState(
			(prevState) => {
				// In case of updating firstly obtain the roles without the updated record
				const roles = prevState.roles.filter((role) => {
					return role._id !== data._id;
				});

				return {
					roles: roles.concat(data).sort()
				};
			},
			() => {
				// Clear the form
				this.setState({ selectedRole: DEFAULT_STATE.selectedRole });
			}
		);
	};

	handleDeleteRole = ({ _id }) => {
		// Open a modal in case the user regrets and send the deleteRole function
		deleteModal(() => this.deleteRole(_id));
	};

	/**
	 * Delete a specific role
	 * @param {String} roleId
	 */
	deleteRole = async (roleId) => {
		return axios
			.delete(BASE_URL_ROLES + roleId, {})
			.then(() => {
				this.setState((prevState) => {
					const roles = prevState.roles.filter((role) => {
						return role._id !== roleId;
					});

					return {
						roles
					};
				});
			})
			.catch((error) => {
				console.error(error);
				errorMsg(error.response.data.error);
			});
	};

	/**
	 * Assign a role to the state.
	 * @param {Object} roleObject
	 */
	setRoleValuesToForm = (role) => {
		const { _id, name, description, permissions } = role;

		this.setState({
			selectedRole: {
				_id,
				name,
				description,
				permissions
			}
		});
	};

	render() {
		const { classes } = this.props;
		const { roles, staticPermissions, selectedRole } = this.state;
		const { name, _id, permissions } = selectedRole;

		return (
			<div className={classes.root}>
				{_id && (
					<Grow in={true}>
						<h1 className={classes.reminder}>
							You are modifying the role: <b> {name} </b>
						</h1>
					</Grow>
				)}
				<div className={classes.roles}>
					<div className={classes.roleForm}>
						<WithPermission any={['roles_edit', 'roles_create']}>
							<RoleForm
								handleInputChange={this.handleFormChange}
								selectedRole={selectedRole}
								onClick={this.saveFormClickHandler}
								cancelRoleEditionMode={this.cancelRoleEditionMode}
							></RoleForm>
						</WithPermission>
					</div>
					<div className={classes.rolesTable}>
						<WithPermission has={'roles_view'}>
							<Roles
								roles={roles}
								onDelete={this.handleDeleteRole}
								onModify={this.setRoleValuesToForm}
								disabledActions={!!_id}
							/>
						</WithPermission>
					</div>
				</div>
				<div className={classes.permissions}>
					<WithPermission has={'permissions_view'}>
						<RolesPermissions
							rolePermissions={permissions}
							staticPermissions={staticPermissions}
							removePermission={this.removePermissionFromRole}
							addPermission={this.addPermissionToRole}
						/>
					</WithPermission>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(RolesHome);
