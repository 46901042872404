import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { removeUnderscore } from 'utils';

const styles = (theme) => ({
	root: {
		'& h4': {
			margin: '4px 0 4px 0',
			padding: 0,
			'text-transform': 'uppercase'
		}
	},
	type: {
		textTransform: 'capitalize',
		margin: 0
	},
	on_review: {
		color: '#FFBE33'
	},
	approved: {
		color: '#1EE26B'
	},
	declined: {
		color: '#FF4233'
	},
	scheduled: {
		color: theme.palette.primary.light
	},
	in_progress: {
		color: '#33BBFF'
	},
	already_taken: {
		color: '#BB7510'
	}
});

const ClaimState = (props) => {
	const { claim, classes } = props;

	return (
		<div className={classes.root}>
			<h4 className={classes[claim.state]}>{removeUnderscore(claim.state)}</h4>
			<p className={classes.type}>{removeUnderscore(claim.type)}</p>
		</div>
	);
};

export default withStyles(styles)(ClaimState);
