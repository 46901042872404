import React from 'react';
import EmployeeAvailableDays from './EmployeeAvailableDays';
import PeriodsDaysUpdates from './PeriodsDaysUpdates';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
	root: {
		margin: 0,
		width: '100%',
		padding: theme.spacing(3)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	daysOff: {
		display: 'flex',
		flexWrap: 'wrap'
	}
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

const EmployeeDays = (props) => {
	const {
		classes,
		open,
		availableDays,
		onClose,
		onOpenPostponeDaysInstructions,
		onOpenInadvanceDaysInstructions
	} = props;
	const currentVacationDays = availableDays.find(
		(dayOff) => dayOff.type === 'vacation_current_days'
	);
	const inAdvanceVacationDays = availableDays.find(
		(dayOff) => dayOff.type === 'vacation_inadvance_days'
	);

	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="dialog-title"
			open={open}
			maxWidth="sm"
			fullWidth={true}
			className={classes.root}
		>
			<DialogTitle id="dialog-title" onClose={onClose}>
				Your available days off
			</DialogTitle>

			<DialogContent dividers>
				<div className={classes.daysOff}>
					{availableDays.map((dayOff, i) => {
						if (dayOff.amount > 0 && !dayOff.expired_at && !dayOff.event_binded) {
							return <EmployeeAvailableDays key={dayOff.type + i} dayOff={dayOff} />;
						}
						return null;
					})}

					<PeriodsDaysUpdates
						onOpenPostponeDaysInstructions={onOpenPostponeDaysInstructions}
						onOpenInadvanceDaysInstructions={onOpenInadvanceDaysInstructions}
						currentVacationDays={currentVacationDays}
						inadvanceVacationDays={inAdvanceVacationDays}
					/>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default withStyles(styles)(EmployeeDays);
