import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import groupBy from 'lodash/groupBy';

import BadgeListItem from './BadgeListItem';
import { withData } from 'components/withData';

//TODO: there are some padding issues I couldn't fix
const styles = (theme) => ({
	list: {
		width: '100%',
		margin: 0
	},
	panel: {
		background: theme.palette.grey[100],
		padding: theme.spacing(0, 1, 0, 1),
		margin: 0
	},
	panelExpanded: {
		background: theme.palette.secondary.main,
		margin: 0,
		padding: theme.spacing(0, 1, 0, 1)
	},
	detailsPanel: {
		padding: 0
	}
});

/**
 * List of badges (groupd by type) which a user can select. When a badge is
 * selected, the prop 'onBadgeClicked' is called with the selected badge.
 */
class BadgeList extends React.PureComponent {
	static getDerivedStateFromProps(props, state) {
		const badgesBytype = groupBy(props.data, 'badge_type');

		return {
			badges: badgesBytype
		};
	}

	state = {
		expanded: 0 //Initially expand 'core badges' panel
	};

	getChangeHandler = (type) => {
		return (e, newExpanded) => {
			this.setState({ expanded: type });
		};
	};

	renderPanel(type, title) {
		const { classes, readOnly } = this.props;
		const { expanded } = this.state;

		const badges = this.state.badges[`${type}`];
		return (
			<Accordion
				expanded={expanded === type}
				onChange={this.getChangeHandler(type)}
				className={classes.root}
			>
				<AccordionSummary
					classes={{ root: classes.panel, expanded: classes.panelExpanded }}
				>
					<Typography>{title}</Typography>
				</AccordionSummary>
				<AccordionDetails classes={{ root: classes.detailsPanel }}>
					<List dense className={classes.list}>
						{badges.map((badge) => (
							<BadgeListItem
								key={badge._id}
								badge={badge}
								readOnly={readOnly}
								onBadgeClicked={this.props.onBadgeClicked}
							/>
						))}
					</List>
				</AccordionDetails>
			</Accordion>
		);
	}

	render() {
		return (
			<div>
				{this.renderPanel(0, 'Core badges')}
				{this.renderPanel(1, 'Elective badges')}
				{this.renderPanel(2, 'One-off badges')}
			</div>
		);
	}
}

BadgeList.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	onBadgeClicked: PropTypes.func.isRequired
};

export default withData(withStyles(styles)(BadgeList), '/api/badges');
