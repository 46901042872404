/**
 * About page
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
	mainContent: {
		display: 'flex',
		alignItems: 'top',
		justifyContent: 'center',
		flexDirection: 'column',
		height: '100%',
	},
	paper: {
		minWidth: '400px',
		maxWidth: '650px',
		width: '50vw',
		margin: 'auto',
		border: `1px solid ${theme.palette.secondary.dark}`,
		padding: theme.spacing(2)
	},
	title: {
		color: theme.palette.primary.main,
		fontWeight: 'bold'
	},
});

class About extends React.PureComponent {

	render() {
		const { classes } = this.props;

		return (

				<Paper className={classes.paper}>
					<Typography
						variant="h2"
						component="h2"
						gutterBottom
						align="center"
						className={classes.title}	>
              ABOUT
            </Typography>
				</Paper>

		);
	}
}

About.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(About);
