import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import WithPermission from 'components/WithPermission';

const styles = (theme) => ({
	description: {
		margin: '0 10px 0 10px',
		padding: '0 10px 0 10px',
		width: '100%'
	},
	form: {
		margin: '0px',
		border: `1px solid ${theme.palette.primary.main}`,
		padding: '5px',
		display: 'flex',
		justifyContent: 'space-evenly'
	},
	input: {
		marginLeft: '6px'
	}
});

const RoleForm = (props) => {
	const {
		classes,
		handleInputChange,
		selectedRole,
		onClick,
		cancelRoleEditionMode
	} = props;
	const { _id, name, description } = selectedRole;

	const handleInput = (event) => {
		handleInputChange(event.target.name, event.target.value);
	};

	return (
		<form className={classes.form}>
			<TextField
				className={classes.input}
				id="roles-name"
				label="Name"
				type="text"
				name="name"
				multiline
				value={name}
				onChange={handleInput}
				InputProps={{
					autoComplete: 'off'
				}}
			/>
			<TextField
				className={classes.input}
				id="roles-description"
				label="Description"
				name="description"
				InputProps={{
					autoComplete: 'off'
				}}
				multiline
				onChange={handleInput}
				value={description}
			/>

			{_id ? (
				<Fab color="primary" onClick={onClick}>
					<SaveIcon />
				</Fab>
			) : (
				<WithPermission has={'roles_create'}>
					<Fab color="primary" onClick={onClick}>
						<AddIcon />
					</Fab>
				</WithPermission>
			)}

			{_id && (
				<Fab color="secondary" onClick={cancelRoleEditionMode}>
					<CloseIcon />
				</Fab>
			)}
		</form>
	);
};

export default withStyles(styles)(RoleForm);
