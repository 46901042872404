/**
 * Home page placeholder
 * what to add here? :thinking:
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import LinksWidget from './LinksWidget';
import SalesWidget from './SalesWidget';
import HiringsWidget from './HiringsWidget';
import UpcomingEvents from './UpcomingEvents';
import ActionsWidget from './ActionsWidget';
import Section from 'components/ui/Section';

const styles = (theme) => {
	/*
  xs 0
  sm 600
  md 960
  lg 1280
  xl 1920
  */
	return {
		root: {
			display: 'flex',
			flexFlow: 'row wrap',
			justifyContent: 'stretch'
		},
		widget: {
			flexGrow: 1,
			[theme.breakpoints.only('xs')]: {
				padding: theme.spacing(0, 0, 1, 0),
				minWidth: 200
			},
			[theme.breakpoints.up('xs')]: {
				minWidth: 480
			},
			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(2, 2, 0, 2)
			}
		}
	};
};

class Home extends Component {
	render() {
		const { classes } = this.props;

		return (
			<div className={classes.root}>
				<div className={classes.widget}>
					<Section title="Useful links">
						<LinksWidget />
					</Section>
				</div>
				<div className={classes.widget}>
					<Section title="Upcoming events">
						<UpcomingEvents />
					</Section>
				</div>
				<div className={classes.widget}>
					<Section title="Sales news">
						<SalesWidget />
					</Section>
				</div>
				<div className={classes.widget}>
					<Section title="Hirings news">
						<HiringsWidget />
					</Section>
				</div>
				<div className={classes.widget}>
					<Section title="Actions">
						<ActionsWidget />
					</Section>
				</div>
			</div>
		);
	}
}

Home.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Home);
