import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const styles = (theme) => {
	return {
		root: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			padding: '20px',
			margin: '5px'
		},
		paper: {
			padding: theme.spacing(3, 2),
			background: theme.palette.error.light,
			border: `1px solid ${theme.palette.error.main}`
		},
		textarea: {
			width: '100%'
		}
	};
};

/* A 'nice' error page */
//TODO: Add automatic error reporting
//TODO: Add 'user-input' to the report
//TODO: Handle specific + general error cases
class ErrorComponent extends React.PureComponent {
	render() {
		const { classes, context, error } = this.props;
		return (
			<div className={classes.root}>
				<Paper className={classes.paper}>
					<Typography variant="h5" component="h3">
						Error!
					</Typography>
					<Typography component="p">An unexpected error occurred.</Typography>
					<pre>Context: {context}</pre>
					<Typography component="p">Error:</Typography>
					<TextareaAutosize
						className={classes.textarea}
						rows={5}
						disabled={'disabled'}
						value={Object.keys(error)
							.map((key, i) => `*${key}: ${JSON.stringify(error[key], null, 1)}`)
							.join('\n')}
					/>
				</Paper>
			</div>
		);
	}
}

ErrorComponent.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ErrorComponent);
