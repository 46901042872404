import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import { withData } from 'components/withData';

const styles = (theme) => ({
	list: {}
});

/**
 * This widget displays any upcoming events and their details. It is used in
 * the home dashboard.
 */

class UpcomingEvents extends React.PureComponent {
	render() {
		const { classes, data } = this.props;

		const dense = false;
		const secondary = true;
		return (
			<List dense={dense} className={classes.list}>
				{data.map((news, i) => (
					<ListItem key={i}>
						<ListItemAvatar>
							<Avatar>
								<FolderIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={news.title}
							secondary={secondary ? 'Secondary text' : null}
						/>
						<ListItemSecondaryAction>
							<IconButton edge="end" aria-label="delete">
								<DeleteIcon />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</List>
		);
	}
}

UpcomingEvents.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withData(withStyles(styles)(UpcomingEvents), '/api/news/calendar');
