import React from 'react';
import { Route, Link } from 'react-router-dom';
import { UserContext } from 'AppContexts';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';

import Permissions from 'permissions';
import WithTitle from 'components/WithTitle';

import Payslips from './Payslips'
import PdfViewer from './PdfViewer';
import UploadPayslips from './UploadPayslips'
import VerifyPayslip from './VerifyPayslip';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    },
    mainContent: {
        margin: theme.spacing(2)
    }
});


class PayslipsIndex extends React.PureComponent {

    renderTab(title, url) {
        return <Tab label={title} value={url} component={Link} to={url} />;
    }

	renderAdminTab(title, url, user) {
        if (Permissions.has(user, 'payslips_upload')) {
            return this.renderTab(title, url);
        }
        return null;
	}    
    
    render() {
        const { match, title, experimental, location, classes} = this.props
        return (
            <UserContext.Consumer>
            {(user) => (
                <div className={classes.root}>
                    <AppBar position="static" color="secondary">
                        <Tabs
                            value={location.pathname}
                            aria-label="payslips"
                            indicatorColor="primary"
                        >
                            {this.renderAdminTab('Upload Payslips', `${match.path}/upload`, user)}
                            {this.renderTab('My Payslips', `${match.path}`)}
                        </Tabs>
                    </AppBar>
                    <WithTitle title={title} experimental={experimental}>
                        <Route path={`${match.path}/upload`} exact component={UploadPayslips} />
                        <Route path={`${match.path}/unverified`} exact component={VerifyPayslip} />
                        <Route path={`${match.path}/view/:month`} exact component={PdfViewer} />
                        <Route path={`${match.path}`} exact component={Payslips} />
                    </WithTitle>
                </div>
                )}
            </UserContext.Consumer>                
        );
    }
};

export default withStyles(styles)(PayslipsIndex);
