import React from 'react';
import { Route } from 'react-router-dom';
import Profile from './Profile';
import WithTitle from 'components/WithTitle';

export default ({ match, title, experimental }) => {
	return (
		<WithTitle title={title} experimental={experimental}>
			<Route path={match.path} exact component={Profile} />
		</WithTitle>
	);
};
