import React from 'react';
import { Route } from 'react-router-dom';

import About from './About';
import WithTitle from 'components/WithTitle';

export default ({ match, title, experimental}) => {
	return (
		<WithTitle title={title} experimental={experimental}>
			<Route path={match.path} exact component={About} />
		</WithTitle>
	);
};
