import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { readableFormatDate } from 'utils';

const styles = (theme) => ({
	root: {
		margin: '20px 0 0 0',
		padding: '10px',
		'text-align': 'left',
		'font-weight': 'bold'
	},
	alerts_dates: {
		color: theme.palette.grayScale.lighter
	}
});

const PeriodsDaysUpdates = (props) => {
	const {
		classes,
		inadvanceVacationDays,
		currentVacationDays,
		onOpenPostponeDaysInstructions,
		onOpenInadvanceDaysInstructions
	} = props;
	const currentMonth = moment().month();
	const VACATION_PERIOD_END_MONTH = 3;
	const VACATION_PERIOD_BEGIN_MONTH = 9;

	/**
	 * Get the future nearest Apr 31th when current days expires.
	 */
	const getExpireDate = () => {
		const date = moment().month(3).date(30).hour(0).minute(0).second(0);
		if (date < moment()) return date.add(12, 'months');
		return date;
	};

	/**
	 * Get the future nearest Oct 1st when days renew (advanced days become currents).
	 */
	const getRenewalDate = () => {
		const date = moment().month(9).date(1).hour(0).minute(0).second(0);
		if (date < moment()) return date.add(12, 'months');
		return date;
	};

	/**
	 * If today date is between Jan and Apr the vacation period is near to finish
	 */
	const periodIsFinishing = () => {
		return currentMonth <= VACATION_PERIOD_END_MONTH;
	};

	/**
	 * If today date is between Apr and Oct the next vacation period is near to start
	 */
	const nextPeriodWillStart = () => {
		return (
			currentMonth > VACATION_PERIOD_END_MONTH &&
			currentMonth < VACATION_PERIOD_BEGIN_MONTH
		);
	};

	return (
		<div className={classes.root}>
			<div className={classes.alerts_dates}>
				To know other days off you have, read the{' '}
				<a
					rel="noopener noreferrer"
					href="https://docs.google.com/document/d/1_OxLkzS7OpjOmh6HDu4sHsVUqO2UctaTok6VC1HH9y0/edit"
					target="_blank"
				>
					Vacation & Days off document
				</a>
			</div>

			{currentVacationDays && currentVacationDays.amount > 0 && periodIsFinishing() ? (
				<div className={classes.alerts_dates}>
					{`In ${readableFormatDate(getExpireDate())} ${
						currentVacationDays.amount
					} vacation days will expire.`}
					<a href="#" onClick={onOpenPostponeDaysInstructions}>
						Click here
					</a>
					{' to postpone them before that'}
				</div>
			) : (
				''
			)}

			{inadvanceVacationDays &&
			inadvanceVacationDays.amount > 0 &&
			nextPeriodWillStart() ? (
				<div className={classes.alerts_dates}>
					{`In ${readableFormatDate(getRenewalDate())} ${
						inadvanceVacationDays.amount
					} vacation days will renew, `}
					<a href="#" onClick={onOpenInadvanceDaysInstructions}>
						know how to use them in advance
					</a>
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default withStyles(styles)(PeriodsDaysUpdates);
