const permissions = {};

/**
 * Checks if the user has all permissions
 * @param {Object} user
 * @param {[String]} permissionList
 */
permissions.all = function (user, permissionList) {
	return permissionList.every(function (permission) {
		return user.permissions.includes(permission.toLowerCase());
	});
};

/**
 * Checks if the user has an specific permission
 * @param {Object} user
 * @param {String} permission
 */
permissions.has = function (user, permission) {
	return user.permissions.includes(permission.toLowerCase());
};

/**
 * Checks if the user has any of the permission
 * @param {Object} user
 * @param {[String]} permissionList
 */
permissions.any = function (user, permissionList) {
	return permissionList.some(function (permission) {
		return user.permissions.includes(permission.toLowerCase());
	});
};

export default Object.freeze(permissions);
