import axios from 'axios';
import React from 'react';
import MaterialTable from '@material-table/core';
import WithPermission from 'components/WithPermission';
import { withStyles } from '@material-ui/core/styles';

import { errorMsg } from 'utils/alerts';
import { BASE_URL_PAYSLIPS } from 'utils/base';

const styles = {
    root: {
		flex: 1
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    table: {
        width: '80%',
        height: '100px'
    },
};

class Payslips extends React.PureComponent {

    state = {
        payslips: []
    }
    
    handleView = (month) => {
        const { history } = this.props;
        history.push(`${this.props.match.path}/view/${month}`);
    }

    componentDidMount() {
        axios
            .get(BASE_URL_PAYSLIPS)
            .then((response) => {
                this.setState({ payslips: response.data });
            })
            .catch((err) => {
                console.error(err.response);
                errorMsg(`Error getting payslips: ${err.response.data.message}`);
            });
    }

	render() {
        const { classes } = this.props;
        const { payslips } = this.state;

        return (
            <WithPermission has={'payslips_view'}>
                <br/>
                <div className={classes.container}>
                    <div className={classes.table}>
                        <MaterialTable
                            className={classes.table}
                            title="Payslips"
                            data={payslips}
                            options={{
                                search: false,
                                pageSize: 10,
                                actionsColumnIndex: -1,
                                paging: true,
                                filtering: false
                            }}
                            actions={[
                                {
                                    icon: 'search',
                                    tooltip: 'View',
                                    onClick: (event, rowData) => this.handleView(rowData.month)
                                }
                            ]}
                            columns={[
                                { title: 'Month', field: 'month' }
                            ]}
                        />
                    </div>
                </div>
            </WithPermission>
        )
    }
}

export default withStyles(styles)(Payslips);
