/**
 * Represents one single badge
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = (theme) => {};

function LevelBadge(props) {
	const { theme, level = 5, award_date = Date.now() } = props;
	return (
		<Paper
			style={{
				padding: 5,
				maxWidth: 'fit-content',
				backgroundColor: theme.palette.secondary.light
			}}
		>
			<Typography
				style={{
					fontWeight: theme.fontWeight.medium,
					color: theme.palette.grayScale.white
				}}
			>
				Level: {level} {award_date}
			</Typography>
		</Paper>
	);
}

LevelBadge.propTypes = {
	//   id: PropTypes.number,
	//   owner_id: PropTypes.number,
	//   updated_at: PropTypes.date,
	//   quarter_id: PropTypes.number,
	//   created_at: PropTypes.date,
	properties: PropTypes.string,
	//   notes: PropTypes.string,
	//   badge_id: PropTypes.number,
	// id: Number,
	name: PropTypes.string,
	description: PropTypes.string,
	// created_at: Date,
	// updated_at: Date,
	// image: string,
	badge_type: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(LevelBadge);
