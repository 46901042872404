import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
	root: {
		margin: 0,
		width: '100%',
		padding: theme.spacing(3)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	help: {
		textAlign: 'justify',
		fontSize: '1.1em',
		fontWeight: 'bold',
		marginBottom: '14px',
		'& .highlight': {
			color: theme.palette.primary.light
		}
	},
	confirm: {
		marginTop: '15px'
	}
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

const PostponeDaysInstructions = (props) => {
	const {
		open,
		classes,
		requestDaysPostponement,
		onClose,
		currentVacationDays
	} = props;

	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="dialog-title"
			open={open}
			maxWidth="sm"
			fullWidth={true}
			className={classes.root}
		>
			<DialogTitle id="dialog-title" onClose={onClose}>
				Postpone your days
			</DialogTitle>

			<DialogContent dividers>
				<p className={classes.help}>
					Each year’s vacation period goes from October 1st to April 30st of the following
					year.{' '}
					<span className="highlight">
						Ekumen employees can place a request to postpone them for later
					</span>
				</p>

				<p className={classes.help}>
					You have <span className="highlight">{currentVacationDays.amount} </span>
					vacation days off, available during the current period. You can use or postpone
					them before the expiration date. If you choose to postpone them, please download
					and fill out the paper form. Send a scanned copy to HRadmin@ekumenlabs.com (As
					soon as you can, bring us the physical copy to HQ).
				</p>

				<p className={classes.help}>
					We need to stress on the fact that if you don't complete these procedures as
					instructed, your days off will be lost.
				</p>

				<p className={classes.help}>
					The next button will create and send your postponement request to your manager
					and will redirect you to the form.
				</p>

				<Button
					className={classes.confirm}
					variant="contained"
					color="primary"
					onClick={() => {
						requestDaysPostponement(currentVacationDays._id);
					}}
				>
					Request postponement & Download form
				</Button>
			</DialogContent>
		</Dialog>
	);
};

export default withStyles(styles)(PostponeDaysInstructions);
