import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DayOffForm from './DayOffForm';
import WithPermission from 'components/WithPermission';
import axios from 'axios';
import { successMsg, errorMsg } from 'utils/alerts';
import { BASE_URL_DAYS_OFF } from 'utils/base';

const styles = (theme) => ({
	root: {
		margin: 0,
		flexGrow: 1,
		width: '100%',
		padding: theme.spacing(3)
	}
});

const CreateDayOff = (props) => (
	<WithPermission has={'days_off_create'}>
		<div className={props.classes.root}>
			<h2>Create day off</h2>
			<DayOffForm
				closeForm={() => {
					props.history.push('/days_off');
				}}
				onSubmit={(dayOff) => {
					axios
						.post(BASE_URL_DAYS_OFF, dayOff)
						.then((response) => {
							successMsg(response.data.message);
							props.history.push('/days_off');
						})
						.catch((err) => {
							console.error(err.response);
							errorMsg(`Error saving: ${err.response.data.message}`);
						});
				}}
			/>
		</div>
	</WithPermission>
);

export default withStyles(styles)(CreateDayOff);
