import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import StyledTableCell from 'components/ui/table/StyledTableCell';
import StyledTableRow from 'components/ui/table/StyledTableRow';

import { withData } from 'components/withData';

const styles = (theme) => ({
	root: {
		flexGrow: 1
	}
});

class Row extends React.PureComponent {
	render() {
		const { row } = this.props;
		return (
			<StyledTableRow key={row._id}>
				<StyledTableCell component="th" scope="row">
					{row._id}
				</StyledTableCell>
				<StyledTableCell>{row.value}</StyledTableCell>
			</StyledTableRow>
		);
	}
}

class SettingsHome extends React.PureComponent {
	render() {
		const { data, classes } = this.props;

		return (
			<div className={classes.root}>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableCell>ID</StyledTableCell>
							<StyledTableCell>Value</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row) => (
							<Row key={row._id} row={row} reloadData={this.props.reloadData} />
						))}
					</TableBody>
				</Table>
			</div>
		);
	}
}

export default withStyles(styles)(withData(SettingsHome, '/api/settings'));
