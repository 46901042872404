import React from 'react';
import DaysClaimForm from './DaysClaimForm';
import DaysOffCompensationForm from './DaysOffCompensationForm';
import Claims from './Claims';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import swal from 'sweetalert';
import ClaimsFilter from './ClaimsFilter';
import ClaimsCount from './ClaimsCount';
import EmployeeDays from './EmployeeDays';
import moment from 'moment';
import { validateData } from 'validation';
import { UserContext } from 'AppContexts';
import WithPermission from 'components/WithPermission';
import { successMsg, errorMsg, assureAction } from 'utils/alerts';
import {
	BASE_URL_DAYS_CLAIM,
	BASE_URL_DAYS_OFF,
	DAYS_OFF_REQUEST_FORM
} from 'utils/base';
import PostponeDaysInstructions from './PostponeDaysInstructions';
import InadvanceDaysInstructions from './InadvanceDaysInstructions';
import permissions from 'permissions';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
		flexGrow: 1
	},
	dayOffActions: {
		marginRight: '10px',
		marginBottom: '20px'
	}
});

class DaysClaim extends React.PureComponent {
	static contextType = UserContext;

	state = {
		showClaimForm: false,
		showDayOffForm: false,
		showAvailableDays: false,
		showPostponeDaysInstructions: false,
		showInadvanceDaysInstructions: false,
		availableDays: [],
		claims: [],
		claim: false,
		visiblesCount: 0,
		currentVacationDays: false,
		vacationInadvanceForm: '',
		vacationPostponementForm: ''
	};

	openClaimForm = () => {
		this.getAvailableDaysOff();
		this.setState({ claim: false, showClaimForm: true });
	};

	openDayOffForm = () => {
		this.setState({ showDayOffForm: true });
	};

	componentDidMount() {
		this.getDaysRequestForms();
		this.showClaimsList();
		
		if (window.location.hash.includes('show-postponement-instructions')) {
			this.showPostponementInstructions();
		}
	}

	getDaysRequestForms = () => {
		axios
			.get(DAYS_OFF_REQUEST_FORM)
			.then((response) => {
				const { vacationPostponementForm, vacationInadvanceForm } = response.data;
				this.setState({ vacationPostponementForm, vacationInadvanceForm });
			})
			.catch((err) => {
				console.error(err.response);
				errorMsg(err.response.data.error);
			});
	};

	showPostponementInstructions = () => {
		const user = this.context;
		axios
			.get(`${BASE_URL_DAYS_OFF + user.employee_id}/type/vacation_current_days`)
			.then((response) => {
				this.setState({ currentVacationDays: response.data[0] }, () => {
					this.setState({ showPostponeDaysInstructions: true });
				});
			})
			.catch((err) => {
				console.error(err.response);
				errorMsg(err.response.data.error);
			});
	};

	onSubmit = (payload) => {
		const validation = validateData('days_claim', payload);

		if (!validation.status) {
			swal({
				title: 'Validation errors',
				content: validation.errors,
				icon: 'warning'
			});
			return;
		}

		this.setState({ showClaimForm: false });

		if (payload.id) {
			axios
				.put(BASE_URL_DAYS_CLAIM + payload.id, payload)
				.then((response) => {
					this.addToList(response.data.data);
					this.showInadvanceInstructions(payload.id);
					successMsg(response.data.message);
				})
				.catch((err) => {
					console.error(err.response);
					errorMsg(`Error saving days claim: ${err.response.data.message}`);
				});
		} else {
			axios
				.post(BASE_URL_DAYS_CLAIM, payload)
				.then((response) => {
					this.addToList(response.data.data);
					this.showInadvanceInstructions(response.data.data._id);
					successMsg(response.data.message);
				})
				.catch((err) => {
					console.error(err.response);
					errorMsg(`Error saving days claim: ${err.response.data.message}`);
				});
		}
	};

	saveDaysOffCompensation = (payload) => {
		const validation = validateData('days_off', payload);

		if (!validation.status) {
			swal({
				title: 'Validation errors',
				content: validation.errors,
				icon: 'warning'
			});
			return;
		}

		this.setState({ showDayOffForm: false });

		axios
			.post(BASE_URL_DAYS_OFF, payload)
			.then((response) => {
				successMsg(response.data.message);
			})
			.catch((err) => {
				console.error(err.response);
				errorMsg(`Error saving days off: ${err.response.data.message}`);
			});
	};

	addToList = (data) => {
		this.setState(
			(prevState) => {
				// In case of updating firstly obtain the claims without the updated record
				const claims = prevState.claims.filter((claim) => {
					return claim._id !== data._id;
				});

				return {
					claims: claims.concat(data)
				};
			},
			() => {
				this.handleFilter({});
			}
		);
	};

	getAvailableDaysOff = () => {
		const user = this.context;
		axios
			.get(`${BASE_URL_DAYS_OFF + user.employee_id}/employee`)
			.then((response) => {
				this.setState({ availableDays: response.data }, () => {
					this.setState({ claim: false, showClaimForm: true });
				});
			})
			.catch((err) => {
				console.error(err.response);
				errorMsg(err.response.data.error);
			});
	};

	showClaimsList = () => {
		axios
			.get(BASE_URL_DAYS_CLAIM)
			.then((response) => {
				const claims = response.data;
				// get only the claims with an employee attribute not null
				const claimsWithEmployee = claims.filter((claim) => claim.employee !== null);
				this.setState({ claims: claimsWithEmployee });

				if (window.location.search.includes('_id')) {
					const urlParams = new URLSearchParams(window.location.search);
					const id = urlParams.get('_id');
					this.handleFilter({}, id);

					if (window.location.hash.includes('show-inadvance-instructions')) {
						this.showInadvanceInstructions(id);
					}
				} else {
					this.handleFilter({});
				}
			})
			.catch((err) => {
				console.error(err.response);
				errorMsg(`Error listing days claim ${err.response.data.message}`);
			});
	};

	showInadvanceInstructions = (id) => {
		const selectedClaim = this.state.claims.find((c) => c._id === id);
		const user = this.context;

		if (selectedClaim.employee._id === user.employee_id) {
			if (selectedClaim.vacation_inadvance_days > 0) {
				this.setState({ claim: selectedClaim }, () => {
					this.setState({ showInadvanceDaysInstructions: true });
				});
			}
		}
	};

	onCloseDaysOff = () => {
		this.setState({ showAvailableDays: false });
	};

	onCloseDaysClaimForm = () => {
		this.setState({ showClaimForm: false });
	};

	onCloseDaysOffCompensationForm = () => {
		this.setState({ showDayOffForm: false });
	};

	onOpenInadvanceDaysInstructions = () => {
		this.setState({ showAvailableDays: false }, () => {
			this.setState({ showInadvanceDaysInstructions: true });
		});
	};

	onCloseInadvanceDaysInstructions = () => {
		this.setState({ showInadvanceDaysInstructions: false });
	};

	onOpenPostponeDaysInstructions = () => {
		this.setState({ showAvailableDays: false }, () => {
			this.showPostponementInstructions();
		});
	};

	onClosePostponeDaysInstructions = () => {
		this.setState({ showPostponeDaysInstructions: false });
		this.setState({ currentVacationDays: false });
	};

	requestDaysPostponement = (id) => {
		assureAction(
			'Hey! You can still use these days before April 30th, are you sure to postpone them?... Then go ahead!',
			() => {
				window.open(this.state.vacationPostponementForm);

				axios
					.post(`${BASE_URL_DAYS_OFF + id}/request_postponement`)
					.then((response) => {
						this.onClosePostponeDaysInstructions();
						successMsg(response.data.message, 10000);
					})
					.catch((err) => {
						console.error(err.response);
						errorMsg(`Error requestin postergation ${err.response.data.message}`);
					});
			}
		);
	};

	requestDaysInadvance = () => {
		window.open(this.state.vacationInadvanceForm);
	};

	handleUpdate = (claim) => {
		this.setState({ claim, showClaimForm: true });
	};

	handleDelete = ({ _id }) => {
		assureAction('Are you sure to delete this days claim?', () => {
			axios
				.delete(BASE_URL_DAYS_CLAIM + _id)
				.then((response) => {
					successMsg(response.data.message);

					this.setState(
						(prevState) => ({
							claims: prevState.claims.filter((claim) => claim._id !== _id)
						}),
						() => {
							this.checkVisiblesCount();
						}
					);
				})
				.catch((err) => {
					console.error(err.response);
					errorMsg(`Error deleting days claim ${err.response.data.message}`);
				});
		});
	};

	handleFilter = (
		{ name, type, state, calendarClaimDates, calendarClaimCreationDate, onlyMines },
		id = false
	) => {
		// Logged user employee object
		const user = this.context;

		this.setState(
			(prevState) => {
				const claims = prevState.claims.filter((claim) => {
					let show = true;

					// Filter only mines
					if (onlyMines) {
						if (user.employee_id !== claim.employee._id) {
							show = false;
						}
					} else {
						// Filter not only mines, but from all employees' claims

						const userCanViewAllClaims = permissions.has(user, 'days_claim_view_all');

						// If user has not permission to view all the claims
						if (!userCanViewAllClaims) {
							const isOwner = user.employee_id === claim.employee._id;
							const isManager = user.employee_id === claim.employee.manager_id;

							// If logged employee is not the claim employee manager
							// (or the sustitute manager), and
							// If logged employee is not claim owner
							if (!isOwner && !isManager) {
								// Logged employee can not see not in_progress claims
								if (claim.state !== 'in_progress') {
									show = false;
								}
							}
						}
					}

					// Filter by id
					if (id) {
						if (id !== claim._id) {
							show = false;
						}
					}

					// Filter by name
					if (name && name.length) {
						const { first_name, last_name } = claim.employee;
						const employee_name = last_name.concat(` ${first_name}`).toLowerCase();
						if (employee_name.indexOf(name.toLowerCase()) === -1) {
							show = false;
						}
					}

					// Filter by state
					if (state && state.length) {
						if (state !== claim.state) {
							show = false;
						}
					}

					// Filter by type
					if (type && type.length) {
						if (type !== claim.type) {
							show = false;
						}
					}

					// Filter by claim dates
					if (calendarClaimDates) {
						if (calendarClaimDates[0].startDate && calendarClaimDates[0].endDate) {
							const claim_start = moment(claim.start_date);
							const claim_end = moment(claim.end_date);
							const { startDate, endDate } = calendarClaimDates[0];

							// If the claim range is outside the filter search
							if (claim_start.isBefore(startDate) || claim_end.isAfter(endDate)) {
								show = false;
							}
						}
					}

					// Filter by claim creation date
					if (calendarClaimCreationDate) {
						if (
							calendarClaimCreationDate[0].startDate &&
							calendarClaimCreationDate[0].endDate
						) {
							const { created_at } = claim;
							const { startDate, endDate } = calendarClaimCreationDate[0];

							if (!moment(created_at).isBetween(startDate, endDate, 'days', true)) {
								show = false;
							}
						}
					}

					claim.show = show;
					return claim;
				});

				return {
					claims
				};
			},
			() => {
				this.checkVisiblesCount();
			}
		);
	};

	checkVisiblesCount = () => {
		const visiblesCount = this.state.claims.filter((claim) => claim.show).length;
		this.setState({ visiblesCount });
	};

	getTypes = () => {
		return {
			vacation: 'Vacation',
			exam: 'Exam',
			childbirth: 'Childbirth',
			compensation: 'Days off compensation',
			moving: 'Moving',
			marriage: 'Marriage',
			unpaid_leave: 'Unpaid leave',
			other: 'Other (illness, extraordinary reason, etc)',
			parent_death: 'Parents, partner or children death',
			sibling_death: 'Sibling death'
		};
	};

	render() {
		const {
			showClaimForm,
			claim,
			claims,
			showDayOffForm,
			visiblesCount,
			currentVacationDays,
			availableDays,
			showAvailableDays,
			showPostponeDaysInstructions,
			showInadvanceDaysInstructions
		} = this.state;
		const { classes } = this.props;

		return (
			<div className={classes.root}>

				<WithPermission has={'days_claim_create'}>
					<Button
						component="a"
						href="https://ekumen.turecibo.com/e/licenses"
						target="_blank"
						rel="noopener noreferrer"
						color="primary"
						variant="contained"
						className={classes.dayOffActions}
					>
						Claim Days
					</Button>
				</WithPermission>

				<WithPermission has={'days_off_create'}>
					<Button
						className={classes.dayOffActions}
						variant="contained"
						color="secondary"
						onClick={this.openDayOffForm}
					>
						I'd like to work in a non-working day
					</Button>
				</WithPermission>

				<WithPermission has={'days_claim_create'}>
					<Button
						color="primary"
						variant="contained"
						onClick={this.getAvailableDaysOff}
						className={classes.dayOffActions}
					>
						Request a Compensation Day
					</Button>
				</WithPermission>

				<DaysOffCompensationForm
					open={showDayOffForm}
					onSubmit={this.saveDaysOffCompensation}
					onClose={this.onCloseDaysOffCompensationForm}
				/>

				<ClaimsFilter typesOptions={this.getTypes} handleFilter={this.handleFilter} />

				{visiblesCount !== 0 && <ClaimsCount visiblesCount={visiblesCount} />}

				<WithPermission any={['days_claim_view', 'days_claim_view_all']}>
					<Claims
						claims={claims}
						visiblesCount={visiblesCount}
						handleUpdate={this.handleUpdate}
						handleDelete={this.handleDelete}
					/>
				</WithPermission>

				<EmployeeDays
					open={showAvailableDays}
					availableDays={availableDays}
					onClose={this.onCloseDaysOff}
					onOpenPostponeDaysInstructions={this.onOpenPostponeDaysInstructions}
					onOpenInadvanceDaysInstructions={this.onOpenInadvanceDaysInstructions}
				/>

				{currentVacationDays && (
					<PostponeDaysInstructions
						open={showPostponeDaysInstructions}
						currentVacationDays={currentVacationDays}
						requestDaysPostponement={this.requestDaysPostponement}
						onClose={this.onClosePostponeDaysInstructions}
					/>
				)}

				<InadvanceDaysInstructions
					open={showInadvanceDaysInstructions}
					claim={claim}
					requestDaysInadvance={this.requestDaysInadvance}
					onClose={this.onCloseInadvanceDaysInstructions}
				/>

				<DaysClaimForm
					key={claim ? claim._id : new Date()}
					claim={claim}
					open={showClaimForm}
					getTypes={this.getTypes}
					onSubmit={this.onSubmit}
					onClose={this.onCloseDaysClaimForm}
					availableDays={availableDays}
				/>
			</div>
		);
	}
}

export default withStyles(styles)(DaysClaim);
