import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import WithPermission from 'components/WithPermission';
import EmployeeForm from './EmployeeForm';
import axios from 'axios';
import { successMsg, errorMsg } from 'utils/alerts';
import { BASE_URL_EMPLOYEES } from 'utils/base';

const styles = (theme) => ({
	root: {
		margin: 0,
		flexGrow: 1,
		width: '100%',
		padding: theme.spacing(3)
	}
});

const EditEmployee = (props) => {
	const { id } = props.match.params;

	return (
		<WithPermission has={'employees_edit'}>
			<div className={props.classes.root}>
				<h2>Edit employee</h2>
				<EmployeeForm
					employeeId={id}
					closeForm={() => {
						props.history.push('/employees');
					}}
					onSubmit={(employee) => {
						axios
							.put(BASE_URL_EMPLOYEES + id, employee)
							.then((response) => {
								successMsg(response.data.message);
								props.history.push('/employees');
							})
							.catch((err) => {
								console.error(err.response);
								errorMsg(`Error saving: ${err.response.data.message}`);
							});
					}}
				/>
			</div>
		</WithPermission>
	);
};

export default withStyles(styles)(EditEmployee);
