/**
 * Displays all the badges for a user for one Quarter
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// Components
import Badge from './Badge';

const styles = (theme) => {};

function QuarterBadges(props) {
	const { badges = [{}] } = props;
	return (
		<Grid
			container
			style={
				{
					// padding: '5px',
					// border: '0.5px black solid'
				}
			}
		>
			{badges.map((badge) => (
				<Grid item xs>
					<Badge {...badge} />
				</Grid>
			))}
		</Grid>
	);
}

QuarterBadges.propTypes = {
	// end: PropTypes.date,
	// updated_at: PropTypes.date,
	// start: PropTypes.date,
	// created_at: PropTypes.date,
	badges: PropTypes.array // array of badges for this quarter
};

export default withStyles(styles, { withTheme: true })(QuarterBadges);
