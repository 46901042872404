import React from 'react';

import BlankBadgeIcon from './BlankBadgeIcon';

const BadgeIcon = (props) => {
	switch (props.type) {
		default:
			return <BlankBadgeIcon {...props} />;
	}
};

BadgeIcon.displayName = 'BadgeIcon';
BadgeIcon.muiName = 'SvgIcon';

export default BadgeIcon;
