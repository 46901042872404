import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { withData } from 'components/withData';

const styles = {
	card: {
		maxWidth: 345,
		border: '1px solid green'
	},
	media: {
		height: 299
	}
};

class Me extends React.PureComponent {
	render() {
		const { classes, data } = this.props;
		const { image_url, first_name, last_name, summary } = data;

		return (
			<Card className={classes.card}>
				<CardActionArea>
					<CardMedia
						className={classes.media}
						image={image_url}
						title={`${first_name}, ${last_name}`}
					/>
					<CardContent>
						<Typography gutterBottom variant="h5" component="h2">
							{`${first_name}, ${last_name}`}
						</Typography>
						<Typography variant="body2" color="textSecondary" component="p">
							{summary || 'Say something...'}
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Button size="small" color="primary">
						Share
					</Button>
					<Button size="small" color="primary">
						Learn More
					</Button>
				</CardActions>
			</Card>
		);
	}
}

export default withData(withStyles(styles)(Me), '/api/me');
