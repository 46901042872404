import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const styles = (theme) => ({
	root: {
		maxWidth: '350px',
		border: `1px solid ${theme.palette.secondary.dark}`,
		background: theme.palette.secondary.main,
		color: '#FFFFFF',
		padding: theme.spacing(1),
		top: theme.spacing(-2),
		left: theme.spacing(2),
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			top: -1,
			left: 0,
			maxWidth: '100%'
		}
	}
});

/* Renders the header of a Section */
class SectionHeader extends React.PureComponent {
	render() {
		const { classes } = this.props;
		return (
			<Paper elevation={2} className={classes.root}>
				{this.props.children}
			</Paper>
		);
	}
}

export default withStyles(styles)(SectionHeader);
