import swal from 'sweetalert';

export const successMsg = (msg, timer = 2000) => {
	swal({
		title: 'Done!',
		text: !!msg ? `${msg}` : 'Successful Operation',
		icon: 'success',
		timer,
		button: false
	});
};

export const errorMsg = (error) => {
	swal({
		title: 'Oops',
		text: !!error ? `${error}` : 'An error has occurred',
		icon: 'error',
		dangerMode: true
	});
};

export const assureAction = (question, action) => {
	swal({
		title: 'Are you sure?',
		text: question,
		icon: 'warning',
		dangerMode: true
	}).then((confirmed) => {
		if (confirmed) {
			action();
		}
	});
};
