import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    alert: {
        marginBottom: '25px'
    }
});

const NoManagerAlert = (props) => {
    const { classes } = props;

    return (

        <div className={classes.alert}>
            <Alert severity="info">
            Info: An Employee who is a manager and has no manager assigned get their days claim requests approved automatically.
            </Alert>
        </div>
    );
};

export default withStyles(styles)(NoManagerAlert);