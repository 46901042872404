import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { withData } from 'components/withData';

const styles = {
	widget: {
		width: '100%'
	}
};

/**
 * This widget displays recent news and their details. It is used in the home
 * dashboard.
 */

class LinksWidget extends React.PureComponent {
	render() {
		const { classes } = this.props;

		return (
			<ul className={classes.widget}>
				<li key="linktree"><a href="https://linktr.ee/EkumenInternal" >Linktree with all the links</a></li>
				<li key="handbook"><a href="https://handbook.ekumenlabs.com/" >Handbook</a></li>
				<li key="eku-do"><a href="https://eku-do.ekumenlabs.com/" >Eku-do</a></li>
			</ul>
		);
	}
}

LinksWidget.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withData(withStyles(styles)(LinksWidget), '/api/news/general');
