import React from 'react';
import PropTypes from 'prop-types';

import { TitleContext } from 'AppContexts';

/* Helper component used to set the title in the TitleContext. Particularly
 * useful when using pure functions, and want to avoid calling a method inside
 * it which would change the state, making it impure.
 */

export default class WithTitle extends React.PureComponent {
	static contextType = TitleContext;

	componentDidMount() {
		this.context.setTitle(this.props.title, this.props.experimental);
	}

	render() {
		return <React.Fragment>{this.props.children}</React.Fragment>;
	}
}

WithTitle.propTypes = {
	title: PropTypes.string.isRequired
};
