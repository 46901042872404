import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import PadawanJourney from './PadawanJourney';

const styles = (theme) => ({
	root: {
		flex: 1,
		margin: theme.spacing(1)
	}
});

class PadawanJourneysGroup extends React.PureComponent {
	render() {
		const { classes, journeys, reloadData } = this.props;
		return (
			<Table className={classes.root}>
				<TableHead>
					<TableRow>
						<TableCell width={'20%'}>Journey</TableCell>
						<TableCell width={'15%'}>Status</TableCell>
						<TableCell width={'15%'}>Started</TableCell>
						<TableCell width={'15%'}>Finished</TableCell>
						<TableCell width={'15%'}>Yoda</TableCell>
						<TableCell width={'20%'}>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{journeys.map((journey) => (
						<PadawanJourney key={journey._id} {...journey} reloadData={reloadData} />
					))}
				</TableBody>
			</Table>
		);
	}
}

export default withStyles(styles)(PadawanJourneysGroup);
