import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import StyledTableCell from 'components/ui/table/StyledTableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Permission } from './Permission';

const styles = (theme) => ({
	root: {
		flexGrow: 1
	}
});

class RolesPermissions extends React.PureComponent {
	render() {
		const {
			classes,
			addPermission,
			removePermission,
			rolePermissions,
			staticPermissions
		} = this.props;

		// Updates the status of the list of permissions. Sets status=true if the role (rolePermissions) contains that permission.
		const permissionsList = staticPermissions.map((element) => {
			element.status = rolePermissions.includes(element._id.toString());
			return element;
		});

		return (
			<div className={classes.root}>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableCell>Name</StyledTableCell>
							<StyledTableCell>Description</StyledTableCell>
							<StyledTableCell>Module</StyledTableCell>
							<StyledTableCell>Action</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{permissionsList.map((permission) => (
							<Permission
								key={permission._id}
								permission={permission}
								addPermission={addPermission}
								removePermission={removePermission}
							/>
						))}
					</TableBody>
				</Table>
			</div>
		);
	}
}

export default withStyles(styles)(RolesPermissions);
