import axios from 'axios';
import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import { BASE_URL_PAYSLIPS } from 'utils/base';
import { errorMsg } from 'utils/alerts';


const styles = {
	root: {
		flex: 1
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    table: {
        width: '80%',
        height: '100px'
    },
};

class VerifyPayslip extends React.PureComponent {

    state = {
        payslip: null
    }

    componentDidMount() {
        axios
            .get(`${BASE_URL_PAYSLIPS}/unverified`)
            .then((response) => {
                this.setState({ 
                    payslip: response.data
                });
                console.log(response.data)
            })
            .catch((err) => {
                console.error(err.response);
                errorMsg(`Error getting payslips: ${err.response.data.message}`);
            });
    }
    
    VerifyPayslip = () => {
        const { payslip } = this.state;   
        axios
        .put(`${BASE_URL_PAYSLIPS}verify/${payslip._id}`)
        .then((response) => {
            this.setState({ 
                payslip: response.data
            });
            console.log(response.data)
        })
        .catch((err) => {
            console.error(err.response);
            errorMsg(`Error getting payslips: ${err.response.data.message}`);
        });     
    }

	render() {
        const { classes } = this.props;
        const { payslip } = this.state;

        const options = {
            url: `${BASE_URL_PAYSLIPS}pdf/${payslip && payslip.file}`,
            httpHeaders: { 'Authorization': `${axios.defaults.headers.common.Authorization}`  },
            withCredentials: true 
        }

        return (
            <>
                <br/>
                {payslip && payslip.file && 
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Grid item xs={12}>
                                cuil: {payslip && payslip.cuil} 
                            </Grid>
                            <Grid item xs={12}>
                                month: {payslip && payslip.month} 
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={this.VerifyPayslip} >Confirm</Button>
                                
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className={classes.paper}>
                                        {payslip && <Document
                                            file={options}
                                        >
                                            <Page pageNumber={1} />
                                        </Document>}
                                        {/*<p>Page {pageNumber} of {numPages}</p>*/}
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            
                        </Grid>
                    </Grid>
                }
            </>
        );
    }
}

export default withStyles(styles)(VerifyPayslip);
