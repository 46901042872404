import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import SectionHeader from 'components/ui/SectionHeader';

const styles = (theme) => ({
	root: {
		border: `1px solid ${theme.palette.primary.main}`,
		margin: theme.spacing(3, 1, 1, 1),
		[theme.breakpoints.down('sm')]: {
			border: 0,
			margin: 0
		}
	},
	innerContainer: {
		margin: 0,
		padding: theme.spacing(0, 1, 1, 1)
	}
});

/* Renders a 'Section' which is a box container with a header */
class Section extends React.PureComponent {
	render() {
		const { classes, title } = this.props;
		return (
			<Paper className={classes.root}>
				<SectionHeader>{title}</SectionHeader>
				<div className={classes.innerContainer}>{this.props.children}</div>
			</Paper>
		);
	}
}

Section.propTypes = {
	classes: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired
};

export default withStyles(styles)(Section);
