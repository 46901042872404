import React from 'react';
import { Route } from 'react-router-dom';

import Home from './Home';
import WithTitle from 'components/WithTitle';

export default ({ match }) => {
	return (
		<WithTitle title="">
			<Route path={match.path} exact component={Home} />
		</WithTitle>
	);
};
