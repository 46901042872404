import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { withData } from 'components/withData';

const styles = {
	root: {
		flex: 1
	}
};

class TeamBuilderHome extends React.PureComponent {
	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<h1>The team builder is under construction.</h1>
			</div>
		);
	}
}

export default withData(withStyles(styles)(TeamBuilderHome), '/api/team_builder');
