import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import ClaimState from './ClaimState';
import ClaimedDays from './ClaimedDays';
import ClaimTimespan from './ClaimTimespan';
import ClaimActions from './ClaimActions';
import ClaimDescription from './ClaimDescription';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import { UserContext } from 'AppContexts';
import LinkIcon from '@material-ui/icons/Link';
import { copyToClipboard } from 'utils';
import permissions from 'permissions';
import NoManagerAlert from './NoManagerAlert';

const useStyles = makeStyles((theme) => ({
	card: {
		borderRadius: 12,
		minWidth: 343,
		maxWidth: 343,
		textAlign: 'center',
		margin: '8px',
		display: 'inline-table'
	},
	linkIt: {
		cursor: 'pointer',
		position: 'relative',
		right: '-132px'
	},
	details: {
		margin: 0,
		flexGrow: 1
	},
	avatar: {
		width: 60,
		height: 60,
		margin: 'auto'
	},
	heading: {
		fontSize: 18,
		fontWeight: 'bold',
		letterSpacing: '0.5px',
		marginTop: 8,
		marginBottom: 0
	},
	header: {
		margin: '15px'
	}
}));

const collapseStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},
	details: {
		padding: '0'
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular
	},
	toggleIcon: {
		'background-color': 'white',
		'border-radius': '10px',
		color: theme.palette.secondary.light
	}
}));

const Claim = (props) => {
	const { claim, handleDelete, handleUpdate } = props;
	const { _id, show, employee } = claim;
	const { first_name, last_name, image_url, pronoun, location_id } = employee;
	const styles = useStyles();
	const collapse = collapseStyles();
	const shadowStyles = useFadedShadowStyles();

	return (
		<div>
			{show && (
				<Grow in={show}>
					<div>
						<Card className={cx(styles.card, shadowStyles.root)}>
							<CardContent>
								<LinkIcon
									onClick={() => {
										copyToClipboard(
											window.location.host + window.location.pathname + '?_id=' + _id
										);
									}}
									className={styles.linkIt}
									titleAccess="Copy link"
								/>

								<div className={styles.header}>
									<Avatar className={styles.avatar} src={image_url} />
									<h3 className={styles.heading}>{`${last_name} ${first_name} ${
										pronoun ? `(${pronoun})` : ''
									}`}</h3>
									{location_id && <label>{location_id}</label>}
								</div>

								<ClaimState claim={claim} />
							</CardContent>

							<Divider light />

							<ClaimTimespan claim={claim} />

							<Divider light />

							<UserContext.Consumer>
								{(user) => (
									<div>
										{
											// Details only visible for managers or
											(user.employee_id === employee.manager_id ||
												// Details only visible for claim owner or
												user.employee_id === employee._id ||
												// Details only visible for who has permission
												permissions.any(user, ['days_claim_view', 'days_claim_view_all'])) && (
												<div className={collapse.root}>
													<Accordion>
														<AccordionSummary
															expandIcon={<ExpandMoreIcon className={collapse.toggleIcon} />}
															aria-controls={`panel${_id}a-content`}
															id={`panel${_id}a-header`}
															className={collapse.toggle}
														>
															<Typography className={collapse.heading}>Details</Typography>
														</AccordionSummary>

														<AccordionDetails className={collapse.details}>
															<div className={styles.details}>
																{/* If the employee of the card is manager and doesn't have a manager assigned,
															remind he/she that the request was automatically approved. */}
																{employee.is_manager && !employee.manager_id && <NoManagerAlert />}
																<ClaimedDays claim={claim} />
																<ClaimDescription claim={claim} />
																<ClaimActions
																	isOwner={user.employee_id === employee._id}
																	isManager={user.employee_id === employee.manager_id}
																	claim={claim}
																	handleUpdate={handleUpdate}
																	handleDelete={handleDelete}
																/>
															</div>
														</AccordionDetails>
													</Accordion>
												</div>
											)
										}
									</div>
								)}
							</UserContext.Consumer>
						</Card>
					</div>
				</Grow>
			)}
		</div>
	);
};

export default Claim;
