import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BlankBadgeIcon = (props) => (
	<SvgIcon {...props} viewBox={'0 0 419.226 419.226'}>
		<path
			d="M209.741,419.226l-1.152-0.197c-2.029-0.352-202.683-37.52-202.683-240.823
			c0-59.806,18.646-117.159,52.514-161.481l2.787-3.652l4.242,1.766c18.545,7.709,38.188,11.623,58.386,11.623
			c29.369,0,57.908-8.425,82.534-24.357L209.616,0l3.24,2.1c24.625,15.932,53.165,24.357,82.534,24.357
			c20.192,0,39.835-3.908,58.38-11.623l4.242-1.766l2.793,3.652c33.862,44.322,52.514,101.675,52.514,161.481
			c0,194.245-200.403,240.328-202.426,240.775L209.741,419.226z M65.2,27.579c-30.574,41.744-47.359,95.034-47.359,150.622
			c0,187.336,173.958,225.47,191.608,228.853c15.83-3.926,191.93-51.715,191.93-228.853c0-55.587-16.785-108.883-47.365-150.622
			c-18.724,7.178-38.421,10.812-58.63,10.812c-30.395,0-59.973-8.366-85.774-24.231c-25.807,15.866-55.378,24.231-85.774,24.231
			C103.627,38.391,83.93,34.757,65.2,27.579z"
		/>
	</SvgIcon>
);
BlankBadgeIcon.displayName = 'BlankBadge';
BlankBadgeIcon.muiName = 'SvgIcon';

export default BlankBadgeIcon;
