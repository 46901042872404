import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import StarIcon from '@material-ui/icons/Star';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import StyledTableCell from 'components/ui/table/StyledTableCell';
import StyledTableRow from 'components/ui/table/StyledTableRow';
import axios from 'axios';
import { withData } from 'components/withData';
import { BASE_URL_PADAWAN_TOC } from 'utils/base';

const handleCreate = (toc) => {
	axios.post(BASE_URL_PADAWAN_TOC, toc);
};

const handleUpdate = (toc) => {
	axios.put(BASE_URL_PADAWAN_TOC, toc);
};

class Row extends React.PureComponent {
	handleDelete = () => {
		axios
			.delete(BASE_URL_PADAWAN_TOC + this.props.row._id)
			.then((response) => this.props.reloadData());
	};

	handleUpdate = () => {
		this.props.tweakModal(this.props.row, true, 'Update');
	};

	handleView = () => {
		this.props.tweakModal(this.props.row, false, 'Ok');
	};

	renderStars = (numb) => {
		let ret = [];
		for (let i = 0; i < 4; i++) {
			ret.push(
				i < numb ? <StarIcon key={i} color={'secondary'} /> : <StarIcon key={i} />
			);
		}
		return ret;
	};

	render() {
		const { row } = this.props;
		return (
			<StyledTableRow key={row._id}>
				<StyledTableCell component="th" scope="row">
					{row.name}
				</StyledTableCell>
				<StyledTableCell component="th" scope="row">
					{this.renderStars(row.level)}
				</StyledTableCell>
				<StyledTableCell>
					<IconButton onClick={this.handleDelete} title="Delete">
						<RemoveCircleIcon />
					</IconButton>
					<IconButton onClick={this.handleUpdate} title="Update">
						<EditIcon />
					</IconButton>
					<IconButton onClick={this.handleView} title="View Details">
						<VisibilityIcon />
					</IconButton>
				</StyledTableCell>
			</StyledTableRow>
		);
	}
}

const styles = {
	root: {
		flex: 1
	},
	fab: {
		position: 'absolute',
		bottom: '20px',
		right: '20px'
	}
};

class TocsHome extends React.PureComponent {
	state = {
		modal: false,
		modalTocName: '',
		modalTocLevel: 1,
		modalTocContent: '',
		modalOk: 'Add',
		modalEditable: true
	};

	onSubmit = () => {
		let row = {};
		row._id = this.state.objId;
		row.name = this.state.modalTocName;
		row.level = this.state.modalTocLevel;
		row.content = this.state.modalTocContent;
		if (this.state.modalOk === 'Add') handleCreate(row);
		else if (this.state.modalOk === 'Update') handleUpdate(row);
		this.props.reloadData();
		this.setState({ modal: !this.state.modal });
	};

	tweakModal = (obj, edit, mode) => {
		this.setState({
			modalTocName: obj.name,
			modalTocLevel: obj.level,
			modalTocContent: obj.content,
			modalEditable: edit,
			modalOk: mode,
			modal: !this.state.modal,
			objId: obj._id
		});
	};

	tocNameChange = (ev) => this.setState({ modalTocName: ev.target.value });
	tocLevelChange = (ev) => this.setState({ modalTocLevel: Number(ev.target.value) });
	tocContentChange = (ev) => this.setState({ modalTocContent: ev.target.value });
	toggleTocsModal = () => {
		this.setState({ modal: !this.state.modal, modalOk: 'Add', modalEditable: true });
		this.props.reloadData();
		this.setState({ modalTocName: '', modalTocLevel: '', modalTocContent: '' });
	};
	render() {
		const { data, classes } = this.props;
		return (
			<div className={classes.root}>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableCell>Name</StyledTableCell>
							<StyledTableCell>Level</StyledTableCell>
							<StyledTableCell>
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									Actions
								</div>
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row) => (
							<Row
								key={row._id}
								row={row}
								reloadData={this.props.reloadData}
								tweakModal={this.tweakModal}
							/>
						))}
					</TableBody>
				</Table>
				<Fab
					color="primary"
					aria-label="add"
					onClick={this.toggleTocsModal}
					className={classes.fab}
				>
					<AddIcon />
				</Fab>
				<Dialog
					open={this.state.modal}
					onClose={this.toggleTocsModal}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{'TOC Editor'}</DialogTitle>
					<DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
						<TextField
							label="Skill"
							defaultValue={this.state.modalTocName}
							onChange={this.tocNameChange}
							disabled={!this.state.modalEditable}
						/>
						<TextField
							label="Level"
							defaultValue={String(this.state.modalTocLevel)}
							onChange={this.tocLevelChange}
							disabled={!this.state.modalEditable}
						/>
						<TextField
							label="TOC Specs"
							defaultValue={this.state.modalTocContent}
							multiline={true}
							onChange={this.tocContentChange}
							disabled={!this.state.modalEditable}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.toggleTocsModal} color="primary">
							Cancel
						</Button>
						<Button onClick={this.onSubmit} color="primary" autoFocus>
							{this.state.modalOk}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default withData(withStyles(styles)(TocsHome), '/api/padawan/toc');

// '/api/padawan/toc/:id' -> _id ; name -> string ; level -> number; content -> [string]
// '/api/padawan/tocs -> list of bojects with 'name', 'level' and 'id'.
