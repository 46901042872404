import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

const styles = {
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '2px',
		maxWidth: '150px'
	}
};

/**
 * Displays a date with elapase time as a hover
 */
class Date extends React.PureComponent {
	render() {
		const { classes, value } = this.props;
		const date = moment(value);
		return (
			<span className={classes.root} title={date.format('MMMM Do YYYY')}>
				{date.fromNow()}
			</span>
		);
	}
}

Date.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Date);
