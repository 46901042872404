import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import StyledTableCell from 'components/ui/table/StyledTableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Role } from './Role';

const styles = (theme) => ({
	root: {
		flexGrow: 1
	}
});

const Roles = (props) => {
	const { roles, classes, onDelete, onModify, disabledActions } = props;

	return (
		<div className={classes.root}>
			<Table>
				<TableHead>
					<TableRow>
						<StyledTableCell>Name</StyledTableCell>
						<StyledTableCell>Description</StyledTableCell>
						<StyledTableCell>Action</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{roles.map((role) => (
						<Role
							key={role._id}
							role={role}
							onDelete={onDelete}
							onModify={(role) => {
								onModify(role);
							}}
							disabledActions={disabledActions}
						/>
					))}
				</TableBody>
			</Table>
		</div>
	);
};

export default withStyles(styles)(Roles);
