/**
 * Displays badges for the whole company
 */
import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { BASE_URL_BADGES, BASE_URL_USERS } from 'utils/base';

class BadgeHistory extends Component {
	state = {
		badges: [
			{
				// sample badge
				id: 123,
				name: 'String',
				description: 'desc',
				created_at: Date.now(),
				updated_at: Date.now(),
				image: 'image?',
				properties: 'properties',
				badge_type: 'core'
			}
		],
		quarters: [
			{
				// sample quarter
				id: Number,
				end: Date,
				updated_at: Date,
				start: Date,
				created_at: Date
			}
		]
	};

	componentDidMount() {
		// TODO(german-e-mas): User an interceptor to attach the JWT (if any)
		const options = {
			params: {}
		};

		const token = localStorage.getItem('token');

		if (token) {
			options.headers = {
				Authorization: `Bearer ${token}`
			};
		}

		axios
			.get(BASE_URL_USERS, options)
			.then((response) => console.log(response))
			.catch((error) => console.error(error));

		axios
			.get(BASE_URL_BADGES, options)
			.then((response) => console.log(response))
			.catch((error) => console.error(error));
	}

	render() {
		return (
			<table>
				<tbody>
					<tr>
						<th />
					</tr>
				</tbody>
			</table>
		);
	}
}

BadgeHistory.propTypes = {
	// passed by the router
	userId: PropTypes.number
};

export default BadgeHistory;
