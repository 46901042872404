import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center'
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	},
	buttonProgress: {
		color: theme.palette.primary.main,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	}
}));

export default function StatefullIconButton(props) {
	const classes = useStyles();
	const [loading, setLoading] = React.useState(false);

	const onClick = () => {
		setLoading(true);
		props.onClick((err, res) => {
			setLoading(false);
		});
	};

	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<IconButton {...props} onClick={onClick} disabled={props.loading || loading} />
				{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
			</div>
		</div>
	);
}
