import React from 'react';
import Claim from './Claim';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
	root: {
		'flex-wrap': 'wrap',
		display: 'flex'
	},
	noResults: {
		padding: '0 0 0 10px',
		color: theme.palette.grayScale.lighter
	}
});

const Claims = (props) => {
	const { classes, claims, handleUpdate, handleDelete, visiblesCount } = props;

	return (
		<div className={classes.root}>
			{visiblesCount === 0 ? (
				<h2 className={classes.noResults}>No results found</h2>
			) : (
				claims.map((claim) => (
					claim.employee && (
						<Claim
							handleUpdate={handleUpdate}
							handleDelete={handleDelete}
							key={claim._id}
							claim={claim}
						/>
					)
				))
			)}
		</div>
	);
};

export default withStyles(styles)(Claims);
