import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
	title: {
		margin: '5px 0 7px 0'
	},
	description: {
		margin: '0 10px 0 10px',
		padding: '0 10px 0 10px'
	}
});

const ClaimDescription = (props) => {
	const { classes, claim } = props;
	const { request_description, response_description, _id } = claim;

	return (
		<div>
			{request_description && (
				<div>
					<h4 className={classes.title}>Claim request description</h4>
					<TextField
						className={classes.description}
						value={request_description}
						key={`request_description${_id}`}
						id="standard-multiline-static"
						multiline
						rows="6"
						InputProps={{
							autoComplete: 'off'
						}}
						disabled
						readOnly
						fullWidth
					/>
				</div>
			)}

			{response_description && (
				<div>
					<h4 className={classes.title}>Claim response description</h4>
					<TextField
						className={classes.description}
						value={response_description}
						key={`response_description${_id}`}
						id="standard-multiline-static"
						multiline
						rows="6"
						InputProps={{
							autoComplete: 'off'
						}}
						disabled
						readOnly
						fullWidth
					/>
				</div>
			)}
		</div>
	);
};

export default withStyles(styles)(ClaimDescription);
