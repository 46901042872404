import React from 'react';
import { Route } from 'react-router-dom';

import RolesHome from './RolesHome';

export default ({ match }) => {
	return (
		<>
			<Route path={match.path} exact component={RolesHome} />
		</>
	);
};
