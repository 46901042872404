/**
 * Controls the routing within the app (front end)
 * when the url matches, it renders the passed component
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
	mainContent: {
		display: 'flex',
		alignItems: 'top',
		justifyContent: 'center',
		flexDirection: 'column',
		height: '100%'
	},
	paper: {
		minWidth: '400px',
		maxWidth: '650px',
		width: '50vw',
		margin: 'auto',
		border: `1px solid ${theme.palette.secondary.dark}`,
		padding: theme.spacing(2)
	},
	title: {
		color: theme.palette.primary.main,
		fontWeight: 'bold'
	}
});

class AppPageNotFound extends React.PureComponent {
	render() {
		const { classes, location } = this.props;

		return (
			<div className={classes.mainContent}>
				<Paper className={classes.paper}>
					<Typography
						variant="h2"
						component="h2"
						gutterBottom
						align="center"
						className={classes.title}
					>
						404 - {location.pathname}
					</Typography>
				</Paper>
			</div>
		);
	}
}

AppPageNotFound.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired
};

export default withStyles(styles)(AppPageNotFound);
