import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { UserContext } from 'AppContexts';
import Users from './Users';
import { BASE_URL_USERS } from 'utils/base';

const styles = (theme) => ({
	root: {
		flexGrow: 1
	}
});

class UsersHome extends React.PureComponent {
	static contextType = UserContext;

	state = {
		users: []
	};

	componentDidMount() {
		// Get all users
		axios
			.get(BASE_URL_USERS)
			.then((response) => {
				this.setState({ users: response.data });
			})
			.catch();
	}

	handleEdit = (id) => {
		this.props.history.push(`${this.props.match.path}/edit/${id}`);
	};

	render() {
		const { classes } = this.props;
		const { users } = this.state;

		return (
			<div className={classes.root}>
				<Users users={users} user={this.context} handleEdit={this.handleEdit} />
			</div>
		);
	}
}

export default withStyles(styles)(UsersHome);
