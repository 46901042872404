import React from 'react';
import { Route } from 'react-router-dom';

import BadgesHome from './BadgesHome';
import BadgeHistory from './BadgeHistory';
import BadgesClaim from './BadgesClaim';
import WithTitle from 'components/WithTitle';

export default ({ match, title, experimental}) => {
	return (
		<WithTitle title={title} experimental={experimental}>
			<Route exact path={`${match.path}`} component={BadgesHome} />
			<Route exact path={`${match.path}/:userId`} component={BadgeHistory} />
			<Route exact path={`${match.path}/:quarter/claim`} component={BadgesClaim} />
		</WithTitle>
	);
};
