import React from 'react';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import Date from 'components/ui/Date';
import IconButton from 'components/ui/IconButton';
import StyledTableCell from 'components/ui/table/StyledTableCell';
import StyledTableRow from 'components/ui/table/StyledTableRow';
import axios from 'axios';
import { BASE_URL_PADAWAN_JOURNEYS } from 'utils/base';

class PadawanJourney extends React.PureComponent {
	deleteJourney = (cb) => {
		const { _id, reloadData } = this.props;
		axios
			.delete(BASE_URL_PADAWAN_JOURNEYS + _id)
			.then(() => {
				reloadData(cb);
			})
			.catch((err) => {
				cb(err);
			});
	};

	cancelJourney = (cb) => {
		const { _id, reloadData } = this.props;
		axios
			.post(`${BASE_URL_PADAWAN_JOURNEYS + _id}/cancel`)
			.then(() => {
				reloadData(cb);
			})
			.catch((err) => {
				console.log('Error cancelling journey', err);
				cb(err);
			});
	};

	renderYoda() {
		const { yoda } = this.props;

		if (!yoda) {
			return null;
		}
		return <a href={`/employees/${yoda._id}`}>{yoda.name}</a>;
	}

	renderActions() {
		const { state } = this.props;

		switch (state) {
			case 'complete':
			case 'canceled':
				return (
					<IconButton onClick={this.deleteJourney} title="Delete journey" size="small">
						<DeleteIcon />
					</IconButton>
				);
			case 'not_started':
			case 'waiting_yoda':
			case 'in_progress':
				return (
					<IconButton onClick={this.cancelJourney} title="Cancel journey" size="small">
						<HighlightOffRoundedIcon />
					</IconButton>
				);
			default:
				return null;
		}
	}

	render() {
		const { name, state, started, finished } = this.props;

		return (
			<StyledTableRow>
				<StyledTableCell>{name}</StyledTableCell>
				<StyledTableCell>{state}</StyledTableCell>
				<StyledTableCell>
					<Date value={started} />
				</StyledTableCell>
				<StyledTableCell>
					<Date value={finished} />
				</StyledTableCell>
				<StyledTableCell>{this.renderYoda()}</StyledTableCell>
				<StyledTableCell>{this.renderActions()}</StyledTableCell>
			</StyledTableRow>
		);
	}
}

export default PadawanJourney;
