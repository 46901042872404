import React from 'react';
import MaterialTable from '@material-table/core';
import permissions from 'permissions';

const DaysOffList = (props) => {
	const { daysOff, handleEdit, handleCreate, handleDelete, user } = props;
	const allowEdit = !permissions.has(user, 'days_off_edit_all');
	const allowCreate = !permissions.has(user, 'days_off_create_all');
	const allowDelete = !permissions.has(user, 'days_off_delete_all');

	return (
		<MaterialTable
			title="Days off list"
			data={daysOff}
			options={{
				search: false,
				pageSize: 20,
				actionsColumnIndex: -1,
				paging: true,
				filtering: true
			}}
			actions={[
				{
					icon: 'edit',
					tooltip: 'Edit',
					disabled: allowEdit,
					onClick: (event, rowData) => handleEdit(rowData._id)
				},
				{
					icon: 'add',
					tooltip: 'Create',
					disabled: allowCreate,
					isFreeAction: true,
					onClick: () => handleCreate()
				},
				{
					icon: 'delete',
					tooltip: 'Delete',
					disabled: allowDelete,
					onClick: (event, rowData) => handleDelete(rowData._id)
				}
			]}
			columns={[
				{ title: 'Employee last name', field: 'employee.last_name' },
				{ title: 'Employee first name', field: 'employee.first_name' },
				{ title: 'Type', field: 'type' },
				{ title: 'Amount', field: 'amount', type: 'numeric' },
				{ title: 'Description', field: 'description' },
				{ title: 'Active', field: 'active', type: 'boolean' },
				{ title: 'Eventual', field: 'event_binded', type: 'boolean' }
			]}
		/>
	);
};

export default DaysOffList;
