import React from 'react';
import { Route } from 'react-router-dom';

import ModulesHome from './ModulesHome';

export default ({ match }) => {
	return (
		<React.Fragment>
			<Route path={match.path} exact component={ModulesHome} />
		</React.Fragment>
	);
};
