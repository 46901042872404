import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import EmployeePage from './EmployeePage';
import { EmployeeContext } from 'AppContexts';
import WithTitle from 'components/WithTitle';

//Redirects to the viewing employee's page, or to our CEO
//TODO: It would be nice to have a view of the whole family together instead
//of going to the CEO
const RedirectToSlug = ({ match }) => (
	<EmployeeContext.Consumer>
		{(employee) => {
			const slug = (employee && employee.slug) || 'pato'; //Hack :-)
			return <Redirect to={`${match.path}/${slug}`} />;
		}}
	</EmployeeContext.Consumer>
);

export default ({ match, title, experimental}) => {
	return (
		<WithTitle title={title} experimental={experimental}>
			<Route path={match.path} exact component={RedirectToSlug} />
			<Route path={`${match.path}/:slug`} exact component={EmployeePage} />
		</WithTitle>
	);
};
