import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import WithPermission from 'components/WithPermission';

const styles = (theme) => ({
	actionButton: {
		cursor: 'pointer',
		margin: '5px 0 5px 0',
		'vertical-align': 'top',
		background: 'white',
		color: theme.palette.grayScale.lighter
	},
	actions: {
		display: 'flex',
		'justify-content': 'space-evenly',
		'margin-top': '20px',
		'border-top': `1px solid ${theme.palette.grayScale.lighter}`
	}
});

const ClaimActions = (props) => {
	const { isOwner, isManager, classes, claim, handleDelete, handleUpdate } = props;
	const { state } = claim;
	// User can delete if he is the claim owner and was not reviewed yet, or was approved or scheduled
	const canDelete = ['on_review', 'approved', 'scheduled'].includes(state) && isOwner;
	// User can edit if he is the claim owner and was not reviewed yet
	// User can edit if he is the claim employee manager (or sustitute) and was not reviewed yet
	const canEdit = state === 'on_review' && (isOwner || isManager);
	return (
		<div className={classes.actions}>
			{canDelete && (
				<WithPermission has={'days_claim_delete'}>
					<DeleteIcon
						className={classes.actionButton}
						onClick={(e) => {
							handleDelete(claim);
						}}
					/>
				</WithPermission>
			)}
			{canEdit && (
				<WithPermission has={'days_claim_edit'}>
					<EditIcon
						className={classes.actionButton}
						onClick={(e) => {
							handleUpdate(claim);
						}}
					/>
				</WithPermission>
			)}
		</div>
	);
};

export default withStyles(styles)(ClaimActions);
