import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import TocNameSelector from './TocNameSelector';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions);

export default class CustomizedDialogs extends React.Component {
	state = {};

	handleSubmit = () => {
		const { name, level, _id } = this.state;
		const tocId = _id;
		const journeyName = name + ' - L' + level;
		this.props.onSubmit(tocId, journeyName);
	};

	saveToc = (valueObj) => {
		this.setState(valueObj);
	};

	render() {
		const { open, onClose } = this.props;
		const { name, level, _id } = this.state;

		return (
			<div>
				<Dialog onClose={onClose} aria-labelledby="dialog-title" open={open}>
					<DialogTitle id="dialog-title" onClose={onClose}>
						Start new padawan journey
					</DialogTitle>
					<DialogContent dividers>
						<TocNameSelector
							saveTocCallback={this.saveToc}
							name={name}
							level={level}
							_id={_id}
						/>
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.handleSubmit} color="primary">
							Save changes
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
