import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import WithPermission from 'components/WithPermission';
import { UserContext } from 'AppContexts';
import { successMsg, errorMsg, assureAction } from 'utils/alerts';
import DaysOffList from './DaysOffList';
import { BASE_URL_DAYS_OFF } from 'utils/base';

const styles = (theme) => ({
	root: {
		flexGrow: 1
	}
});

/**
 * Employee home page
 */
export class DaysOffHome extends React.PureComponent {
	static contextType = UserContext;

	state = {
		daysOff: []
	};

	componentDidMount() {
		axios
			.get(BASE_URL_DAYS_OFF)
			.then((response) => {
				this.setState({ daysOff: response.data });
			})
			.catch((err) => {
				console.error(err.response);
				errorMsg(`Error getting days off: ${err.response.data.message}`);
			});
	}

	handleEdit = (id) => {
		this.props.history.push(`${this.props.match.path}/edit/${id}`);
	};

	handleCreate = () => {
		this.props.history.push(`${this.props.match.path}/create`);
	};

	handleDelete = (id) => {
		assureAction('Do you really want to delete it?', () => {
			axios
				.delete(BASE_URL_DAYS_OFF + id)
				.then((response) => {
					successMsg(response.data.message);
					this.setState((prevState) => ({
						daysOff: prevState.daysOff.filter((e) => e._id !== id)
					}));
				})
				.catch((err) => {
					console.error(err.response);
					errorMsg(`Error deleting: ${err.response.data.message}`);
				});
		});
	};

	render() {
		const { classes } = this.props;
		const { daysOff } = this.state;

		return (
			<WithPermission has={'days_off_view_all'}>
				<div className={classes.root}>
					<DaysOffList
						user={this.context}
						daysOff={daysOff}
						handleCreate={this.handleCreate}
						handleEdit={this.handleEdit}
						handleDelete={this.handleDelete}
					/>
				</div>
			</WithPermission>
		);
	}
}

export default withStyles(styles)(DaysOffHome);
