import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Employees from './Employees';
import WithPermission from 'components/WithPermission';
import { UserContext } from 'AppContexts';
import { successMsg, errorMsg, assureAction } from 'utils/alerts';
import { BASE_URL_EMPLOYEES, MANAGER_EMPLOYEES } from 'utils/base';
import DeleteEmployee from './DeleteEmployee';

const styles = (theme) => ({
	root: {
		flexGrow: 1
	}
});

/**
 * Employee home page
 */
export class EmployeesHome extends React.PureComponent {
	static contextType = UserContext;

	state = {
		employees: [],
		managers: [],
		deleteEmployeeId: null,
		openDeletePanel: false,
	};

	componentDidMount() {
		axios
			.get(BASE_URL_EMPLOYEES)
			.then((response) => {
				this.setState({ employees: response.data });
			})
			.catch((err) => {
				console.error(err.response);
				errorMsg(`Error getting employees: ${err.response.data.message}`);
			});

		//GET MANAGERS LIST
		axios
			.get(MANAGER_EMPLOYEES)
			.then((response) => {
				this.setState({ managers: response.data });
			})
			.catch((err) => {
				console.error(err.response);
				errorMsg(`Error getting managers: ${err.response.data.message}`);
			});
	}

	handleEdit = (id) => {
		this.props.history.push(`${this.props.match.path}/edit/${id}`);
	};

	handleCreate = () => {
		this.props.history.push(`${this.props.match.path}/create`);
	};

	handleDelete = (id) => {
		this.setState({ deleteEmployeeId: id, openDeletePanel: true });
	};

	handleCloseDeletePanel = () => {
		this.setState({ openDeletePanel: false, deleteEmployeeId: null });
	};

	handleConfirmDelete = (id) => {
		assureAction('Do you really want to delete it?', () => {
			axios
				.delete(BASE_URL_EMPLOYEES + id)
				.then((response) => {
					successMsg(response.data.message);
					this.setState((prevState) => ({
						employees: prevState.employees.filter((e) => e._id !== id)
					}));
				})
				.catch((err) => {
					console.error(err.response);
					errorMsg(`Error deleting: ${err.response.data.message}`);
				});
		});
	};

	render() {
		const { classes } = this.props;
		const { employees, managers, openDeletePanel, deleteEmployeeId } = this.state;

		employees.forEach((employee) => {
			const manager = managers.find((manager) => manager._id === employee.manager_id);

			if (manager) {
				employee.manager = manager.first_name + ' ' + manager.last_name;
			}
		});

		return (
			<WithPermission has={'employees_view'}>
				<div className={classes.root}>
					<Employees
						user={this.context}
						employees={employees}
						handleCreate={this.handleCreate}
						handleEdit={this.handleEdit}
						handleDelete={this.handleDelete}
					/>
					<DeleteEmployee 
						open={openDeletePanel} 
						onClose={this.handleCloseDeletePanel} 
						onDeleteEmployee={() => {
						this.handleConfirmDelete(deleteEmployeeId)
						this.handleCloseDeletePanel();
						}} 
					/>
				</div>
			</WithPermission>
		);
	}
}

export default withStyles(styles)(EmployeesHome);
