import React from 'react';
import StyledTableCell from 'components/ui/table/StyledTableCell';
import StyledTableRow from 'components/ui/table/StyledTableRow';
import Switch from '@material-ui/core/Switch';
import Fade from '@material-ui/core/Fade';
import WithPermission from 'components/WithPermission';

export const Permission = (props) => {
	const { permission, addPermission, removePermission } = props;
	const { _id, name, description, table, status } = permission;

	const changeStatus = (permission) => {
		permission.status
			? removePermission(permission._id)
			: addPermission(permission._id);
	};

	return (
		<Fade in={true}>
			<StyledTableRow key={_id}>
				<StyledTableCell component="th" scope="row">
					{name}
				</StyledTableCell>
				<StyledTableCell>{description}</StyledTableCell>
				<StyledTableCell>{table}</StyledTableCell>
				<StyledTableCell>
					<WithPermission has={'permissions_edit'}>
						<Switch
							checked={status}
							onChange={() => changeStatus(permission)}
							name="hasPermission"
							inputProps={{ 'aria-label': 'secondary checkbox' }}
						/>
					</WithPermission>
				</StyledTableCell>
			</StyledTableRow>
		</Fade>
	);
};
