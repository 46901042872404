import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
	root: {
		padding: '0 0 0 10px',
		'& h4': {
			color: theme.palette.primary.main
		}
	}
});

const ClaimsCount = (props) => {
	const { visiblesCount, classes } = props;

	return (
		<div className={classes.root}>
			<h4>{`${visiblesCount} claims found`}</h4>
		</div>
	);
};

export default withStyles(styles)(ClaimsCount);
