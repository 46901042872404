import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useGutterBorderedGridStyles } from '@mui-treasury/styles/grid/gutterBordered';
import { readableFormatDate } from 'utils';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	statLabel: {
		fontSize: '1em',
		color: theme.palette.grey[500],
		fontWeight: 500,
		fontFamily:
			'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
		margin: 0
	},
	statValue: {
		fontWeight: 'bold',
		margin: 0,
		letterSpacing: '1px'
	}
}));

const ClaimTimespan = (props) => {
	const { start_date, end_date } = props.claim;
	const styles = useStyles();
	const borderedGridStyles = useGutterBorderedGridStyles({
		borderColor: 'rgba(0, 0, 0, 0.08)',
		height: '50%'
	});

	return (
		<Box display={'flex'}>
			<Box p={2} flex={'auto'} className={borderedGridStyles.item}>
				<p className={styles.statLabel}>From</p>
				<p className={styles.statValue}>{readableFormatDate(moment.utc(start_date))}</p>
			</Box>
			<Box p={2} flex={'auto'} className={borderedGridStyles.item}>
				<p className={styles.statLabel}>To</p>
				<p className={styles.statValue}>{readableFormatDate(moment.utc(end_date))}</p>
			</Box>
		</Box>
	);
};

export default ClaimTimespan;
