import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { withData } from 'components/withData';

const styles = (theme) => ({
	root: {
		flex: 1,
		margin: theme.spacing(1)
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: '100%'
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	}
});

class TocNamesSelector extends React.PureComponent {
	state = {
		showNewPadawan: true
	};

	handleNameSelect = (event) => {
		this.props.saveTocCallback({ name: event.target.value });
	};

	handleLevelSelect = (namesToLevels) => (event) => {
		const { name } = this.props;
		const level = event.target.value;
		this.props.saveTocCallback({ level: level, _id: namesToLevels[name][level] });
	};

	render() {
		const { classes, data, name, level } = this.props;

		let namesToLevels = {};
		data.forEach((toc) => {
			namesToLevels[toc.name]
				? (namesToLevels[toc.name][toc.level] = toc._id)
				: (namesToLevels[toc.name] = { [toc.level]: toc._id });
		});

		return (
			<div>
				<FormControl className={classes.formControl}>
					<InputLabel id="toc-name">TOC Name</InputLabel>
					<Select
						labelId="toc-name"
						id="toc"
						value={name}
						onChange={this.handleNameSelect}
					>
						{Object.keys(namesToLevels).map((tocName) => (
							<MenuItem key={tocName} value={tocName}>
								{tocName}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl className={classes.formControl}>
					<InputLabel id="toc-level">TOC Level</InputLabel>
					<Select
						labelId="toc-level"
						id="toc"
						value={level}
						onChange={this.handleLevelSelect(namesToLevels)}
					>
						{namesToLevels[name] &&
							Object.keys(namesToLevels[name]).map((tocLevel) => (
								<MenuItem key={tocLevel} value={tocLevel}>
									{tocLevel}
								</MenuItem>
							))}
					</Select>
				</FormControl>
			</div>
		);
	}
}

export default withData(
	withStyles(styles)(TocNamesSelector),
	'/api/padawan/toc/list'
);
