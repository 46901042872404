import React from 'react';
import { withStyles, FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Selector from 'components/ui/Selector';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { isMobileDevice, removeStaticsPicker } from 'utils';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = (theme) => ({
	root: {
		padding: '0 0 0 10px'
	},
	filter: {
		width: '100%',
		display: 'flex-root',
		'justify-content': 'flex-start',
		margin: '0 0 30px 0',
		background: theme.palette.grayScale.white
	},
	title: {
		margin: '18px 0 -10px 0'
	},
	formControl: {
		margin: '0 15px 0 0',
		minWidth: 120,
		'& .MuiFormControlLabel-root': {
			'margin-top': '12px'
		},
		'& input': {
			width: '235px'
		}
	},
	dialog: {
		height: '92vh',
		width: '100%'
	},
	content: {
		'max-width': '700px',
		display: 'inline-grid'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.secondary.main
	},
	clean_icon: {
		'font-size': '16px',
		position: 'absolute',
		top: '26px',
		right: '2px',
		color: '#345690'
	}
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.filter} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

class ClaimsFilter extends React.PureComponent {
	state = {
		calendarClaimDates: [
			{
				startDate: null,
				endDate: null,
				key: 'selection'
			}
		],
		calendarClaimCreationDate: [
			{
				startDate: null,
				endDate: null,
				key: 'selection'
			}
		],
		onlyMines: false,
		claimDates: '',
		claimCreationDate: '',
		openDates: false,
		openCreation: false,
		state: '',
		type: '',
		name: ''
	};

	handleStateFilter = (state) => {
		this.setState({ state }, () => {
			this.props.handleFilter(this.state);
		});
	};

	handleTypeFilter = (type) => {
		this.setState({ type }, () => {
			this.props.handleFilter(this.state);
		});
	};

	handleNameFilter = (e) => {
		this.setState({ name: e.target.value }, () => {
			this.props.handleFilter(this.state);
		});
	};

	handleClaimDateFilter = (date) => {
		const claimDate = date.selection;

		this.setState(
			{
				openDates: false,
				calendarClaimDates: [claimDate],
				claimDates: `${moment(claimDate.startDate).format('DD/MM/YYYY')} to ${moment(
					claimDate.endDate
				).format('DD/MM/YYYY')}`
			},
			() => {
				this.props.handleFilter(this.state);
			}
		);
	};

	handleOwnerFilter = () => {
		this.setState(
			(prevState) => {
				return {
					onlyMines: !prevState.onlyMines
				};
			},
			() => {
				this.props.handleFilter(this.state);
			}
		);
	};

	handleClaimCreationFilter = (date) => {
		const claimCreation = date.selection;

		this.setState(
			{
				openCreation: false,
				calendarClaimCreationDate: [claimCreation],
				claimCreationDate: `${moment(claimCreation.startDate).format(
					'DD/MM/YYYY'
				)} to ${moment(claimCreation.endDate).format('DD/MM/YYYY')}`
			},
			() => {
				this.props.handleFilter(this.state);
			}
		);
	};

	/**
	 * Opens a modal to filter claim by claim dates
	 */
	openCalendarClaimDates = () => {
		this.setState({
			openDates: true,
			openCreation: false,
			calendarClaimDates: [
				{
					startDate: new Date(),
					endDate: new Date(),
					key: 'selection'
				}
			]
		});
	};

	/**
	 * Opens a modal to filter claim by creation date
	 */
	openCalendarClaimCreation = () => {
		this.setState({
			openDates: false,
			openCreation: true,
			calendarClaimCreationDate: [
				{
					startDate: new Date(),
					endDate: new Date(),
					key: 'selection'
				}
			]
		});
	};

	/**
	 * When closing the calendar modal
	 */
	onClose = () => {
		this.setState({ openDates: false, openCreation: false });
	};

	/**
	 * Clears the input field and date state
	 */
	clearDate = (key) => {
		const calendarKey =
			key === 'claimDates' ? 'calendarClaimDates' : 'calendarClaimCreationDate';

		this.setState(
			{
				[key]: '',
				[calendarKey]: [
					{
						startDate: null,
						endDate: null,
						key: 'selection'
					}
				]
			},
			() => {
				this.props.handleFilter(this.state);
			}
		);
	};

	render() {
		const { classes, typesOptions } = this.props;
		const {
			state,
			type,
			name,
			openDates,
			openCreation,
			claimDates,
			claimCreationDate,
			onlyMines,
			calendarClaimDates,
			calendarClaimCreationDate
		} = this.state;

		return (
			<div className={classes.root}>
				<div className={classes.filter}>
					<FormControl className={classes.formControl}>
						<FormControlLabel
							label="Only mines"
							control={
								<Switch
									color="secondary"
									name="onlyMines"
									checked={onlyMines}
									onChange={this.handleOwnerFilter}
								/>
							}
						/>
					</FormControl>
					<FormControl className={classes.formControl}>
						<Selector
							key="filter_state"
							name="state"
							value={state}
							onChange={this.handleStateFilter}
							options={{
								on_review: 'On review',
								declined: 'Declined',
								approved: 'Approved',
								scheduled: 'Scheduled',
								in_progress: 'In progress',
								already_taken: 'Already taken'
							}}
						/>
					</FormControl>
					<FormControl className={classes.formControl}>
						<Selector
							key="filter_type"
							name="type"
							value={type}
							onChange={this.handleTypeFilter}
							options={typesOptions()}
						/>
					</FormControl>
					{!onlyMines && (
						<FormControl className={classes.formControl}>
							<TextField
								type="search"
								onChange={this.handleNameFilter}
								name="name"
								key="filter_name"
								value={name}
								inputProps={{ autoComplete: 'off' }}
								id="standard-multiline-static"
								label="Employee name"
								fullWidth
							/>
						</FormControl>
					)}
					<FormControl className={classes.formControl}>
						<TextField
							onClick={this.openCalendarClaimDates}
							name="filter_claim_date"
							key="filter_claim_date"
							value={claimDates}
							inputProps={{ autoComplete: 'off' }}
							label="Claim dates"
							fullWidth
						/>
						{claimDates.length !== 0 && (
							<CloseIcon
								className={classes.clean_icon}
								// It clears the filter_claim_date input field
								onClick={() => {
									this.clearDate('claimDates');
								}}
							/>
						)}
					</FormControl>
					<FormControl className={classes.formControl}>
						<TextField
							onClick={this.openCalendarClaimCreation}
							name="filter_claim_creation"
							key="filter_claim_creation"
							value={claimCreationDate}
							inputProps={{ autoComplete: 'off' }}
							label="Claim creation date"
							fullWidth
						/>
						{claimCreationDate.length !== 0 && (
							<CloseIcon
								className={classes.clean_icon}
								// It clears the filter_claim_creation input field
								onClick={() => {
									this.clearDate('claimCreationDate');
								}}
							/>
						)}
					</FormControl>
				</div>
				<Dialog
					key="filter_date_modal"
					className={classes.dialog}
					aria-labelledby="dialog-title"
					open={openDates || openCreation}
					maxWidth="xl"
					onClose={this.onClose}
					TransitionProps={{
						onEnter: removeStaticsPicker
					}}
				>
					<DialogTitle id="dialog-title" onClose={this.onClose}>
						{openDates && 'Filter by claim dates'}
						{openCreation && 'Filter by claim creation date'}
					</DialogTitle>

					<DialogContent dividers>
						<div className={classes.content}>
							{openDates && (
								<DateRangePicker
									rangeColors={['#15A6D9']}
									startDatePlaceholder="From"
									endDatePlaceholder="To"
									showSelectionPreview={true}
									moveRangeOnFirstSelection={false}
									months={2}
									direction={isMobileDevice() ? 'vertical' : 'horizontal'}
									ranges={calendarClaimDates}
									onChange={this.handleClaimDateFilter}
									staticRanges={[]}
									inputRanges={[]}
								/>
							)}
							{openCreation && (
								<DateRangePicker
									rangeColors={['#15A6D9']}
									startDatePlaceholder="From"
									endDatePlaceholder="To"
									showSelectionPreview={true}
									moveRangeOnFirstSelection={false}
									months={2}
									direction={isMobileDevice() ? 'vertical' : 'horizontal'}
									ranges={calendarClaimCreationDate}
									onChange={this.handleClaimCreationFilter}
									staticRanges={[]}
									inputRanges={[]}
								/>
							)}
						</div>
					</DialogContent>
				</Dialog>
			</div>
		);
	}
}

export default withStyles(styles)(ClaimsFilter);
