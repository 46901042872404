import React from 'react';
import { Route, Link, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { UserContext, TitleContext } from 'AppContexts';
import WithPermission from 'components/WithPermission';
import Permissions from 'permissions';

import PadawanHome from './PadawanHome';
import PadawanAdmin from './PadawanAdmin';
import TocsHome from './TocsHome';

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	mainContent: {
		margin: theme.spacing(2)
	}
});

class PadawanIndex extends React.PureComponent {

	static contextType = TitleContext;

	componentDidMount() {
		//We use the context here (instead of the WithTitle component because we
		//can. I find this approach better, less markup in the render. We must use
		//WithTitle in pure functions, but in full-fledged components, we can use
		//the context directly
		this.context.setTitle(this.props.title, this.props.experimental);
	}

	renderTab(title, url) {
		return <Tab label={title} value={url} component={Link} to={url} />;
	}

	renderAdminTab(title, url, user) {
		if (Permissions.has(user, 'PADAWANS_REVIEW')) {
			return this.renderTab(title, url);
		}
		return null;
	}

	render() {
		const { match, location, classes } = this.props;
		if (location.pathname === match.path) {
			return <Redirect to={`${match.path}/home`} />;
		}

		return (
			<UserContext.Consumer>
				{(user) => (
					<div className={classes.root}>
						<AppBar position="static" color="secondary">
							<Tabs
								value={location.pathname}
								aria-label="padawans"
								indicatorColor="primary"
							>
								{this.renderTab('Padawan', `${match.path}/home`)}
								{this.renderTab('Tocs', `${match.path}/tocs`)}
								{/* Can't use WithPermission here because the Tabs component
                  expects all its children to be Tab components */}
								{this.renderAdminTab('Padawan admin', `${match.path}/admin`, user)}
							</Tabs>
						</AppBar>
						<div className={classes.mainContent}>
							<Route exact path={`${match.path}/home`} component={PadawanHome} />
							<Route exact path={`${match.path}/tocs`} component={TocsHome} />
							<WithPermission has={'PADAWANS_REVIEW'}>
								<Route exact path={`${match.path}/admin`} component={PadawanAdmin} />
							</WithPermission>
						</div>
					</div>
				)}
			</UserContext.Consumer>
		);
	}
}

PadawanIndex.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PadawanIndex);
