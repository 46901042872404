import React from 'react';
import { Route, Link, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { TitleContext } from 'AppContexts';
import AdminSettings from './settings';
import AdminModules from './modules';
import AdminUsers from './users';
import AdminRoles from './roles';

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	mainContent: {
		margin: theme.spacing(2)
	}
});

class AdminIndex extends React.PureComponent {

	static contextType = TitleContext;

	componentDidMount() {
		//We use the context here (instead of the WithTitle component because we
		//can. I find this approach better, less markup in the render. We must use
		//WithTitle in pure functions, but in full-fledged components, we can use
		//the context directly
		this.context.setTitle(this.props.title, this.props.experimental);
	}

	renderTab(title, url) {
		return <Tab label={title} value={url} component={Link} to={url} />;
	}

	render() {
		const { match, location, classes } = this.props;

		//If we are at /admin, redirect to settings
		if (location.pathname === match.path) {
			return <Redirect to={`${match.path}/settings`} />;
		}

		return (
			<div className={classes.root}>
				<AppBar position="static" color="secondary">
					<Tabs value={location.pathname} aria-label="admin" indicatorColor="primary">
						{this.renderTab('Settings', `${match.path}/settings`)}
						{this.renderTab('Modules', `${match.path}/modules`)}
						{this.renderTab('Users', `${match.path}/users`)}
						{this.renderTab('Roles & Permissions', `${match.path}/roles`)}
					</Tabs>
				</AppBar>
				<div className={classes.mainContent}>
					<Route path={`${match.path}/settings`} component={AdminSettings} />
					<Route path={`${match.path}/modules`} component={AdminModules} />
					<Route path={`${match.path}/users`} component={AdminUsers} />
					<Route path={`${match.path}/roles`} component={AdminRoles} />
				</div>
			</div>
		);
	}
}

AdminIndex.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AdminIndex);
