import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import assign from 'lodash/assign';

//TODO: There are several sytling / behaviour issues
const styles = (theme) => ({
	textarea: {
		width: '100%',
		resize: 'none',
		outlineColor: theme.palette.primary.dark
	}
});

/**
 * Modal dialog for employee claiming of a single badge
 */
//TODO: Focus first field on load
class BadgesClaimDialog extends React.PureComponent {
	state = {
		valid: false,
		values: {}
	};

	evidenceRef = React.createRef();

	cancel = () => {
		this.props.onCancel();
	};

	addClaim = () => {
		this.props.onClaim({
			badge: this.props.badge,
			properties: assign({}, this.state.values),
			evidence: this.evidenceRef.current.value
		});
	};

	validate = (values) => {
		return (
			Object.keys(this.props.badge.properties).every((key) => {
				return values[key];
			}) && this.evidenceRef.current.value.length > 0
		);
	};

	setValue = (key, value) => {
		const newValues = assign({}, this.state.values);
		newValues[key] = value;
		this.setState({ values: newValues, valid: this.validate(newValues) });
	};

	getValueSetter = (key) => {
		return (e, p) => {
			this.setValue(key, e.target.value);
		};
	};

	render() {
		const { classes, badge } = this.props;
		const { valid, values } = this.state;

		return (
			<Dialog open={true} aria-labelledby="claim-dialog-title">
				<DialogTitle id="claim-dialog-title">Claiming badge: {badge.name}</DialogTitle>
				<DialogContent>
					<DialogContentText>{badge.description}</DialogContentText>
					{Object.keys(badge.properties).map((property, i) => {
						const label =
							property.charAt(0).toUpperCase() + property.slice(1).replace('_', ' ');
						return (
							<TextField
								key={i}
								margin="normal"
								id={property}
								inputProps={{ autoComplete: 'off' }}
								label={label}
								type={badge.properties[property]}
								required={true}
								value={values[property] || ''}
								onChange={this.getValueSetter(property)}
								fullWidth
							/>
						);
					})}
					<textarea
						rows={6}
						className={classes.textarea}
						required="required"
						ref={this.evidenceRef}
						placeholder="Provide evidence supporting your claim here"
						onChange={() => {
							this.setState({ valid: this.validate(this.state.values) });
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.cancel} variant="contained">
						Cancel
					</Button>
					<Button
						onClick={this.addClaim}
						variant="contained"
						color="secondary"
						disabled={!valid}
					>
						Add claim
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

BadgesClaimDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	badge: PropTypes.object.isRequired,
	onClaim: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
};

export default withStyles(styles)(BadgesClaimDialog);
