import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import WarningIcon from '@material-ui/icons/Warning';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { withData } from 'components/withData';
import Fab from '@material-ui/core/Fab';
import { successMsg, errorMsg, assureAction } from 'utils/alerts';
import { BASE_URL_ACTIONS } from 'utils/base';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
	list: {}
});

/**
 * This widget displays any pending actions the user has to execute. It is
 * used in the home dashboard.
 */

class ActionsWidget extends React.PureComponent {
	getActionHandler = (action) => {
		return (e) => {
			if (action.delete_on_click === true) {
				axios({ method: 'delete', url: BASE_URL_ACTIONS + action._id });
			}
			if (action.url) {
				this.props.history.push(action.url);
			}
		};
	};

	render() {
		const { classes, data } = this.props;

		return (
			<List dense={true} className={classes.list}>
				{data.map((action, i) => {
					const { dead_line, expired, title, createdAt } = action;
					let secondaryTitle =
						dead_line &&
						(expired
							? `Deadline expired: ${moment(dead_line).fromNow()}`
							: `Deadline: ${moment(dead_line).fromNow()}`);

					if (action.context && action.context.description) {
						secondaryTitle = secondaryTitle
							? secondaryTitle + '. '
							: '' + `Descripion: ${action.context.description}`;
					}

					return (
						<ListItem key={i}>
							<ListItemIcon>
								<WarningIcon color={expired ? 'error' : 'primary'} fontSize="large" />
							</ListItemIcon>
							<ListItemText
								primary={title}
								secondary={
									<>
										<Typography display="block" component={'span'}>{`Created at: ${moment(
											createdAt
										).format('MMMM Do YYYY, h:mm a')}`}</Typography>
										<Typography display="block" component={'span'}>
											{secondaryTitle || ''}
										</Typography>
									</>
								}
								title={dead_line && moment(dead_line).format('MMMM Do YYYY, h:mm:ss a')}
							/>
							<ListItemSecondaryAction>
								{action.type.includes('confirmation') ? (
									<>
										{action.context.deny && (
											<Fab
												size="small"
												title={action.context.deny.title}
												variant="extended"
												aria-label="deny"
												color="default"
												onClick={() => {
													assureAction(action.context.deny.title, () => {
														axios({
															method: action.context.deny.method,
															url: action.context.deny.url,
															data: action.context.deny.data
														})
															.then((response) => {
																successMsg(response.data.message);
																if (action.delete_on_click === true) {
																	axios({ method: 'delete', url: BASE_URL_ACTIONS + action._id });
																}
															})
															.catch((error) => {
																errorMsg(error.response.data.error);
															});
													});
												}}
											>
												Deny
											</Fab>
										)}
										{action.context.confirm && (
											<Fab
												size="small"
												title={action.context.confirm.title}
												variant="extended"
												aria-label="Confirm"
												color="primary"
												onClick={() => {
													assureAction(action.context.confirm.title, () => {
														axios({
															method: action.context.confirm.method,
															url: action.context.confirm.url,
															data: action.context.confirm.data
														})
															.then((response) => {
																successMsg(response.data.message);
																if (action.delete_on_click === true) {
																	axios({ method: 'delete', url: BASE_URL_ACTIONS + action._id });
																}
															})
															.catch((error) => {
																errorMsg(error.response.data.error);
															});
													});
												}}
											>
												Confirm
											</Fab>
										)}
									</>
								) : (
									<IconButton
										edge="end"
										onClick={this.getActionHandler(action)}
										disabled={expired}
									>
										<CloudUploadIcon color={expired ? 'inherit' : 'secondary'} />
									</IconButton>
								)}
							</ListItemSecondaryAction>
						</ListItem>
					);
				})}
			</List>
		);
	}
}

ActionsWidget.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withData(withStyles(styles)(withRouter(ActionsWidget)), {
	url: '/api/actions',
	reload: 10
});
