import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/Publish';
import WavesIcon from '@material-ui/icons/Waves';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import StyledTableCell from 'components/ui/table/StyledTableCell';
import StyledTableRow from 'components/ui/table/StyledTableRow';

import axios from 'axios';

import { withData } from 'components/withData';

const styles = (theme) => ({
	root: {
		flexGrow: 1
	}
});

const DISABLED = 'disabled';
const EXPERIMENTAL = 'experimental';
const AVAILABLE = 'available';

class Row extends React.PureComponent {
	_handleAction = (id, action) => {
		axios
			.post(`/api/modules/${id}/${action}`)
			.then((response) => {
				this.props.reloadData();
			})
			.catch((e) => {
				console.log(`Error performing ${action} on ${id}`, e);
			});
	};

	handleDisable = () => {
		this._handleAction(this.props.row._id, 'disable');
	};

	handleExperimental = () => {
		this._handleAction(this.props.row._id, 'experiment');
	};

	handlePublish = () => {
		this._handleAction(this.props.row._id, 'publish');
	};

	isDisableDisabled = () => {
		return this.props.row.state === DISABLED;
	};

	isExperimentalDisabled = () => {
		return this.props.row.state === EXPERIMENTAL;
	};

	isPublishDisabled = () => {
		return this.props.row.state === AVAILABLE;
	};

	render() {
		const { row } = this.props;
		return (
			<StyledTableRow key={row._id}>
				<StyledTableCell component="th" scope="row">
					{row.name}
				</StyledTableCell>
				<StyledTableCell>{row.description}</StyledTableCell>
				<StyledTableCell>{row.state}</StyledTableCell>
				<StyledTableCell>
					<IconButton
						onClick={this.handleDisable}
						disabled={this.isDisableDisabled()}
						title="Disable module"
					>
						<RemoveCircleIcon />
					</IconButton>
					<IconButton
						onClick={this.handleExperimental}
						disabled={this.isExperimentalDisabled()}
						title="Publish module as experimental"
					>
						<WavesIcon />
					</IconButton>
					<IconButton
						onClick={this.handlePublish}
						disabled={this.isPublishDisabled()}
						title="Publish module"
					>
						<PublishIcon />
					</IconButton>
				</StyledTableCell>
			</StyledTableRow>
		);
	}
}

class ModulesHome extends React.PureComponent {
	render() {
		const { data, classes } = this.props;

		return (
			<div className={classes.root}>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableCell>Name</StyledTableCell>
							<StyledTableCell>Description</StyledTableCell>
							<StyledTableCell>State</StyledTableCell>
							<StyledTableCell>Actions</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row) => (
							<Row key={row._id} row={row} reloadData={this.props.reloadData} />
						))}
					</TableBody>
				</Table>
			</div>
		);
	}
}

export default withStyles(styles)(withData(ModulesHome, '/api/modules'));
