import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
	root: {
		flexGrow: 1
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	titleContainer: {
		flexGrow: 1
	},
	title: {
		cursor: 'pointer'
	},
	suffix: {
		paddingLeft: theme.spacing(1),
		color: '#ccc'
	},
	beta: {
		color: '#dd4444',
		fontSize: '12px',
		padding: theme.spacing(1)
	},
	avatar: {
		margin: 0
	}
});

class ApplicationBar extends React.Component {
	state = {
		leftMenuOpen: false,
		rightMenuOpen: false
	};

	navigateTo = (url) => {
		this.props.history.push(url);
		this.closeLeftMenu();
		this.closeRightMenu();
	};

	openLeftMenu = (e) => {
		this.setState({ leftMenuOpen: true, leftMenuAnchor: e.currentTarget });
	};

	closeLeftMenu = () => {
		this.setState({ leftMenuOpen: false, leftMenuAnchor: null });
	};

	renderLeftMenu() {
		const { classes } = this.props;
		const { leftMenuOpen, leftMenuAnchor } = this.state;

		return (
			<div>
				<IconButton
					edge="start"
					className={classes.menuButton}
					color="inherit"
					aria-label="Menu"
					onClick={this.openLeftMenu}
					aria-controls="menu-nav"
				>
					<MenuIcon />
				</IconButton>
				<Menu
					id="menu-nav"
					anchorEl={leftMenuAnchor}
					keepMounted
					anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
					transformOrigin={{ vertical: 'top', horizontal: 'left' }}
					open={leftMenuOpen}
					onClose={this.closeLeftMenu}
				>
					{this.props.leftRoutes.map((route) => {
						const { _id, url, name } = route;
						return (
							<MenuItem
								key={_id}
								onClick={() => {
									this.navigateTo(url);
								}}
							>
								{name}
							</MenuItem>
						);
					})}
				</Menu>
			</div>
		);
	}

	openRightMenu = (e) => {
		this.setState({ rightMenuOpen: true, rightMenuAnchor: e.currentTarget });
	};

	closeRightMenu = () => {
		this.setState({ rightMenuOpen: false, rightMenuAnchor: null });
	};

	renderRightMenu() {
		const { rightMenuOpen, rightMenuAnchor } = this.state;
		const { user, classes, onLogout, rightRoutes } = this.props;

		return (
			<div>
				<IconButton
					aria-label="Account of current user"
					aria-controls="menu-user"
					aria-haspopup="true"
					onClick={this.openRightMenu}
					color="inherit"
				>
					<Avatar src={user.picture} className={classes.avatar} />
				</IconButton>

				<Menu
					id="menu-user"
					anchorEl={rightMenuAnchor}
					keepMounted
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					transformOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={rightMenuOpen}
					onClose={this.closeRightMenu}
				>
					{rightRoutes.map((route) => (
						<MenuItem
							key={route._id}
							onClick={() => {
								this.navigateTo(route.url);
							}}
						>
							{route.name}
						</MenuItem>
					))}
					<MenuItem onClick={onLogout}>Logout</MenuItem>
				</Menu>
			</div>
		);
	}

	render() {
		const { classes, user, title, experimental } = this.props;
		return (
			<div className={classes.root}>
				<AppBar position="static">
					<Toolbar>
						{user && this.renderLeftMenu()}
						<Typography variant="h6" className={classes.titleContainer}>
							<span
								className={classes.title}
								onClick={() => {
									this.navigateTo('/');
								}}
							>
								Ekumen
							</span>
							<span className={classes.suffix}>{title}</span>
							{experimental && <span className={classes.beta}>Experimental</span>}
						</Typography>
						{user && this.renderRightMenu()}
					</Toolbar>
				</AppBar>
			</div>
		);
	}
}

ApplicationBar.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	onLogout: PropTypes.func.isRequired,
	user: PropTypes.object
};

export default withStyles(styles)(withRouter(ApplicationBar));
