import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withData } from 'components/withData';
import Section from 'components/ui/Section';
import PadawanJourneysGroup from './PadawanJourneysGroup';
import ActiveJourneysGroup from './ActiveJourneysGroup';

const styles = (theme) => ({
	root: {
		flex: 1,
		margin: theme.spacing(1)
	},
	fab: {
		margin: theme.spacing(1),
		position: 'absolute',
		bottom: '20px',
		right: '20px'
	}
});

class PadawanAdmin extends React.PureComponent {
	state = {
		showNewPadawan: false
	};

	displayJourneyAdd = () => {
		this.setState({ showNewPadawan: true });
	};

	onSubmit = (journey) => {
		this.setState({ showNewPadawan: false });
	};

	onClose = () => {
		this.setState({ showNewPadawan: false });
	};

	render() {
		const { classes, data, reloadData } = this.props;

		const notStarted = [];
		const inprogressJourneys = [];

		data.forEach((journey) => {
			switch (journey.state) {
				case 'created':
				case 'waiting_yoda':
				case 'not_started':
					notStarted.push(journey);
					break;
				default:
					inprogressJourneys.push(journey);
			}
		});

		return (
			<div className={classes.root}>
				<Section title="Journeys not started">
					<PadawanJourneysGroup journeys={notStarted} reloadData={reloadData} />
				</Section>
				<Section title="Journeys in progress">
					<ActiveJourneysGroup journeys={inprogressJourneys} reloadData={reloadData} />
				</Section>
			</div>
		);
	}
}

export default withData(
	withStyles(styles)(PadawanAdmin),
	'/api/padawan/admin/journeys'
);
