import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

const styles = (theme) => ({
	title: {
		margin: '5px 0 7px 0'
	},
	small: {
		width: theme.spacing(3),
		height: theme.spacing(3),
		'font-size': '1em',
		background: 'white',
		color: theme.palette.secondary.main,
		border: `1px solid ${theme.palette.secondary.main}`
	},
	days: {
		margin: '1px'
	},
	subheader: {
		fontSize: 14,
		color: theme.palette.grey[500],
		display: 'flow-root'
	}
});

const ClaimedDays = (props) => {
	const { classes, claim } = props;
	const {
		weekends_inside,
		holidays_inside,
		vacation_current_days,
		vacation_inadvance_days,
		vacation_postponed_days,
		amount,
		type,
		_id
	} = claim;

	return (
		<div>
			<h4 className={classes.title}>General timespan info</h4>

			<Chip
				key={`total_days${_id}`}
				className={classes.days}
				icon={<Avatar className={classes.small}>{amount}</Avatar>}
				variant="outlined"
				color="secondary"
				label="Days off"
			/>

			{type.includes('vacation') && (
				<>
					{weekends_inside ? (
						<Chip
							key={`weekends_inside${_id}`}
							className={classes.days}
							icon={<Avatar className={classes.small}>{weekends_inside}</Avatar>}
							variant="outlined"
							color="secondary"
							label="Weekend days"
						/>
					) : (
						''
					)}

					{holidays_inside ? (
						<Chip
							key={`holidays_inside${_id}`}
							className={classes.days}
							icon={<Avatar className={classes.small}>{holidays_inside}</Avatar>}
							variant="outlined"
							color="secondary"
							label="Holidays in timespan"
						/>
					) : (
						''
					)}

					<h4 className={classes.title}>Requested days by period</h4>

					<Chip
						key={`vacation_postponed_days${_id}`}
						className={classes.days}
						icon={<Avatar className={classes.small}>{vacation_postponed_days}</Avatar>}
						variant="outlined"
						color="secondary"
						label="Postponed"
					/>
					<Chip
						key={`vacation_current_days${_id}`}
						className={classes.days}
						icon={<Avatar className={classes.small}>{vacation_current_days}</Avatar>}
						variant="outlined"
						color="secondary"
						label="Current"
					/>
					<Chip
						key={`vacation_inadvance_days${_id}`}
						className={classes.days}
						icon={<Avatar className={classes.small}>{vacation_inadvance_days}</Avatar>}
						color="secondary"
						variant="outlined"
						label="In advance"
					/>
				</>
			)}
		</div>
	);
};

export default withStyles(styles)(ClaimedDays);
