import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { withData } from 'components/withData';
import Section from 'components/ui/Section';
import PadawanJourneysGroup from './PadawanJourneysGroup';
import NewJourney from './NewJourney';
import { BASE_URL_PADAWAN_JOURNEYS } from 'utils/base';
import axios from 'axios';

const styles = (theme) => ({
	root: {
		flex: 1,
		margin: theme.spacing(1)
	},
	fab: {
		margin: theme.spacing(1),
		position: 'absolute',
		bottom: '20px',
		right: '20px'
	}
});

class PadawanJourneys extends React.PureComponent {
	state = {
		showNewPadawan: false
	};

	displayJourneyAdd = () => {
		this.setState({ showNewPadawan: true });
	};

	onSubmit = (tocId, journeyName, cb) => {
		this.setState({ showNewPadawan: false });
		axios
			.post(`${BASE_URL_PADAWAN_JOURNEYS + tocId}/${journeyName}`)
			.then((response) => {
				this.props.reloadData(cb);
			})
			.catch((err) => {
				cb(err);
			});
	};

	onClose = () => {
		this.setState({ showNewPadawan: false });
	};

	render() {
		const { classes, data, reloadData } = this.props;
		const { showNewPadawan } = this.state;

		const notStarted = [];
		const finishedJourneys = [];
		const inprogressJourneys = [];

		data.forEach((journey) => {
			switch (journey.state) {
				case 'completed':
				case 'canceled':
					finishedJourneys.push(journey);
					break;
				case 'created':
				case 'not_started':
				case 'waiting_yoda':
					notStarted.push(journey);
					break;
				default:
					inprogressJourneys.push(journey);
			}
		});

		return (
			<div className={classes.root}>
				<Section title="Journeys in progress">
					<PadawanJourneysGroup journeys={inprogressJourneys} reloadData={reloadData} />
				</Section>
				<Section title="Journeys not started">
					<PadawanJourneysGroup journeys={notStarted} reloadData={reloadData} />
				</Section>
				<Section title="Journeys finished">
					<PadawanJourneysGroup journeys={finishedJourneys} reloadData={reloadData} />
				</Section>
				<Fab color="primary" onClick={this.displayJourneyAdd} className={classes.fab}>
					<AddIcon />
				</Fab>
				<NewJourney
					open={showNewPadawan}
					onSubmit={this.onSubmit}
					onClose={this.onClose}
				/>
			</div>
		);
	}
}

export default withData(
	withStyles(styles)(PadawanJourneys),
	'/api/padawan/journeys'
);
