import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import EmployeeForm from './EmployeeForm';
import WithPermission from 'components/WithPermission';
import axios from 'axios';
import { successMsg, errorMsg } from 'utils/alerts';
import { BASE_URL_EMPLOYEES } from 'utils/base';

const styles = (theme) => ({
	root: {
		margin: 0,
		flexGrow: 1,
		width: '100%',
		padding: theme.spacing(3)
	}
});

const CreateEmployee = (props) => (
	<WithPermission has={'employees_create'}>
		<div className={props.classes.root}>
			<h2>Create employee</h2>
			<EmployeeForm
				closeForm={() => {
					props.history.push('/employees');
				}}
				onSubmit={(employee) => {
					axios
						.post(BASE_URL_EMPLOYEES, employee)
						.then((response) => {
							successMsg(response.data.message);
							props.history.push('/employees');
						})
						.catch((err) => {
							console.error(err.response);
							errorMsg(`Error saving: ${err.response.data.message}`);
						});
				}}
			/>
		</div>
	</WithPermission>
);

export default withStyles(styles)(CreateEmployee);
