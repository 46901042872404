import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import groupBy from 'lodash/groupBy';

import { withData } from 'components/withData';
import TeamMember from './TeamMember';

const styles = {
	outterGrid: {
		display: 'flex'
	},
	childNode: {
		width: '100%'
	},
	firstRow: {
		height: '20vh'
	},
	middleRow: {
		height: '45vh'
	},
	childrenRow: {
		height: '30vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'middle',
		flexFlow: 'row'
	},
	siblings: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexFlow: 'row'
	},
	midColumn: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
};

/** Renders the whole team, focused on the the requested slug */
class EmployeePage extends React.PureComponent {
	state = { node: null };

	selectNode = (node) => {
		this.props.history.push(`/team/${node.slug}`);
		this.setCurrentUserFromSlug(node.slug);
	};

	setCurrentUserFromSlug = (slug) => {
		this.setState({
			node: this.employeesBySlug[slug]
		});
	};

	componentDidMount() {
		const { data, match } = this.props;
		this.employeesById = {};
		this.employeesBySlug = {};

		data.forEach((e) => {
			this.employeesById[e._id] = e;
			this.employeesBySlug[e.slug] = e;
		});
		this.byManager = groupBy(data, 'manager_id');

		// Recursively populates nodes with its children.
		const populate = (node) => {
			const parentId = node.manager_id;
			//Groupby uses 'null' string to group orphan childs
			node.siblings = this.byManager[parentId || 'null'];

			node.parent = parentId ? this.employeesById[parentId] : null;
			if (this.byManager[node._id]) {
				node.children = this.byManager[node._id];
				node.children.forEach(populate);
			}
		};

		this.byManager['null'].forEach(populate);

		this.setCurrentUserFromSlug(match.params.slug);
	}

	renderTopRow() {
		const { classes } = this.props;
		const { node } = this.state;

		return (
			<Grid item container xs={12} className={classes.firstRow}>
				<Grid item xs={4} />
				<Grid item xs={4} className={classes.midColumn}>
					{node.parent && <TeamMember person={node.parent} onClick={this.selectNode} />}
				</Grid>
				{/* <Grid item xs={4} className={classes.lastColumn}>
					<Section title={`Information about ${node.slug}`}>Bla bla bla about</Section>
				</Grid> */}
			</Grid>
		);
	}

	renderMainRow() {
		const { classes } = this.props;
		const { node } = this.state;

		const leftSiblings = [];
		const rightSiblings = [];

		let appendLeft = true;
		node.siblings.forEach(function (s) {
			if (s._id === node._id) {
				//This is us, so we switch the list to which we are adding, and skip us
				appendLeft = false;
			} else {
				if (appendLeft) {
					leftSiblings.push(s);
				} else {
					rightSiblings.push(s);
				}
			}
		});

		return (
			<Grid item container xs={12} className={classes.middleRow}>
				<Grid item xs={4} className={classes.siblings}>
					{leftSiblings.map((s) => {
						return (
							<div className={classes.childNode} key={s._id}>
								<TeamMember person={s} onClick={this.selectNode} />
							</div>
						);
					})}
				</Grid>
				<Grid item xs={4} className={classes.midColumn}>
					<TeamMember big person={node} />
				</Grid>
				<Grid item xs={4} className={classes.siblings}>
					{rightSiblings.map((s) => {
						return (
							<div className={classes.childNode} key={s._id}>
								<TeamMember person={s} onClick={this.selectNode} />
							</div>
						);
					})}
				</Grid>
			</Grid>
		);
	}

	renderChildrenRow() {
		const { classes } = this.props;
		const { node } = this.state;

		return (
			<Grid item container xs={12} className={classes.childrenRow}>
				{node.children &&
					node.children.map((c) => {
						return (
							<div className={classes.childNode} key={c._id}>
								<TeamMember person={c} onClick={this.selectNode} />
							</div>
						);
					})}
			</Grid>
		);
	}

	render() {
		if (!this.state.node) {
			return <h1>Loading...</h1>;
		}
		return (
			<Grid container className={this.props.classes.outterGrid}>
				{this.renderTopRow()}
				{this.renderMainRow()}
				{this.renderChildrenRow()}
			</Grid>
		);
	}
}

export default withData(withRouter(withStyles(styles)(EmployeePage)), '/api/team');
