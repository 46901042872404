import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import BadgeIcon from 'icons/BadgeIcon';

/**
 * Displays a single badge in a list.
 * @extends React
 */
export default class BadgeListItem extends React.PureComponent {
	onClick = (e) => {
		this.props.onBadgeClicked(this.props.badge);
	};

	render() {
		const { badge, readOnly } = this.props;
		return (
			<ListItem button={!readOnly} onClick={readOnly ? null : this.onClick}>
				<ListItemIcon>
					<BadgeIcon color={'secondary'} />
				</ListItemIcon>
				<ListItemText primary={badge.name} secondary={badge.description} />
			</ListItem>
		);
	}
}

BadgeListItem.propTypes = {
	readOnly: PropTypes.bool.isRequired,
	badge: PropTypes.object.isRequired,
	onBadgeClicked: PropTypes.func.isRequired
};
