import React from 'react';
import { Calendar } from 'react-date-range';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
	dateRange: {
		'justify-content': 'center',
		margin: '10px 0 10px 0',
		padding: '0 0 25px 0'
	},
	'@global': {
		'.react-daterange-picker__wrapper': {
			border: 'none',
			'border-bottom': '1px solid rgba(0, 0, 0, 0.54)'
		},
		'.react-daterange-picker__range-divider': {
			'line-height': '3',
			width: '22px',
			'text-align': 'center'
		}
	},
	dialog: {
		height: '93vh',
		width: '100%'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.filter} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

const DatePickerModal = (props) => {
	const { open, onSelectDate, onCloseCalendar, date, classes, title } = props;

	return (
		<Dialog
			key="filter_date_modal"
			className={classes.dialog}
			aria-labelledby="dialog-title"
			open={open}
			maxWidth="xl"
			onClose={onCloseCalendar}
		>
			<DialogTitle id="dialog-title" onClose={onCloseCalendar}>
				{title}
			</DialogTitle>

			<DialogContent dividers>
				<Calendar
					color={'#15A6D9'}
					date={date ? date : new Date()}
					onChange={onSelectDate}
				/>
			</DialogContent>
		</Dialog>
	);
};

export default withStyles(styles)(DatePickerModal);
