import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import { green, grey } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CircularProgress from '@material-ui/core/CircularProgress';

const OFF = 'off';
const RUNNING = 'running';
const ERROR = 'error';
const SUCCESS = 'success';

const styles = (theme) => ({
	icon: {
		fontSize: 20
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1)
	},
	message: {
		display: 'flex',
		alignItems: 'center'
	},
	content: {
		minHeight: '60px'
	},
	success: {
		backgroundColor: green[600]
	},
	error: {
		backgroundColor: theme.palette.error.dark
	},
	running: {
		backgroundColor: grey[500]
	}
});

/**
 * Displays a snackbar at the bottom-center with the state of a running process.
 * Props:
 *   message (String): Message to display at the snackbar
 *   onClose (function): function called when the snackbar is closed
 *   state (String): One of 'off', 'running', 'success' or 'error'. This will
 *                  change the display accordingly.
 * @extends React
 */
class TransitionSnackbar extends React.PureComponent {
	handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		this.props.onClose();
	};

	//Render an icon according to the current state
	renderIcon() {
		const { state, classes } = this.props;

		switch (state) {
			case SUCCESS:
				return (
					<CheckCircleIcon className={clsx(classes.iconVariant, classes.success)} />
				);
			case RUNNING:
				return (
					<CircularProgress
						size={20}
						className={clsx(classes.iconVariant, classes.running)}
					/>
				);
			case ERROR:
				return <ErrorIcon className={clsx(classes.iconVariant, classes.error)} />;
			default:
				return null;
		}
	}

	renderCloseAction() {
		const { classes, state } = this.props;

		if (state === RUNNING) {
			return [];
		}
		return [
			<IconButton
				key="close"
				aria-label="close"
				color="inherit"
				onClick={this.handleClose}
			>
				<CloseIcon className={classes.icon} />
			</IconButton>
		];
	}

	render() {
		const { classes, message, state } = this.props;

		const autoHideDuration = state === SUCCESS ? 1500 : null;
		return (
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={state !== OFF}
				onClose={this.handleClose}
				autoHideDuration={autoHideDuration}
			>
				<SnackbarContent
					className={clsx(classes[state], classes.content)}
					aria-describedby="client-snackbar"
					message={
						<span id="client-snackbar" className={classes.message}>
							{this.renderIcon()}
							{message}
						</span>
					}
					action={this.renderCloseAction()}
				/>
			</Snackbar>
		);
	}
}

TransitionSnackbar.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	state: PropTypes.oneOf([OFF, RUNNING, SUCCESS, ERROR]).isRequired,
	message: PropTypes.string
};

export default withStyles(styles)(TransitionSnackbar);
