import axios from 'axios';
import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import { BASE_URL_PAYSLIPS } from 'utils/base';
import WithPermission from 'components/WithPermission';

const styles = {
	root: {
		flex: 1
    }
};

class UploadPayslips extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null
        }
        this.baseUrl = `${BASE_URL_PAYSLIPS}`;
    }

    onFileChoosen = (event) => {
        console.log(event.target.files[0])
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
          });
    }
    
    onUploadButtonClicked = () => {
        const { selectedFile } = this.state;
        const { history } = this.props;
        const data = new FormData();
        data.append('file', selectedFile);
        axios.post(`${this.baseUrl}payslips_upload`, data, {})
        .then(res => {
            if(res.status === 200) {
                history.push(`${res.data}`);
            }
        });      
    }

	render() {
        const { classes } = this.props;
		return (
            <WithPermission has={'payslips_upload'}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <form method="post" action="#" id="#">
                                <div className={classes.files}>
                                    <label>Upload Your File </label>
                                    <input type="file" className="form-control" multiple={false} onChange={this.onFileChoosen}/>
                                </div>
                            </form>
                        </div>
                    </div>
                    <button type="button" 
                        className="btn btn-success btn-block" 
                        onClick={this.onUploadButtonClicked}>Upload</button> 
                </div>
            </WithPermission>
        )
    }
}

export default withStyles(styles)(UploadPayslips);
