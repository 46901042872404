import axios from 'axios';
import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { withStyles } from '@material-ui/core/styles';

import { BASE_URL_PAYSLIPS } from 'utils/base';



const styles = {
	root: {
		flex: 1
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    table: {
        width: '80%',
        height: '100px'
    },
};

class PdfViewer extends React.PureComponent {

    state = {
        payslip: null
    }

	render() {
        const { classes, match } = this.props;
        const options = {
            url: `${BASE_URL_PAYSLIPS}view/${match.params.month}`, 
            httpHeaders: { 'Authorization': `${axios.defaults.headers.common.Authorization}`  },
            withCredentials: true 
        }

        return (
            <>
                <br/>
                <div className={classes.container}>
                    <div className={classes.table}>
                        <Document
                            file={options}
                        >
                            <Page pageNumber={1} />
                        </Document>
                        {/*<p>Page {pageNumber} of {numPages}</p>*/}
                    </div>
                </div>
            </>
          );
        }
}

export default withStyles(styles)(PdfViewer);
