import React from 'react';
import { Route } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { UserContext } from 'AppContexts';
import WithTitle from 'components/WithTitle';
import DaysClaimHome from './DaysClaimHome';

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	mainContent: {
		margin: theme.spacing(2)
	}
});

class DaysClaimIndex extends React.PureComponent {
	render() {
		const { classes, match, title, experimental } = this.props;

		return (
			<UserContext.Consumer>
				{(user) => (
					<div className={classes.root}>
						<WithTitle title={title} experimental={experimental}>
							{<Route path={match.path} exact component={DaysClaimHome} />}
						</WithTitle>
					</div>
				)}
			</UserContext.Consumer>
		);
	}
}

DaysClaimIndex.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DaysClaimIndex);
