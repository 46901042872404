/**
 * Controls the routing within the app (front end)
 * when the url matches, it renders the passed component
 */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { TitleContext } from 'AppContexts';
import ApplicationBar from './components/ApplicationBar';
import AppPageNotFound from './AppPageNotFound';
import HomeModule from './home';
import BadgesModule from './badges';
import TeamModule from './team';
import MeModule from './me';
import AdminModule from './admin';
import AboutModule from './about';
import ProfileModule from './profile';
import EmployeesModule from './employees';
import PadawanModule from './padawan';
import TeamBuilderModule from './team_builder';
import DaysClaimModule from './days_claim';
import DaysOffModule from './days_off';
import Payslips from './payslips';

const styles = (theme) => ({
	mainContent: {
		margin: 0,
		display: 'flex',
		flexGrow: 1,
		alignItems: 'top',
		flexDirection: 'column',
		[theme.breakpoints.down('xs')]: {
			height: 'calc(100vh - 56px)',
			overflowY: 'scroll'
		},
		[theme.breakpoints.up('sm')]: {
			height: 'calc(100vh - 64px)',
			overflowY: 'scroll'
		},
		backgroundColor: '#F0F0F0'
	}
});

//These go to the right menu
const RIGHT_MENU_ROUTES = ['about', 'profile'];

const splitRoutes = function (modules = []) {
	const left = [];
	const right = [];

	modules.forEach(function (module) {
		if (RIGHT_MENU_ROUTES.includes(module._id)) {
			right.push(module);
		} else {
			left.push(module);
		}
	});
	return { right: right, left: left };
};

const moduleComponent = {
  'admin': AdminModule,
  'about': AboutModule,
  'badges': BadgesModule,
  'days_claim': DaysClaimModule,
  'days_off': DaysOffModule,
  'employees': EmployeesModule,
  'me': MeModule,
  'team': TeamModule,
  'profile': ProfileModule,
  'padawan': PadawanModule,
  'team_builder': TeamBuilderModule,
  'payslips': Payslips,
};


class AppRouter extends React.PureComponent {

	state = {
		title: '',
    experimental: false
	};

	handleLogout = () => {
		const { onLogout, history } = this.props;

		//Redirect the browser to '/'. This needs to happen here because we need
		//to be inside the router context to be able to use it
		history.replace('/');

		//Call App's onLogout which will effectively logout the user
		onLogout();
	};

	setTitle = (newTitle, experimental = false) => {
		this.setState({ title: newTitle, experimental: experimental });
	};

  renderModules(modules) {
    const routes = [];

    //You always get the home module
    routes.push(<Route exact key='home' path='/' component={HomeModule} />);

    for(let i = 0 ; i < modules.length ; i++) {
      const module = modules[i];
      const Comp = moduleComponent[module._id];
      if (! Comp) {
        console.error(`No component registered for module '${module._id}'`);
        continue
      }

      routes.push(
        <Route key={module._id} path={module.url} render={routeProps => {
            return (<Comp {...routeProps} title={module.name}
              experimental={module.state === 'experimental'} />);
          }}
        />
      );
    }

    //You always get the 404 handler
    routes.push(<Route key='404' component={AppPageNotFound} />);

    return routes;
  }

	render() {
		const { classes, user, modules } = this.props;
		const { title, experimental } = this.state;
		const { left, right } = splitRoutes(modules);

		return (
			<React.Fragment>
				<TitleContext.Provider value={{ title: title, setTitle: this.setTitle }}>
					<ApplicationBar
						title={title}
						leftRoutes={left}
						rightRoutes={right}
						user={user}
            experimental={experimental}
						onLogout={this.handleLogout}
					/>
					<div className={classes.mainContent}>
  					<Switch>
              {this.renderModules(modules)}
  					</Switch>
					</div>
				</TitleContext.Provider>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(withRouter(AppRouter));
