import React from 'react';

/* Declares the UserContext which contains the currently logged in user, so
that any component may use if without having to pass the prop around */
export const UserContext = React.createContext();

/* Declares the EmployeeContext which contains the employee object related to
the currently logged in user. It may be null, if there is no employee associated
to the user */
export const EmployeeContext = React.createContext();

/* Declares the TitleContext which is used by the modules to change the title
displayed by the app bar */
export const TitleContext = React.createContext();
