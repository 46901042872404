import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ClaimedDays from './ClaimedDays';
import ClaimTimespan from './ClaimTimespan';

const styles = (theme) => ({
	root: {
		margin: 0,
		width: '100%',
		padding: theme.spacing(3)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	help: {
		textAlign: 'justify',
		fontSize: '1.1em',
		fontWeight: 'bold',
		marginBottom: '8px',
		'& .highlight': {
			color: theme.palette.primary.light
		}
	},
	claim: {
		textAlign: 'center'
	},
	confirm: {
		marginTop: '15px'
	}
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

const InadvanceDaysInstructions = (props) => {
	const { open, classes, claim, onClose, requestDaysInadvance } = props;

	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="dialog-title"
			open={open}
			maxWidth="sm"
			fullWidth={true}
			className={classes.root}
		>
			<DialogTitle id="dialog-title" onClose={onClose}>
				Using vacation days in advance
			</DialogTitle>

			<DialogContent dividers>
				{/* This modal is shown to the user twice: 
				A - Immediately after create a claim that needs to take days in advance
				B - If the user doesn't did it on point A, has a second chance with the action we send
				if the claim needs days in advance. That action will redirect the user to this same dialog*/}

				<p className={classes.help}>
					Each year’s vacation period goes from October 1st to April 30th of the following
					year.{' '}
					<span className="highlight">
						Ekumen employees can place a request to use vacation days in-advance, before
						October 1st (renewal date).
					</span>
				</p>

				{claim ? (
					<>
						<p className={classes.help}>
							You have created a <span className="highlight">{claim.type} days claim</span>{' '}
							that requires you to spend{' '}
							<span className="highlight">{claim.vacation_inadvance_days}</span>{' '}
							days-in-advance.
						</p>

						<div className={classes.claim}>
							<ClaimTimespan claim={claim} />
							<ClaimedDays claim={claim} />
						</div>

						<p className={classes.help}>
							In order to use your days-in-advance, please download and fill out the paper
							form. Send a scanned copy to HRadmin@ekumenlabs.com (As soon as you can, bring
							us the physical copy to HQ). Remember this is critical for your claim to be
							approved.
						</p>

						{window.location.hash.includes('show-inadvance-instructions') && (
							// The user may have done this just after create the claim
							<p className={classes.help}>
								Note: If you already sent the form for this claim previously, just close this
								dialog.
							</p>
						)}
						<Button
							className={classes.confirm}
							variant="contained"
							color="primary"
							onClick={requestDaysInadvance}
						>
							Download in advance days request form
						</Button>
					</>
				) : (
					<>
						<p className={classes.help}>
							Once you have created a <span className="highlight">vacation days claim</span>
							, if any days-in-advance are needed, we will send you the instructions for you
							to be able to spend them. If so, here is a hint: be aware of your
							<span className="highlight"> Actions </span>
							section at the home page.
						</p>

						<p className={classes.help}>
							In case you don't need to add days-in-advance to your claim, no further
							actions will be necessary; just wait for your PM to review the claim.
						</p>

						<p className={classes.help}>
							Note: If for some reason you didn't receive any instructions, after creating
							your claim, please contact HR.
						</p>
					</>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default withStyles(styles)(InadvanceDaysInstructions);
