import React from 'react';
import {
	withStyles,
	InputLabel,
	MenuItem,
	FormControl,
	Select
} from '@material-ui/core';

const styles = () => ({
	root: {
		flex: 1,
		display: 'block'
	},
	select: {
		width: '100%',
		margin: '0 0 15px 0'
	}
});

class Selector extends React.PureComponent {
	state = {
		[this.props.name]: ''
	};

	handleSelect = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		this.setState({ [name]: value });
		this.props.onChange(value);
	};

	render() {
		const { classes, options, name, value, disabled } = this.props;
		const keys = Object.keys(options);

		return (
			<FormControl className={classes.select}>
				<InputLabel id={name}>Select {name}</InputLabel>
				<Select
					disabled={disabled}
					className={classes.select}
					labelId={name}
					id={name}
					name={name}
					value={value}
					onChange={this.handleSelect}
				>
					<MenuItem value="">
						<em>None</em>
					</MenuItem>
					{keys.map((i) => (
						<MenuItem key={i} value={i}>
							{options[i]}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		);
	}
}

export default withStyles(styles)(Selector);
