import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import MaterialTable from '@material-table/core';
import permissions from 'permissions';

const Users = (props) => {
	const { users, handleEdit, user } = props;
	const allowEdit = !permissions.has(user, 'users_edit');

	return (
		<MaterialTable
			title="Users list"
			data={users}
			options={{
				pageSize: 10,
				actionsColumnIndex: -1,
				paging: true
			}}
			actions={[
				{
					icon: 'edit',
					tooltip: 'Edit',
					disabled: allowEdit,
					onClick: (event, rowData) => handleEdit(rowData._id)
				}
			]}
			columns={[
				{
					title: 'Avatar',
					field: 'picture',
					render: (rowData) => <Avatar alt={rowData.last_name} src={rowData.picture} />
				},
				{ title: 'Last name', field: 'last_name' },
				{ title: 'First name', field: 'first_name' },
				{ title: 'Email', field: 'email' }
			]}
		/>
	);
};

export default Users;
