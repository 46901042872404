import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import SaveIcon from '@material-ui/icons/Save';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import BadgeIcon from 'icons/BadgeIcon';
import { withData } from 'components/withData';
import TransitionSnackbar from 'components/TransitionSnackbar';
import BadgeList from './BadgeList';
import BadgeClaimDialog from './BadgeClaimDialog';
import { BASE_URL_BADGES } from 'utils/base';

const styles = (theme) => ({
	root: {
		margin: 0,
		overflow: 'hidden',
		display: 'flex',
		flexGrow: 1,
		alignItems: 'top',
		justifyContent: 'center',
		[theme.breakpoints.down('xs')]: {
			height: 'calc(100vh - 56px)'
		},
		[theme.breakpoints.up('sm')]: {
			height: 'calc(100vh - 64px)'
		}
	},
	mainContainer: {
		flexGrow: 1,
		margin: 0,
		height: '100%'
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: `1px solid ${theme.palette.secondary.main}`,
		boxShadow: theme.shadows[2],
		padding: theme.spacing(2, 4, 4),
		outline: 'none'
	},
	badgeList: {
		backgroundColor: theme.palette.background.paper,
		border: `1px solid ${theme.palette.secondary.main}`,
		boxShadow: theme.shadows[2],
		margin: theme.spacing(2),
		padding: theme.spacing(2, 0, 0, 0),
		height: 'calc(100% - 45px)',
		overflow: 'auto'
	},
	badgeClaims: {
		backgroundColor: theme.palette.background.paper,
		border: `1px solid ${theme.palette.secondary.main}`,
		boxShadow: theme.shadows[2],
		margin: theme.spacing(2, 2, 2, 0),
		padding: theme.spacing(2, 0, 0, 0),
		height: 'calc(100% - 45px)',
		overflow: 'auto',
		position: 'relative'
	},
	title: {
		padding: theme.spacing(2, 0, 0, 0)
	},
	actionsContainer: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		zIndex: 1
	},
	fab: {
		margin: theme.spacing(1)
	}
});

class Claim extends React.PureComponent {
	render() {
		const { claim } = this.props;
		return (
			<ListItem>
				<ListItemIcon>
					<BadgeIcon color={'secondary'} />
				</ListItemIcon>
				<ListItemText primary={claim.badge.name} secondary={claim.evidence} />
			</ListItem>
		);
	}
}

/**
 * Main UI for employee badge claiming.
 */
//TODO: Improve UI, edit claims, display claim info
class BadgesClaim extends React.PureComponent {
	state = {
		claimDialogOpen: false,
		claimBadge: null,
		claims: [],
		snackState: 'off'
	};

	constructor(props) {
		super(props);
		const { match, data } = props;
		this.state.claims = data.claims || [];
		this.state.readOnly = data.state !== 'draft'; //TODO: GM. Move to a prop!

		this.baseUrl = `${BASE_URL_BADGES}claims/${match.params.quarter}/${data._id}`;
	}

	_withTransition = (runningMsg, successMsg, fc) => {
		return () => {
			this.setState({ snackState: 'running', snackMessage: runningMsg });
			return fc()
				.then(() => {
					this.setState({ snackState: 'success', snackMessage: successMsg });
				})
				.catch((e) => {
					this.setState({ snackState: 'error', snackMessage: e.toString() });
				});
		};
	};

	promptForBadgeClaim = (badge) => {
		this.setState({ claimDialogOpen: true, claimBadge: badge });
	};

	closeBadgeClaim = () => {
		this.setState({ claimDialogOpen: false });
	};

	addClaim = (claim) => {
		this.closeBadgeClaim();

		this.setState({ claims: this.state.claims.concat([claim]) }, () => {
			this.save();
		});
	};

	submit = this._withTransition('Submitting...', 'Submitted!', () => {
		return axios.post(`${this.baseUrl}/submit`).then(() => {
			this.props.history.push('/badges');
		});
	});

	save = this._withTransition('Saving...', 'Saved!', () => {
		//Clone claims and drop the extra badge information we don't care about at the server
		const claims = this.state.claims.map((claim) => {
			if (!claim.badge_id && claim.badge) {
				claim.badge_id = claim.badge._id;
				delete claim.badge;
			}
			return claim;
		});

		return axios({
			method: 'put',
			url: this.baseUrl,
			data: {
				claims: claims
			}
		});
	});

	render() {
		const { classes, match, data } = this.props;

		const { claimDialogOpen, claimBadge, readOnly, snackMessage } = this.state;

		return (
			<div className={classes.root}>
				{claimDialogOpen && (
					<BadgeClaimDialog
						badge={claimBadge}
						onClaim={this.addClaim}
						onCancel={this.closeBadgeClaim}
					/>
				)}
				<Grid container className={classes.mainContainer} spacing={0}>
					<Grid key="header" item xs={12}>
						<Typography variant="h5" align="center" className={classes.title}>
							Badge claiming {match.params.quarter} ({data.state})
						</Typography>
					</Grid>
					<Grid key="badge_list" item xs={6} lg={4} xl={3}>
						<Paper className={classes.badgeList}>
							<Typography variant="h5" align="center">
								Available badges
							</Typography>
							<BadgeList onBadgeClicked={this.promptForBadgeClaim} readOnly={readOnly} />
						</Paper>
					</Grid>
					<Grid key="claim_list" item xs={6} lg={8} xl={9}>
						<Paper className={classes.badgeClaims}>
							<div className={classes.actionsContainer}>
								<Fab
									color="secondary"
									aria-label="save"
									onClick={this.save}
									className={classes.fab}
									disabled={readOnly}
								>
									<SaveIcon />
								</Fab>
								<Fab
									color="primary"
									aria-label="submit"
									onClick={this.submit}
									className={classes.fab}
									disabled={readOnly}
								>
									<SendIcon />
								</Fab>
							</div>
							<List>
								{this.state.claims.map((claim, i) => (
									<Claim claim={claim} key={i} />
								))}
							</List>
						</Paper>
					</Grid>
				</Grid>
				<TransitionSnackbar
					onClose={this.closeSnack}
					state={this.state.snackState}
					message={snackMessage}
				/>
			</div>
		);
	}

	closeSnack = () => {
		this.setState({ snackState: 'off' });
	};
}

export default withData(withStyles(styles)(withRouter(BadgesClaim)), ({ match }) => {
	return { url: `/api/badges/claims/${match.params.quarter}`, method: 'put' };
});
