import Box from '@material-ui/core/Box';
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { BASE_URL_USER_PROFILE } from 'utils/base';
import TransitionSnackbar from 'components/TransitionSnackbar';
import { absoluteUrl, parseMongoErrorMessage } from 'utils';
import Section from 'components/ui/Section';
import Button from '@material-ui/core/Button';
import Selector from 'components/ui/Selector';

const styles = {
	root: {
		width: '100%',
		height: '100vh'
	},
	textField: {
		marginLeft: '10px'
	},
	column: {
		margin: '5px'
	},
	roles: {
		marginLeft: '5px'
	},
	pronoun: {
		width: '40%',
		marginLeft: '5px'
	}
};

/* Renders the user profile */
class Profile extends React.PureComponent {
	state = {
		snackState: 'off',
		snackMessage: '',
		data: {
			slug: '',
			alias: '',
			pronoun: '',
			title: '',
			use_experimental_modules: false,
			email_actions: false,
			roles: [],
			manager: ''
		}
	};

	componentDidMount() {
		//Load app-wide configuration
		axios
			.get(BASE_URL_USER_PROFILE)
			.then((response) => {
				const { data } = response;
				this.setState({ data });
			})
			.catch((e) => {
				console.error('Error getting employee profile', e);
				this.setState({ snackState: 'error', snackMessage: e.toString() });
			});
	}

	handleUseExperimental = (event) => {
		let last_data = { ...this.state.data };
		last_data['use_experimental_modules'] = event.target.checked;
		this.setState({
			data: last_data
		});
	};

	handleTextChange = (event) => {
		let last_state = { ...this.state };
		last_state.data[event.target.name] = event.target.value;
		this.setState({ last_state });
	};

	handleChangePronoun = (event) => {
		const last_state = { ...this.state };
		last_state.data['pronoun'] = event;
		this.setState({ last_state });
	};

	handleEmailActions = (event) => {
		let last_state = { ...this.state };
		last_state.data['email_actions'] = event.target.checked;
		this.setState({ last_state });
	};

	closeSnackbar = () => {
		this.setState({ error: null, snackState: 'off' });
	};

	handleSubmit = (event) => {
		event.preventDefault();

		const { alias, pronoun, email_actions, slug, title, use_experimental_modules } =
			this.state.data;

		axios
			.put(BASE_URL_USER_PROFILE, {
				alias,
				pronoun,
				email_actions,
				slug,
				title,
				use_experimental_modules
			})
			.then((_) => {
				this.setState({ snackState: 'success', snackMessage: 'Saved!' });
			})
			.catch((err) => {
				this.setState({
					snackState: 'error',
					snackMessage: parseMongoErrorMessage(err.response.data.message.toString())
				});
			});
	};

	render() {
		const { classes } = this.props;
		const { data } = this.state;
		const slugUrl = absoluteUrl(`/team/${this.state.data.slug}`);

		return (
			<div className={classes.root}>
				<Section title="Alias">
					<Typography component="h2" variant="body1">
						Your nickname! You already are unique, your nick doesn't need to be :-)
					</Typography>
					<FormGroup row>
						<FormControlLabel
							labelPlacement="start"
							control={
								<TextField
									name="alias"
									value={data.alias || ''}
									className={classes.textField}
									onChange={this.handleTextChange}
								/>
							}
							label="Alias"
						/>
					</FormGroup>
				</Section>
				<Section title="Slug">
					<Typography component="h2" variant="body1">
						The <em>slug</em> is your nickname sometimes used to generate URL's. I.e{' '}
						<a href={slugUrl}>{slugUrl}</a> is your current link. It must be unique accross
						the company.
					</Typography>
					<FormGroup row>
						<FormControlLabel
							labelPlacement="start"
							control={
								<TextField
									name="slug"
									value={data.slug || ''}
									className={classes.textField}
									onChange={this.handleTextChange}
								/>
							}
							label="Slug"
						/>
					</FormGroup>
				</Section>
				<Section title="Title">
					<Typography component="h2" variant="body1">
						Your title within Ekumen. This has no official recognition, but one can let
						everyone know who you really are.
					</Typography>
					<FormGroup row>
						<FormControlLabel
							labelPlacement="start"
							control={
								<TextField
									name="title"
									value={data.title || ''}
									className={classes.textField}
									onChange={this.handleTextChange}
								/>
							}
							label="Title"
						/>
					</FormGroup>
				</Section>
				<Section title="Manager">
					<Typography component="h2" variant="body1">
						This is your current assigned manager.
					</Typography>
					<Chip
						color="primary"
						variant="outlined"
						avatar={<Avatar src={data.manager ? data.manager.image_url : ''} />}
						label={
							data.manager
								? data.manager.first_name + ' ' + data.manager.last_name
								: 'No current assigned manager'
						}
					/>
				</Section>
				<Section title="Roles">
					<Typography component="h2" variant="body1">
						These are your user roles.
					</Typography>
					<FormGroup row className={classes.column}>
						{data.roles.map((role) => {
							return (
								<Chip
									key={role._id}
									className={classes.roles}
									color="primary"
									label={role.name}
								/>
							);
						})}
					</FormGroup>
				</Section>
				<Section title="Pronoun">
					<Typography component="h2" variant="body1">
						You can select your pronoun.
					</Typography>
					<div className={classes.pronoun}>
						<Selector
							classes={classes.pronoun}
							key="pronoun_selector"
							name="pronoun"
							value={data.pronoun}
							onChange={this.handleChangePronoun}
							options={{
								'They/Them': 'They/Them',
								'She/Her': 'She/Her',
								'He/Him': 'He/Him',
								'Name as Pronoun': 'Name as Pronoun'
							}}
						/>
					</div>
				</Section>
				<Section title="Experimental modules">
					<Typography component="h2" variant="body1">
						Experimental modules are ones that the author thinks they are still not
						production ready, meaning errors may arise when using them. Using experimental
						modules is critical to provide feedback to the author, but they should not be
						considered fully functional nor fool proof.
					</Typography>
					<FormGroup row>
						<FormControlLabel
							control={
								<Switch
									checked={this.state.data.use_experimental_modules || false}
									onChange={this.handleUseExperimental}
									value="aaa"
								/>
							}
							label="Use experimental modules"
						/>
					</FormGroup>
				</Section>
				{/* <Section title="Email actions">
					<Typography component="h2" variant="body1">
						Each time a new action is generated for you, we can send an email or not. Some
						people prefer email, but if you come to the app often, it may not be necesary
						the extra notification.
					</Typography>
					<FormGroup row>
						<FormControlLabel
							control={
								<Switch
									checked={this._value('email_actions')}
									onChange={this.handleEmailActions}
								/>
							}
							label="Email me new actions"
						/>
					</FormGroup>
				</Section> */}
				<TransitionSnackbar
					onClose={this.closeSnackbar}
					state={this.state.snackState}
					message={this.state.snackMessage}
				/>
				<Box textAlign="center">
					<Button variant="contained" color="primary" onClick={this.handleSubmit}>
						Submit
					</Button>
				</Box>
			</div>
		);
	}
}

Profile.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Profile);
