import React from 'react';
import { Route } from 'react-router-dom';
import WithTitle from 'components/WithTitle';
import DaysOffHome from './DaysOffHome';
import EditDayOff from './EditDayOff';
import CreateDayOff from './CreateDayOff';

export default ({ match, title, experimental }) => {
	return (
		<WithTitle title={title} experimental={experimental}>
			<Route exact path={`${match.path}`} component={DaysOffHome} />
			<Route path={`${match.path}/edit/:id`} component={EditDayOff} />
			<Route path={`${match.path}/create`} component={CreateDayOff} />
		</WithTitle>
	);
};
