import React from 'react';
import { Route } from 'react-router-dom';
import EmployeesHome from './EmployeesHome';
import WithTitle from 'components/WithTitle';
import CreateEmployee from './CreateEmployee';
import EditEmployee from './EditEmployee';

export default ({ match, title, experimental}) => {
	return (
		<WithTitle title={title} experimental={experimental}>
			<Route exact path={`${match.path}`} component={EmployeesHome} />
			<Route path={`${match.path}/edit/:id`} component={EditEmployee} />
			<Route path={`${match.path}/create`} component={CreateEmployee} />
		</WithTitle>
	);
};
